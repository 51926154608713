import React from 'react';

/* eslint-disable */
const PrivacyPolicy = () => (
  <div className="container-fluid pb-3">
    <div className="card naf-card">
      <div className="card-body">
        <div className="row justify-content-around">
          <div className="col-12 col-md-8 col-lg-6">
            <h1 className="text-center my-5">Privacy and Cookies Policy</h1>
            <h2>1. Introduction</h2>
            <ol>
              <li>This is the privacy notice for www.___________.com/uk (“Website”).</li>
              <li>NEEDaFIXER Ltd is the “controller” of personal data which is collected from you when you visit and use this Website for the purposes of the General Data Protection Regulation 2016/679 (“EU GDPR”) and the EU GDPR as incorporated into national law by virtue of the UK’s European Union (Withdrawal) Act 2018 (“UK GDPR”), as applicable (referred to herein as the “GDPR”).</li>
              <li>NEEDaFIXER Ltd may be referred to herein as “we”, “us” or “our”. Our corporate information is as follows:
                <ol>
                  <li>Registered company number: 09019896</li>
                  <li>Registered address: 1 Kings Avenue, Winchmore Hill, London, N21 3NA, offices also at Metal Box Factory, 30 Great Guildford Street, London, SE1 0HS.</li>
                </ol>
              </li>
              <li>Privacy is very important to us and therefore we have created this privacy notice to tell you what personal data we collect from you when you visit and use this Website or communicate with us, including how we use your data, how we share it with others and how you can manage it. If you have any questions whatsoever about this privacy notice, then we would be glad to answer them; our contact information is set out below:
                <ol>
                  <li>Post: Data Protection Lead, NEEDaFIXER Ltd, 1 Kings Avenue, Winchmore Hill, London, N21 3NA, offices also at Metal Box Factory, 30 Great Guildford Street, London, SE1 0HS;</li>
                  <li>Email: legal@genplusgroup.agency</li>
                </ol>
              </li>
              <li>Please read this privacy notice before you start using our Website, as it will apply to your use of the Website. By using the Website, you confirm that you accept the terms of this privacy notice. You should also read this privacy notice if you have entered into communications with us, as it applies to any personal data that we collect from you during our communications.</li>
              <li>This privacy notice should be read together with any other privacy notices which are brought to your attention on other pages of this Website which, when relevant, will apply to certain uses of the Website in addition to this privacy notice.</li>
              <li>This privacy notice may change from time to time, so please periodically review this page to ensure that you are still content with how we process your data and continue to share your personal data with us.</li>
              <li>Our Website may contain links to other websites, but please note that such websites are outside of our control and are not covered by this privacy notice. Should you visit other websites, then the owners of such websites may collect and use your personal data, so you should familiarize yourself with any privacy notices or policies that they publish, which we cannot accept any liability for.</li>
            </ol>
            <h2>2. What is “Personal Data”?</h2>
            <p>Under the GDPR “personal data” is defined as:</p>
            <ol>
              <li>“any information relating to an identified or identifiable natural person (“data subject”); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person”.</li>
              <li>As a result, “personal data” includes, amongst many other things, your name, address, phone number, email address and any other information that could be used to identify you, either by itself, or together with other information.
              </li>
            </ol>
            <h2>3. What Personal Data of Mine Will NEEDaFIXER Collect When I Use This Website?</h2>
            <ol>
              <li>By using this Website:
                <ol>
                  <li>we may collect personal data that you provide to us by completing forms on this Website. It is difficult to say what this may be, however, it may include things such as your name (including title), address, phone number, date of birth, email address, job title, company details, tax register number etc. In any event, the precise personal data that we collect from you will be clear to you as you complete the relevant form, as we will only collect the personal data that you submit to us and which will be specifically requested (i.e. you will be specifically invited to enter your name, email address etc.). In certain circumstances, certain data fields will be marked with an asterisk (i.e. *), which means that we require this personal data to be provided. To the extent that fields are not marked with an asterisk, then we do not require this personal data, but should you submit it to us then we will automatically collect it;</li>
                  <li>we may collect personal data that may be included in CVs, covering letters or other materials uploaded and/or sent to us via our Website;</li>
                  <li>we may collect personal data that you provide to us when you communicate with us for any reason. Once again, it is difficult to say what personal data this may be, but we will collect all of the information that is contained within your communication and, as this is within your control, you will be able to identify what this is. However, by way of example, if you communicate with us by email, we will collect your email address as a minimum;</li>
                  <li>you will automatically download a ‘cookie’ (you can read more about “cookies” below); and</li>
                  <li>we will automatically collect your IP address.</li>
                </ol>
              </li>
              <li>We do not collect any special category data.</li>
              <li>We do not collect any personal data from third party sources other than those set out in section 4.</li>
            </ol>
            <h2>4. Does NEEDaFIXER Process My Personal Data Other Than Through This Website?</h2>
            <ol>
              <li>If you post or make certain information publically available on social media platforms (e.g. Facebook, Instagram, Twitter, TikTok, etc.), we may collect and process your personal data either as part of the services we provide to our customers or for our own industry and market understanding purposes. We do this in order for us, and our customers, to understand how consumers are reacting to certain things such as trends, industries, advertising campaigns, products and services.</li>
              <li>We may collect categories of data such as profile name or username, user profile pictures and content of your posts. We only collect and process this information where it has been made publically available. We rely on our legitimate interests in order to collect this data (see further details below).</li>
            </ol>
            <h2>5. Why Does NEEDaFIXER Collect and Use My Personal Data?</h2>
            <p>There are a number of ways that we may use your personal data. These are set out below:</p>
            <h3>1. Consent</h3>
            <ol>
              <li>On occasion, we may use your personal data because you have consented to us doing so, which will be in the context of:
                <ol>
                  <li>providing certain material to you, such as a newsletter or report;</li>
                  <li>sharing your personal data with third parties, so that they can send you marketing; and</li>
                  <li>considering you for a permanent or temporary position with us.</li>
                </ol>
              </li>
              <li>Whenever you have provided consent, we will use your personal data only for the purpose that we specify to you when you provide consent.</li>
              <li>In any event, you will always have the right to withdraw your consent at any time. In order to do so, please contact us by post, email or telephone, as set out above, or, as relevant, follow the unsubscription instructions that are contained within our communication (for instance, an unsubscribe link). Please note that our actions are guided by your consent, so if, for instance, we are using your email address to send you a newsletter, if you withdraw your consent then you will no longer be able to receive this newsletter.</li>
            </ol>
            <h3>2. Legitimate Interest</h3>
            <ol>
              <li>We may use your personal data as set out below where we have a legitimate interest, which will be in the context of:
                <ol>
                  <li>responding to correspondence that you send to us from time to time and fulfilling any requests that you make of us from time to time (including things such as general business enquiries);</li>
                  <li>analyzing, evaluating and improving the products and services that we offer to you;</li>
                  <li>undertaking research and analysis on our products and services (for instance, by customer surveys), so that we can better understand our business and your needs;</li>
                  <li>analyzing and evaluating public responses to industry trends, markets and certain products and services to improve our knowledge and services to customers; engaging with you in the course of our business, for instance, as an actual or potential client, supplier or partner, in the absence of a contractual relationship or obligation;</li>
                  <li>contacting you with relevant marketing information after we have provided a particular service or product to you, or you have attended one of our events. We will only ever contact you about our own products or service, or events that we are involved in;</li>
                  <li>contacting you from time to time for business development purposes if we have obtained your business contact details either personally, through our business network, from a public directory or because you have signed up to one of our mailing lists or downloaded one of our reports;</li>
                  <li>informing you of updates to our terms and conditions and policies;</li>
                  <li>contacting you about permanent or temporary positions with us where you have previously applied for a position; and</li>
                  <li>protecting ourselves, our Website and our IT systems against damage.</li>
                </ol>
              </li>
              <li>In any event, you always have the right to object against us using your personal data for legitimate interests. In order to do so, please contact us by post, email or telephone, as set out above. In the event that you object to the use of your personal data as above, we will consider this request and will communicate our decision with you. While in certain circumstances we may refuse to stop processing your personal data, we will explain this to you and you will always have the right to complain about our decision. In any event, we will always stop using your personal data for marketing where you object against us using your personal data for this reason.</li>
            </ol>
            <h3>3. Contractual Performance</h3>
            <p>We may use your personal data in order to perform a contract with you, for instance, to provide services to you as a client or engage with you as a supplier. If you do not provide your personal data for the purpose of contractual performance, we will be unable to fulfill this purpose.</p>
            <h3>4. Legal Obligation</h3>
            <p>We may use your personal data in order to comply with legal requirements that apply to us as a company (for instance, towards HM Revenue & Customs).</p>
            <h2>6. What About ‘Cookies’?</h2>
            <p>Cookies are small files which are sent to your computer, smartphone or other device automatically from a web server whenever you visit a particular website. Our Website uses cookies, much like most others but, specifically, we use Google Analytics which, via cookies, helps us to analyze how visitors use our Website.</p>
            <ol>
              <li>All devices can block or delete cookies, which can be done by activating certain settings on your browser, however exactly how you do this will depend on your device and what browser you use.</li>
              <li>If you would like to read more information on cookies, including on how to block or delete them, then you can do so by visiting:
                <ol>
                  <li>www.allaboutcookies.org</li>
                  <li>www.youronlinechoices.eu</li>
                  <li>www.google.co.uk/policies/technologies/cookies</li>
                </ol>
              </li>
            </ol>
            <h2>7. Who Do You Share My Personal Data With?</h2>
            <ol>
              <li>We would never share your personal data with third parties for their own marketing or business purposes without your consent, without it being required as part of a contractual obligation between us or without us being subject to a legal obligation to do so, however we use third party service providers in the course of our business for, amongst other things, web hosting, web security, web analytics, data storage, data back-up services, recruitment services and email services.</li>
              <li>We take steps to ensure that any third parties service providers who handle your personal data comply with relevant data protection laws and protect your personal data and in any event, these third parties are limited in their ability to use your personal data for reasons other than providing services to us. Due to the nature of these services providers, which often operate in the ‘cloud’, your personal data may be used or stored outside of the EEA/UK.</li>
              <li>We share your personal data with other entities within the GEN PLUS group and have an intra-group agreement in place for this transfer.</li>
            </ol>
            <h2>8. International Data Transfers</h2>
            <ol>
              <li>Whenever we transfer your personal data outside of the EEA/UK, it will only ever be to a country that has been deemed by the European Commission and/or by the UK’s Secretary of State to provide an adequate level of data protection or, otherwise, using standard data protection clauses approved by the European Commission and Information Commissioner’s Office (ICO).</li>
              <li>Should you have any queries in relation to international transfers or wish to request a copy of the safeguards we use, please contact us by post, email or telephone, as set out above.</li>
            </ol>
            <h2>9. Automatic Decision Making</h2>
            <p>We do not undertake any automatic decision making when processing your personal data.</p>
            <h2>10. How Long Will You Keep My Personal Data For?</h2>
            <p>We will not hold your personal data in an identifiable format for longer than is reasonably necessary to fulfil the purposes for which we collected it and to comply with our legal obligations.</p>
            <h2>11. How Can I Manage My Personal Data?</h2>
            <p>At any point while we are in possession of or using your personal data, you have the following rights:</p>
            <ol>
              <li><strong>Right of access</strong> – you have the right to request a copy of personal data that we hold about you;</li>
              <li><strong>Right of rectification</strong> – you have a right to correct the personal data that we hold about you that is inaccurate or incomplete;</li>
              <li><strong>Right to be forgotten</strong> – in certain circumstances, you can ask for the personal data that we hold about you to be erased from our records;</li>
              <li><strong>Right to restriction of processing</strong> — in certain circumstances, you have a right to restrict our use of your personal data;</li>
              <li><strong>Right of portability</strong> — in certain circumstances, you have the right to have the personal data we hold about you transferred to another organisation;</li>
              <li><strong>Right to object</strong> – you have the right to object to certain ways that we use your personal data, such as direct marketing; and</li>
              <li><strong>Right to object to automated processing, including profiling</strong> – you have the right not to be subject to a decision based solely on automated processing, including profiling.</li>
            </ol>
            <p>Reasonable identification may be required from you if you exercise any of your rights above. In order to exercise any of your rights, we ask that you please contact us by post or email, as set out above.</p>
            <p>Please note that you have the right to lodge a complaint at any time about us and the manner in which we collect and use your personal data with any supervisory authority of the UK or EU member state in which (a) you work, (b) you live or (c) an alleged infringement has taken place. The supervisory authority in the UK is the Information Commissioner’s Office, the contact details of which are set out below:</p>
            <ol>
              <li>Post: Information Commissioner’s Office, Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF</li>
              <li>Telephone: 0303 123 1113</li>
              <li>Email: casework@ico.org.uk</li>
            </ol>
            <h2>12. Changes to This Privacy Notice</h2>
            <p>We may update this privacy notice from time to time in order to reflect changes to our practices or for other operational, legal, or regulatory reasons. If we make changes, we will notify you by revising the date at the top of this privacy notice, and, in some cases, we may provide you with additional notice (such as adding a statement to our homepage or sending you a notification). We encourage you to review the privacy notice whenever you access or use our services to stay informed about our information practices and the ways you can help protect your privacy.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PrivacyPolicy;
