import React, { Fragment } from 'react';
import { DropdownList } from 'react-widgets';
import roles from '../../../core/constants/roles';
import { errorStyle, fieldHasError } from './utils';

const renderRoles = field => (
  <Fragment>
    <label htmlFor="edit-exp-role-select">Role</label>
    <div style={fieldHasError(field) ? { border: '1px solid #dc3545' } : {}}>
      <DropdownList
        id="edit-exp-role-select"
        filter="contains"
        searchIcon={<span className="naf-icon-pencil" />}
        data={roles}
        value={field.input.value}
        onChange={field.input.onChange}
        placeholder="Please select a role"
      />
    </div>
    {
      fieldHasError(field) && <div style={errorStyle}>{ field.meta.error }</div>
    }
  </Fragment>
);

export default renderRoles;
