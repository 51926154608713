import React from 'react';
import PropTypes from 'prop-types';
import { ENTER_KEY, ESCAPE_KEY } from '../../core/constants';
import { DISCARD_FIELD_CHANGES_ELEMENT_STYLE, GeneralInformationFields } from '../constants';

const cancelWasClickedOnBLur = e => e && e.nativeEvent && e.nativeEvent.relatedTarget &&
  e.nativeEvent.relatedTarget.className
  && e.nativeEvent.relatedTarget.className === DISCARD_FIELD_CHANGES_ELEMENT_STYLE;

const GeneralInformationEditTextField = (props) => {
  const {
    input,
    meta,
    fieldValue,
    storedFieldValue,
    revertTo,
    updateSection
  } = props;
  return (
    <input
      {...input}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      type="text"
      className={`form-control ${meta && meta.error ? 'is-invalid' : ''}`}
      onBlur={(e) => {
        if (input.name !== GeneralInformationFields.AMOUNT) {
          e.preventDefault();
          if (cancelWasClickedOnBLur(e) || fieldValue === storedFieldValue) {
            revertTo(storedFieldValue);
          } else {
            updateSection();
          }
        }
      }}
      onKeyDown={(e) => {
        if (e.key === ENTER_KEY && (fieldValue !== storedFieldValue)) {
          e.preventDefault();
          updateSection();
        }
        if (e.key === ESCAPE_KEY) {
          e.preventDefault();
          revertTo(storedFieldValue);
        }
      }}
    />
  );
};

GeneralInformationEditTextField.propTypes = {
  input: PropTypes.shape,
  meta: PropTypes.shape,
  fieldValue: PropTypes.string,
  storedFieldValue: PropTypes.string,
  revertTo: PropTypes.func,
  updateSection: PropTypes.func,
};

export default GeneralInformationEditTextField;
