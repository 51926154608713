import { isNafEmail, isValidEmail } from '../../core/utils';

const updateAccountValidator = (val) => {
  if (!val) {
    return 'Required';
  }
  if ((!isValidEmail(val) || isNafEmail(val))) {
    return 'Invalid Email';
  }
  return undefined;
};

export default updateAccountValidator;
