import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RenderAttachmentField from './RenderAttachmentField';
import { setUIStateAction } from '../../core/actions';
import { ATTACHMENTS, UPLOAD_FILE_MODAL } from '../constants';
import NafUploadFileModal from '../../widgets/UploadFileWidget/NafUploadFileModal';
import { deleteAttachmentAction, downloadAttachmentAction, submitFileUploadFormAction } from '../actions';
import Spinner from '../../widgets/Spinner';

const mapDispatchToProps = dispatch => ({
  openUploadFileModal: () => dispatch(setUIStateAction(UPLOAD_FILE_MODAL, true)),
  handleDelete: fileId => dispatch(deleteAttachmentAction(fileId)),
  handleDownload: (fileId, fileName, contentType) => dispatch(downloadAttachmentAction(fileId, fileName, contentType))
});

const Attachments = ({
  initialValues, openUploadFileModal, handleDelete, handleDownload
}) => (
  <div className="card naf-card col-md-6">
    <Spinner element={ATTACHMENTS} />
    <div className="card-body">
      <h4 className="card-title"><span>FILES (ATTACHMENTS)</span></h4>
      <ul className="naf-list-editing">
        {
          initialValues.attachments &&
          initialValues.attachments
            .map(attachment => <RenderAttachmentField {...attachment} handleDelete={handleDelete} handleDownload={handleDownload} />)
        }
      </ul>
      <div className="text-right">
        <button type="button" className="btn btn-link naf-edit-btn" onClick={() => openUploadFileModal()}>
          <span className="fa fa-plus" /> ADD NEW
        </button>
      </div>
    </div>
    <NafUploadFileModal
      action={submitFileUploadFormAction}
      stateIdentifier={UPLOAD_FILE_MODAL}
      title="PLEASE UPLOAD YOUR FILE"
    />
  </div>
);

Attachments.propTypes = {
  initialValues: PropTypes.arrayOf(PropTypes.shape()),
  openUploadFileModal: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDownload: PropTypes.func
};

export default connect(null, mapDispatchToProps)(Attachments);
