const countriesData = [
  {
    name: 'Afghanistan',
    FIELD2: 'AF',
    value: '+93',
    label: 'Afghanistan (AF)'
  },
  {
    name: 'Albania',
    FIELD2: 'AL',
    value: '+355',
    label: 'Albania (AL)'
  },
  {
    name: 'Algeria',
    FIELD2: 'DZ',
    value: '+213',
    label: 'Algeria (DZ)'
  },
  {
    name: 'American Samoa',
    FIELD2: 'AS',
    value: '+1 684',
    label: 'American Samoa(AS)'
  },
  {
    name: 'Andorra',
    FIELD2: 'AD',
    value: '+376',
    label: 'Andorra(AD)'
  },
  {
    name: 'Angola',
    FIELD2: 'AO',
    value: '+244',
    label: 'Angola(AO)'
  },
  {
    name: 'Anguilla',
    FIELD2: 'AI',
    value: '+1 264',
    label: 'Anguilla (AI)'
  },
  {
    name: 'Antarctica',
    FIELD2: 'AQ',
    value: '+672',
    label: 'Antarctica(AQ)'
  },
  {
    name: 'Antigua and Barbuda',
    FIELD2: 'AG',
    value: '+1 268',
    label: 'Antigua and Barbuda(AG)'
  },
  {
    name: 'Argentina',
    FIELD2: 'AR',
    value: '+54',
    label: 'Argentina (AR)'
  },
  {
    name: 'Armenia',
    FIELD2: 'AM',
    value: '+374',
    label: 'Armenia(AM)'
  },
  {
    name: 'Aruba',
    FIELD2: 'AW',
    value: '+297',
    label: 'Aruba(AW)'
  },
  {
    name: 'Australia',
    FIELD2: 'AU',
    value: '+61',
    label: 'Australia(AU)'
  },
  {
    name: 'Austria',
    FIELD2: 'AT',
    value: '+43',
    label: 'Austria(AT)'
  },
  {
    name: 'Azerbaijan or Azerbaidjan',
    FIELD2: 'AZ',
    value: '+994',
    label: 'Azerbaijan or Azerbaidjan (AZ)'
  },
  {
    name: 'Bahamas',
    FIELD2: 'BS',
    value: '+1 242',
    label: 'Bahamas(BS)'
  },
  {
    name: 'Bahrain',
    FIELD2: 'BH',
    value: '+973',
    label: 'Bahrain(BH)'
  },
  {
    name: 'Bangladesh',
    FIELD2: 'BD',
    value: '+880',
    label: 'Bangladesh (BD)'
  },
  {
    name: 'Barbados',
    FIELD2: 'BB',
    value: '+1 246',
    label: 'Barbados (BB)'
  },
  {
    name: 'Belarus',
    FIELD2: 'BY',
    value: '+375',
    label: 'Belarus (BY)'
  },
  {
    name: 'Belgium',
    FIELD2: 'BE',
    value: '+32',
    label: 'Belgium (BE)'
  },
  {
    name: 'Belize',
    FIELD2: 'BZ',
    value: '+501',
    label: 'Belize (BZ)'
  },
  {
    name: 'Benin',
    FIELD2: 'BJ',
    value: '+229',
    label: 'Benin (BJ)'
  },
  {
    name: 'Bermuda',
    FIELD2: 'BM',
    value: '+1 441',
    label: 'Bermuda (BM)'
  },
  {
    name: 'Bhutan',
    FIELD2: 'BT',
    value: '+975',
    label: 'Bhutan(BT)'
  },
  {
    name: 'Bolivia',
    FIELD2: 'BO',
    value: '+591',
    label: 'Bolivia (BO)'
  },
  {
    name: 'Bosnia and Herzegovina',
    FIELD2: 'BA',
    value: '+387',
    label: 'Bosnia and Herzegovina (BA)'
  },
  {
    name: 'Botswana',
    FIELD2: 'BW',
    value: '+267',
    label: 'Botswana (BW)'
  },
  {
    name: 'Bouvet Island',
    FIELD2: 'BV',
    value: '',
    label: 'Bouvet Island (BV)'
  },
  {
    name: 'Brazil',
    FIELD2: 'BR',
    value: '+55',
    label: 'Brazil (BR)'
  },
  {
    name: 'British Indian Ocean Territory',
    FIELD2: 'IO',
    value: '',
    label: 'British Indian Ocean Territory (IO)'
  },
  {
    name: 'Brunei ',
    FIELD2: 'BN',
    value: '+673',
    label: 'Brunei  (BN)'
  },
  {
    name: 'Bulgaria',
    FIELD2: 'BG',
    value: '+359',
    label: 'Bulgaria (BG)'
  },
  {
    name: 'Burkina Faso',
    FIELD2: 'BF',
    value: '+226',
    label: 'Burkina Faso (BF)'
  },
  {
    name: 'Burundi',
    FIELD2: 'BI',
    value: '+257',
    label: 'Burundi (BI)'
  },
  {
    name: 'Cambodia',
    FIELD2: 'KH',
    value: '+855',
    label: 'Cambodia(KH)'
  },
  {
    name: 'Cameroon',
    FIELD2: 'CM',
    value: '+237',
    label: 'Cameroon (CM)'
  },
  {
    name: 'Canada',
    FIELD2: 'CA',
    value: '+1',
    label: 'Canada (CA)'
  },
  {
    name: 'Cape Verde',
    FIELD2: 'CV',
    value: '+238',
    label: 'Cape Verde (CV)'
  },
  {
    name: 'Cayman Islands',
    FIELD2: 'KY',
    value: '+1 345',
    label: 'Cayman Islands (KY)'
  },
  {
    name: 'Central African Republic',
    FIELD2: 'CF',
    value: '+236',
    label: 'Central African Republic (CF)'
  },
  {
    name: 'Chad',
    FIELD2: 'TD',
    value: '+235',
    label: 'Chad (TD)'
  },
  {
    name: 'Chile',
    FIELD2: 'CL',
    value: '+56',
    label: 'Chile (CL)'
  },
  {
    name: 'China',
    FIELD2: 'CN',
    value: '+86',
    label: 'China (CN)'
  },
  {
    name: 'Christmas Island',
    FIELD2: 'CX',
    value: '+53',
    label: 'Christmas Island (CX)'
  },
  {
    name: 'Cocos  Islands',
    FIELD2: 'CC',
    value: '+61',
    label: 'Cocos  Islands (CC)'
  },
  {
    name: 'Colombia',
    FIELD2: 'CO',
    value: '+57',
    label: 'Colombia (CO)'
  },
  {
    name: 'Comoros',
    FIELD2: 'KM',
    value: '+269',
    label: 'Comoros(KM)'
  },
  {
    name: 'Congo',
    FIELD2: 'CD',
    value: '+243',
    label: 'Congo(CD)'
  },
  {
    name: 'Congo',
    FIELD2: 'CG',
    value: '+242',
    label: 'Congo(CG)'
  },
  {
    name: 'Cook Islands',
    FIELD2: 'CK',
    value: '+682',
    label: 'Cook Islands (CK)'
  },
  {
    name: 'Costa Rica',
    FIELD2: 'CR',
    value: '+506',
    label: 'Costa Rica (CR)'
  },
  {
    name: "Cote D'Ivoire ",
    FIELD2: 'CI',
    value: '+225',
    label: "Cote D'Ivoire  (CI)"
  },
  {
    name: 'Croatia ',
    FIELD2: 'HR',
    value: '+385',
    label: 'Croatia  (HR)'
  },
  {
    name: 'Cuba',
    FIELD2: 'CU',
    value: '+53',
    label: 'Cuba (CU)'
  },
  {
    name: 'Cyprus',
    FIELD2: 'CY',
    value: '+357',
    label: 'Cyprus (CY)'
  },
  {
    name: 'Czech Republic',
    FIELD2: 'CZ',
    value: '+420',
    label: 'Czech Republic(CZ)'
  },
  {
    name: 'Denmark',
    FIELD2: 'DK',
    value: '+45',
    label: 'Denmark (DK)'
  },
  {
    name: 'Djibouti',
    FIELD2: 'DJ',
    value: '+253',
    label: 'Djibouti (DJ)'
  },
  {
    name: 'Dominica',
    FIELD2: 'DM',
    value: '+1 767',
    label: 'Dominica (DM)'
  },
  {
    name: 'Dominican Republic',
    FIELD2: 'DO',
    value: '+1 809',
    label: 'Dominican Republic (DO)'
  },
  {
    name: 'East Timor',
    FIELD2: 'TP',
    value: '+670',
    label: 'East Timor (TP)'
  },
  {
    name: 'Ecuador',
    FIELD2: 'EC',
    value: '+593',
    label: 'Ecuador (EC)'
  },
  {
    name: 'Egypt',
    FIELD2: 'EG',
    value: '+20',
    label: 'Egypt (EG)'
  },
  {
    name: 'El Salvador',
    FIELD2: 'SV',
    value: '+503',
    label: 'El Salvador (SV)'
  },
  {
    name: 'Equatorial Guinea',
    FIELD2: 'GQ',
    value: '+240',
    label: 'Equatorial Guinea (GQ)'
  },
  {
    name: 'Eritrea',
    FIELD2: 'ER',
    value: '+291',
    label: 'Eritrea (ER)'
  },
  {
    name: 'Estonia',
    FIELD2: 'EE',
    value: '+372',
    label: 'Estonia (EE)'
  },
  {
    name: 'Ethiopia',
    FIELD2: 'ET',
    value: '+251',
    label: 'Ethiopia (ET)'
  },
  {
    name: 'Falkland Islands ',
    FIELD2: 'FK',
    value: '+500',
    label: 'Falkland Islands  (FK)'
  },
  {
    name: 'Faroe Islands',
    FIELD2: 'FO',
    value: '+298',
    label: 'Faroe Islands (FO)'
  },
  {
    name: 'Fiji',
    FIELD2: 'FJ',
    value: '+679',
    label: 'Fiji (FJ)'
  },
  {
    name: 'Finland',
    FIELD2: 'FI',
    value: '+358',
    label: 'Finland (FI)'
  },
  {
    name: 'France',
    FIELD2: 'FR',
    value: '+33',
    label: 'France (FR)'
  },
  {
    name: 'French Guiana or French Guyana',
    FIELD2: 'GF',
    value: '+594',
    label: 'French Guiana or French Guyana (GF)'
  },
  {
    name: 'French Polynesia',
    FIELD2: 'PF',
    value: '+689',
    label: 'French Polynesia (PF)'
  },
  {
    name: 'French Southern Territories and Antarctic Lands',
    FIELD2: 'TF',
    value: '',
    label: 'French Southern Territories and Antarctic Lands (TF)'
  },
  {
    name: 'Gabon',
    FIELD2: 'GA',
    value: '+241',
    label: 'Gabon (GA)'
  },
  {
    name: 'Gambia',
    FIELD2: 'GM',
    value: '+220',
    label: 'Gambia(GM)'
  },
  {
    name: 'Georgia',
    FIELD2: 'GE',
    value: '+995',
    label: 'Georgia (GE)'
  },
  {
    name: 'Germany',
    FIELD2: 'DE',
    value: '+49',
    label: 'Germany (DE)'
  },
  {
    name: 'Ghana',
    FIELD2: 'GH',
    value: '+233',
    label: 'Ghana (GH)'
  },
  {
    name: 'Gibraltar',
    FIELD2: 'GI',
    value: '+350',
    label: 'Gibraltar (GI)'
  },
  {
    name: 'Great Britain ',
    FIELD2: 'GB',
    value: '+44',
    label: 'Great Britain  (GB)'
  },
  {
    name: 'Greece',
    FIELD2: 'GR',
    value: '+30',
    label: 'Greece (GR)'
  },
  {
    name: 'Greenland',
    FIELD2: 'GL',
    value: '+299',
    label: 'Greenland (GL)'
  },
  {
    name: 'Grenada',
    FIELD2: 'GD',
    value: '+1 473',
    label: 'Grenada (GD)'
  },
  {
    name: 'Guadeloupe',
    FIELD2: 'GP',
    value: '+590',
    label: 'Guadeloupe(GP)'
  },
  {
    name: 'Guam',
    FIELD2: 'GU',
    value: '+1 671',
    label: 'Guam(GU)'
  },
  {
    name: 'Guatemala',
    FIELD2: 'GT',
    value: '+502',
    label: 'Guatemala (GT)'
  },
  {
    name: 'Guinea',
    FIELD2: 'GN',
    value: '+224',
    label: 'Guinea (GN)'
  },
  {
    name: 'Guinea Bissau',
    FIELD2: 'GW',
    value: '+245',
    label: 'Guinea Bissau (GW)'
  },
  {
    name: 'Guyana',
    FIELD2: 'GY',
    value: '+592',
    label: 'Guyana (GY)'
  },
  {
    name: 'Haiti',
    FIELD2: 'HT',
    value: '+509',
    label: 'Haiti (HT)'
  },
  {
    name: 'Heard Island and McDonald Islands',
    FIELD2: 'HM',
    value: '',
    label: 'Heard Island and McDonald Islands (HM)'
  },
  {
    name: 'Holy See',
    FIELD2: 'VA',
    value: '',
    label: 'Holy See (VA)'
  },
  {
    name: 'Honduras',
    FIELD2: 'HN',
    value: '+504',
    label: 'Honduras (HN)'
  },
  {
    name: 'Hong Kong',
    FIELD2: 'HK',
    value: '+852',
    label: 'Hong Kong (HK)'
  },
  {
    name: 'Hungary',
    FIELD2: 'HU',
    value: '+36',
    label: 'Hungary (HU)'
  },
  {
    name: 'Iceland',
    FIELD2: 'IS',
    value: '+354',
    label: 'Iceland (IS)'
  },
  {
    name: 'India',
    FIELD2: 'IN',
    value: '+91',
    label: 'India (IN)'
  },
  {
    name: 'Indonesia',
    FIELD2: 'ID',
    value: '+62',
    label: 'Indonesia (ID)'
  },
  {
    name: 'Iran',
    FIELD2: 'IR',
    value: '+98',
    label: 'Iran(IR)'
  },
  {
    name: 'Iraq',
    FIELD2: 'IQ',
    value: '+964',
    label: 'Iraq (IQ)'
  },
  {
    name: 'Ireland',
    FIELD2: 'IE',
    value: '+353',
    label: 'Ireland (IE)'
  },
  {
    name: 'Israel',
    FIELD2: 'IL',
    value: '+972',
    label: 'Israel (IL)'
  },
  {
    name: 'Italy',
    FIELD2: 'IT',
    value: '+39',
    label: 'Italy (IT)'
  },
  {
    name: 'Jamaica',
    FIELD2: 'JM',
    value: '+1 876',
    label: 'Jamaica (JM)'
  },
  {
    name: 'Japan',
    FIELD2: 'JP',
    value: '+81',
    label: 'Japan (JP)'
  },
  {
    name: 'Jordan',
    FIELD2: 'JO',
    value: '+962',
    label: 'Jordan (JO)'
  },
  {
    name: 'Kazakstan or Kazakhstan',
    FIELD2: 'KZ',
    value: '+7',
    label: 'Kazakstan or Kazakhstan (KZ)'
  },
  {
    name: 'Kenya',
    FIELD2: 'KE',
    value: '+254',
    label: 'Kenya (KE)'
  },
  {
    name: 'Kiribati ',
    FIELD2: 'KI',
    value: '+686',
    label: 'Kiribati  (KI)'
  },
  {
    name: 'North Korea',
    FIELD2: 'KP',
    value: '+850',
    label: 'Korea(KP)'
  },
  {
    name: 'South Korea',
    FIELD2: 'KR',
    value: '+82',
    label: 'Korea(KR)'
  },
  {
    name: 'Kuwait',
    FIELD2: 'KW',
    value: '+965',
    label: 'Kuwait (KW)'
  },
  {
    name: 'Kyrgyzstan ',
    FIELD2: 'KG',
    value: '+996',
    label: 'Kyrgyzstan  (KG)'
  },
  {
    name: "Lao People's Democratic Republic ",
    FIELD2: 'LA',
    value: '+856',
    label: "Lao People's Democratic Republic (LA)"
  },
  {
    name: 'Latvia',
    FIELD2: 'LV',
    value: '+371',
    label: 'Latvia (LV)'
  },
  {
    name: 'Lebanon',
    FIELD2: 'LB',
    value: '+961',
    label: 'Lebanon (LB)'
  },
  {
    name: 'Lesotho',
    FIELD2: 'LS',
    value: '+266',
    label: 'Lesotho (LS)'
  },
  {
    name: 'Liberia',
    FIELD2: 'LR',
    value: '+231',
    label: 'Liberia (LR)'
  },
  {
    name: 'Libya',
    FIELD2: 'LY',
    value: '+218',
    label: 'Libya (LY)'
  },
  {
    name: 'Liechtenstein',
    FIELD2: 'LI',
    value: '+423',
    label: 'Liechtenstein (LI)'
  },
  {
    name: 'Lithuania',
    FIELD2: 'LT',
    value: '+370',
    label: 'Lithuania (LT)'
  },
  {
    name: 'Luxembourg',
    FIELD2: 'LU',
    value: '+352',
    label: 'Luxembourg (LU)'
  },
  {
    name: 'Macau',
    FIELD2: 'MO',
    value: '+853',
    label: 'Macau (MO)'
  },
  {
    name: 'Macedonia',
    FIELD2: 'MK',
    value: '+389',
    label: 'Macedonia(MK)'
  },
  {
    name: 'Madagascar',
    FIELD2: 'MG',
    value: '+261',
    label: 'Madagascar (MG)'
  },
  {
    name: 'Malawi',
    FIELD2: 'MW',
    value: '+265',
    label: 'Malawi (MW)'
  },
  {
    name: 'Malaysia',
    FIELD2: 'MY',
    value: '+60',
    label: 'Malaysia (MY)'
  },
  {
    name: 'Maldives',
    FIELD2: 'MV',
    value: '+960',
    label: 'Maldives (MV)'
  },
  {
    name: 'Mali ',
    FIELD2: 'ML',
    value: '+223',
    label: 'Mali  (ML)'
  },
  {
    name: 'Malta',
    FIELD2: 'MT',
    value: '+356',
    label: 'Malta (MT)'
  },
  {
    name: 'Marshall Islands',
    FIELD2: 'MH',
    value: '+692',
    label: 'Marshall Islands (MH)'
  },
  {
    name: 'Martinique ',
    FIELD2: 'MQ',
    value: '+596',
    label: 'Martinique  (MQ)'
  },
  {
    name: 'Mauritania',
    FIELD2: 'MR',
    value: '+222',
    label: 'Mauritania (MR)'
  },
  {
    name: 'Mauritius',
    FIELD2: 'MU',
    value: '+230',
    label: 'Mauritius (MU)'
  },
  {
    name: 'Mayotte',
    FIELD2: 'YT',
    value: '+269',
    label: 'Mayotte (YT)'
  },
  {
    name: 'Mexico',
    FIELD2: 'MX',
    value: '+52',
    label: 'Mexico (MX)'
  },
  {
    name: 'Micronesia',
    FIELD2: 'FM',
    value: '+691',
    label: 'Micronesia(FM)'
  },
  {
    name: 'Moldova',
    FIELD2: 'MD',
    value: '+373',
    label: 'Moldova(MD)'
  },
  {
    name: 'Monaco',
    FIELD2: 'MC',
    value: '+377',
    label: 'Monaco(MC)'
  },
  {
    name: 'Mongolia',
    FIELD2: 'MN',
    value: '+976',
    label: 'Mongolia (MN)'
  },
  {
    name: 'Montserrat',
    FIELD2: 'MS',
    value: '+1 664',
    label: 'Montserrat (MS)'
  },
  {
    name: 'Morocco',
    FIELD2: 'MA',
    value: '+212',
    label: 'Morocco (MA)'
  },
  {
    name: 'Mozambique',
    FIELD2: 'MZ',
    value: '+258',
    label: 'Mozambique (MZ)'
  },
  {
    name: 'Myanmar',
    FIELD2: 'MM',
    value: '+95',
    label: 'Myanmar(MM)'
  },
  {
    name: 'Namibia',
    FIELD2: 'NA',
    value: '+264',
    label: 'Namibia (NA)'
  },
  {
    name: 'Nauru',
    FIELD2: 'NR',
    value: '+674',
    label: 'Nauru (NR)'
  },
  {
    name: 'Nepal',
    FIELD2: 'NP',
    value: '+977',
    label: 'Nepal (NP)'
  },
  {
    name: 'Netherlands',
    FIELD2: 'NL',
    value: '+31',
    label: 'Netherlands (NL)'
  },
  {
    name: 'Netherlands Antilles',
    FIELD2: 'AN',
    value: '+599',
    label: 'Netherlands Antilles (AN)'
  },
  {
    name: 'New Caledonia',
    FIELD2: 'NC',
    value: '+687',
    label: 'New Caledonia (NC)'
  },
  {
    name: 'New Zealand ',
    FIELD2: 'NZ',
    value: '+64',
    label: 'New Zealand  (NZ)'
  },
  {
    name: 'Nicaragua',
    FIELD2: 'NI',
    value: '+505',
    label: 'Nicaragua (NI)'
  },
  {
    name: 'Niger',
    FIELD2: 'NE',
    value: '+227',
    label: 'Niger (NE)'
  },
  {
    name: 'Nigeria',
    FIELD2: 'NG',
    value: '+234',
    label: 'Nigeria (NG)'
  },
  {
    name: 'Niue',
    FIELD2: 'NU',
    value: '+683',
    label: 'Niue (NU)'
  },
  {
    name: 'Norfolk Island',
    FIELD2: 'NF',
    value: '+672',
    label: 'Norfolk Island (NF)'
  },
  {
    name: 'Northern Mariana Islands',
    FIELD2: 'MP',
    value: '+1 670',
    label: 'Northern Mariana Islands (MP)'
  },
  {
    name: 'Norway',
    FIELD2: 'NO',
    value: '+47',
    label: 'Norway (NO)'
  },
  {
    name: 'Oman',
    FIELD2: 'OM',
    value: '+968',
    label: 'Oman(OM)'
  },
  {
    name: 'Pakistan',
    FIELD2: 'PK',
    value: '+92',
    label: 'Pakistan (PK)'
  },
  {
    name: 'Palau',
    FIELD2: 'PW',
    value: '+680',
    label: 'Palau (PW)'
  },
  {
    name: 'Palestinian State',
    FIELD2: 'PS',
    value: '+970',
    label: 'Palestinian State (PS)'
  },
  {
    name: 'Panama',
    FIELD2: 'PA',
    value: '+507',
    label: 'Panama (PA)'
  },
  {
    name: 'Papua New Guinea',
    FIELD2: 'PG',
    value: '+675',
    label: 'Papua New Guinea (PG)'
  },
  {
    name: 'Paraguay',
    FIELD2: 'PY',
    value: '+595',
    label: 'Paraguay (PY)'
  },
  {
    name: 'Peru',
    FIELD2: 'PE',
    value: '+51',
    label: 'Peru (PE)'
  },
  {
    name: 'Philippines',
    FIELD2: 'PH',
    value: '+63',
    label: 'Philippines (PH)'
  },
  {
    name: 'Pitcairn Island',
    FIELD2: 'PN',
    value: '',
    label: 'Pitcairn Island(PN)'
  },
  {
    name: 'Poland',
    FIELD2: 'PL',
    value: '+48',
    label: 'Poland (PL)'
  },
  {
    name: 'Portugal',
    FIELD2: 'PT',
    value: '+351',
    label: 'Portugal (PT)'
  },
  {
    name: 'Puerto Rico',
    FIELD2: 'PR',
    value: '+1 787',
    label: 'Puerto Rico (PR)'
  },
  {
    name: 'Qatar',
    FIELD2: 'QA',
    value: '+974',
    label: 'Qatar(QA)'
  },
  {
    name: 'Reunion ',
    FIELD2: 'RE',
    value: '+262',
    label: 'Reunion  (RE)'
  },
  {
    name: 'Romania',
    FIELD2: 'RO',
    value: '+40',
    label: 'Romania (RO)'
  },
  {
    name: 'Russia   USSR  Now RU   Russian Federation',
    FIELD2: 'SU',
    value: '',
    label: 'Russia   USSR  Now RU   Russian Federation(SU)'
  },
  {
    name: 'Russian Federation',
    FIELD2: 'RU',
    value: '+7',
    label: 'Russian Federation (RU)'
  },
  {
    name: 'Rwanda ',
    FIELD2: 'RW',
    value: '+250',
    label: 'Rwanda  (RW)'
  },
  {
    name: 'Saint Helena',
    FIELD2: 'SH',
    value: '+290',
    label: 'Saint Helena (SH)'
  },
  {
    name: 'Saint Kitts and Nevis',
    FIELD2: 'KN',
    value: '+1 869',
    label: 'Saint Kitts and Nevis (KN)'
  },
  {
    name: 'Saint Lucia',
    FIELD2: 'LC',
    value: '+1 758',
    label: 'Saint Lucia (LC)'
  },
  {
    name: 'Saint Pierre and Miquelon',
    FIELD2: 'PM',
    value: '+508',
    label: 'Saint Pierre and Miquelon (PM)'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    FIELD2: 'VC',
    value: '+1 784',
    label: 'Saint Vincent and the Grenadines (VC)'
  },
  {
    name: 'Samoa',
    FIELD2: 'WS',
    value: '+685',
    label: 'Samoa (WS)'
  },
  {
    name: 'San Marino',
    FIELD2: 'SM',
    value: '+378',
    label: 'San Marino (SM)'
  },
  {
    name: 'Sao Tome and Principe',
    FIELD2: 'ST',
    value: '+239',
    label: 'Sao Tome and Principe (ST)'
  },
  {
    name: 'Saudi Arabia',
    FIELD2: 'SA',
    value: '+966',
    label: 'Saudi Arabia (SA)'
  },
  {
    name: 'Serbia',
    FIELD2: 'RS',
    value: '',
    label: 'Serbia(RS)'
  },
  {
    name: 'Senegal',
    FIELD2: 'SN',
    value: '+221',
    label: 'Senegal (SN)'
  },
  {
    name: 'Seychelles',
    FIELD2: 'SC',
    value: '+248',
    label: 'Seychelles (SC)'
  },
  {
    name: 'Sierra Leone',
    FIELD2: 'SL',
    value: '+232',
    label: 'Sierra Leone (SL)'
  },
  {
    name: 'Singapore',
    FIELD2: 'SG',
    value: '+65',
    label: 'Singapore (SG)'
  },
  {
    name: 'Slovakia',
    FIELD2: 'SK',
    value: '+421',
    label: 'Slovakia(SK)'
  },
  {
    name: 'Slovenia',
    FIELD2: 'SI',
    value: '+386',
    label: 'Slovenia (SI)'
  },
  {
    name: 'Solomon Islands',
    FIELD2: 'SB',
    value: '+677',
    label: 'Solomon Islands (SB)'
  },
  {
    name: 'Somalia ',
    FIELD2: 'SO',
    value: '+252',
    label: 'Somalia  (SO)'
  },
  {
    name: 'South Africa',
    FIELD2: 'ZA',
    value: '+27',
    label: 'South Africa (ZA)'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    FIELD2: 'GS',
    value: '',
    label: 'South Georgia and the South Sandwich Islands(GS)'
  },
  {
    name: 'Spain',
    FIELD2: 'ES',
    value: '+34',
    label: 'Spain (ES)'
  },
  {
    name: 'Sri Lanka ',
    FIELD2: 'LK',
    value: '+94',
    label: 'Sri Lanka  (LK)'
  },
  {
    name: 'Sudan ',
    FIELD2: 'SD',
    value: '+249',
    label: 'Sudan  (SD)'
  },
  {
    name: 'Suriname',
    FIELD2: 'SR',
    value: '+597',
    label: 'Suriname (SR)'
  },
  {
    name: 'Svalbard  and Jan Mayen Islands',
    FIELD2: 'SJ',
    value: '',
    label: 'Svalbard  and Jan Mayen Islands (SJ)'
  },
  {
    name: 'Swaziland',
    FIELD2: 'SZ',
    value: '+268',
    label: 'Swaziland(SZ)'
  },
  {
    name: 'Sweden',
    FIELD2: 'SE',
    value: '+46',
    label: 'Sweden (SE)'
  },
  {
    name: 'Switzerland',
    FIELD2: 'CH',
    value: '+41',
    label: 'Switzerland (CH)'
  },
  {
    name: 'Syria ',
    FIELD2: 'SY',
    value: '+963',
    label: 'Syria  (SY)'
  },
  {
    name: 'Taiwan',
    FIELD2: 'TW',
    value: '+886',
    label: 'Taiwan (TW)'
  },
  {
    name: 'Tajikistan',
    FIELD2: 'TJ',
    value: '+992',
    label: 'Tajikistan (TJ)'
  },
  {
    name: 'Tanzania',
    FIELD2: 'TZ',
    value: '+255',
    label: 'Tanzania(TZ)'
  },
  {
    name: 'Thailand',
    FIELD2: 'TH',
    value: '+66',
    label: 'Thailand (TH)'
  },
  {
    name: 'Togo',
    FIELD2: 'TG',
    value: '',
    label: 'Togo (TG)'
  },
  {
    name: 'Tokelau',
    FIELD2: 'TK',
    value: '+690',
    label: 'Tokelau (TK)'
  },
  {
    name: 'Tonga',
    FIELD2: 'TO',
    value: '+676',
    label: 'Tonga(TO)'
  },
  {
    name: 'Trinidad and Tobago',
    FIELD2: 'TT',
    value: '+1 868',
    label: 'Trinidad and Tobago (TT)'
  },
  {
    name: 'Tromelin Island',
    FIELD2: 'TE',
    value: '',
    label: 'Tromelin Island (TE)'
  },
  {
    name: 'Tunisia',
    FIELD2: 'TN',
    value: '+216',
    label: 'Tunisia (TN)'
  },
  {
    name: 'Turkey',
    FIELD2: 'TR',
    value: '+90',
    label: 'Turkey (TR)'
  },
  {
    name: 'Turkmenistan',
    FIELD2: 'TM',
    value: '+993',
    label: 'Turkmenistan (TM)'
  },
  {
    name: 'Turks and Caicos Islands',
    FIELD2: 'TC',
    value: '+1 649',
    label: 'Turks and Caicos Islands (TC)'
  },
  {
    name: 'Tuvalu',
    FIELD2: 'TV',
    value: '+688',
    label: 'Tuvalu (TV)'
  },
  {
    name: 'Uganda',
    FIELD2: 'UG',
    value: '+256',
    label: 'Uganda(UG)'
  },
  {
    name: 'Ukraine',
    FIELD2: 'UA',
    value: '+380',
    label: 'Ukraine (UA)'
  },
  {
    name: 'United Arab Emirates ',
    FIELD2: 'AE',
    value: '+971',
    label: 'United Arab Emirates  (AE)'
  },
  {
    name: 'United Kingdom',
    FIELD2: 'GB',
    value: '+44',
    label: 'United Kingdom (GB)'
  },
  {
    name: 'United States',
    FIELD2: 'US',
    value: '+1',
    label: 'United States (US)'
  },
  {
    name: 'United States Minor Outlying Islands',
    FIELD2: 'UM',
    value: '',
    label: 'United States Minor Outlying Islands (UM)'
  },
  {
    name: 'Uruguay',
    FIELD2: 'UY',
    value: '+598',
    label: 'Uruguay(UY)'
  },
  {
    name: 'Uzbekistan',
    FIELD2: 'UZ',
    value: '+998',
    label: 'Uzbekistan (UZ)'
  },
  {
    name: 'Vanuatu',
    FIELD2: 'VU',
    value: '+678',
    label: 'Vanuatu (VU)'
  },
  {
    name: 'Vatican City State',
    FIELD2: 'VA',
    value: '+418',
    label: 'Vatican City State (VA)'
  },
  {
    name: 'Venezuela',
    FIELD2: 'VE',
    value: '+58',
    label: 'Venezuela (VE)'
  },
  {
    name: 'Vietnam',
    FIELD2: 'VN',
    value: '+84',
    label: 'Vietnam (VN)'
  },
  {
    name: 'Virgin Islands',
    FIELD2: 'VI',
    value: '+1 284',
    label: 'Virgin Islands(VI)'
  },
  {
    name: 'Virgin Islands',
    FIELD2: 'VQ',
    value: '+1 340',
    label: 'Virgin Islands(VQ)'
  },
  {
    name: 'Wallis and Futuna Islands',
    FIELD2: 'WF',
    value: '+681',
    label: 'Wallis and Futuna Islands (WF)'
  },
  {
    name: 'Western Sahara',
    FIELD2: 'EH',
    value: '',
    label: 'Western Sahara (EH)'
  },
  {
    name: 'Yemen',
    FIELD2: 'YE',
    value: '+967',
    label: 'Yemen (YE)'
  },
  {
    name: 'Yugoslavia',
    FIELD2: 'YU',
    value: '',
    label: 'Yugoslavia (YU)'
  },
  {
    name: 'Zaire  Now CD   Congo',
    FIELD2: 'ZR',
    value: '',
    label: 'Zaire  Now CD   Congo(ZR)'
  },
  {
    name: 'Zambia',
    FIELD2: 'ZM',
    value: '+260',
    label: 'Zambia(ZM)'
  },
  {
    name: 'Zimbabwe',
    FIELD2: 'ZW',
    value: '+263',
    label: 'Zimbabwe(ZW)'
  }
];

export default countriesData.map(country => ({ label: country.label, value: country.name }));
