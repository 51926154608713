import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uiSpinnerSelector } from '../core';

const mapStateToProps = (state, { element }) => ({
  visible: uiSpinnerSelector(state) === element
});

const Spinner = ({ visible }) => (
  visible &&
  <div className="naf-spinner-2">
    <div className="lds-spinner">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

Spinner.propTypes = {
  visible: PropTypes.bool
};

export default connect(mapStateToProps)(Spinner);

