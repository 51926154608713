import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues, getFormError } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { setUIStateAction } from '../../core/actions/index';
import Spinner from '../Spinner';
import { UPLOAD_FILE_MODAL } from '../../Profile/constants';
import { UI } from '../../core/constants';
import validations from '../../core/utils/validations';

const handleChange = handler => ({ target: { files } }) => handler(files.length ? files[0] : null);

const FileInputField = ({
  input: {
    onChange, onBlur, value: omitValue, ...inputProps
  }, meta: omitMeta, ...props
}) => (
  <input
    className="custom-file-input"
    type="file"
    onChange={handleChange(onChange)}
    onBlur={handleChange(onBlur)}
    {...inputProps}
    {...props}
  />
);

const mapStateToProps = (state, { stateIdentifier }) => ({
  formValues: getFormValues('upload-file')(state),
  open: state.getIn([UI, stateIdentifier]),
  formError: getFormError('upload-file')(state)
});

const valuesToFormData = (values) => {
  const formData = new FormData();
  Object.entries(values).forEach(entry => formData.append(entry[0], entry[1]));
  return formData;
};

const mapDispatchToProps = (dispatch, { stateIdentifier, action }) => ({
  close: () => dispatch(setUIStateAction(stateIdentifier, false)),
  onSubmit: values => dispatch(action(valuesToFormData(values.toJS())))
});

FileInputField.propTypes = {
  input: PropTypes.shape(),
  meta: PropTypes.shape()
};

const NafUploadFileModal = ({
  close, open, title, handleSubmit, formValues, extraFields, formError
}) => (
  open ?
    <form onSubmit={handleSubmit} className="naf-modal naf-modal-upload naf-show-modal">
      <div className="naf-modal-content">
        <Spinner element={UPLOAD_FILE_MODAL} />
        <div className="naf-modal-header">
          <h2>{title}</h2>
        </div>
        <div className="naf-modal-body">
          <div className="naf-modal-footer d-flex flex-column1 justify-content-between">
            <div className="d-flex justify-content-between">
              <div className="custom-file">
                <Field validate={[validations.required]} name="attachment" component={FileInputField} />
                <label className="custom-file-label text-left modal-no-text-wrap" htmlFor="cv-file">{ (formValues && formValues.get('attachment') && formValues.get('attachment').name) || 'Choose file'}</label>
              </div>
            </div>
          </div>
          {
            extraFields
          }
          <div className="naf-modal-footer d-flex flex-column1 justify-content-between">
            <div className="d-flex justify-content-between">
              <button type="submit" className="btn btn-light ">UPLOAD NEW FILE</button>
              <button type="button" className="btn btn-light ml-3" onClick={close}>CANCEL</button>
            </div>
          </div>
          {
            formError &&
            <div className="invalid-feedback" style={{ display: 'block' }}>{formError}</div>
          }
        </div>
      </div>
    </form> : null
);


NafUploadFileModal.propTypes = {
  formError: PropTypes.string,
  extraFields: PropTypes.node,
  formValues: PropTypes.shape(),
  close: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  handleSubmit: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'upload-file'
})(NafUploadFileModal));
