import React from 'react';
import PropTypes from 'prop-types';
import { change } from 'redux-form/immutable';
import { DropdownList } from 'react-widgets';
import { connect } from 'react-redux';
import { GENERAL_INFORMATION, GeneralInformationFields } from '../constants';
import { sectionFieldSelector } from '../selectors';
import countries from '../../core/constants/countries';

const mapDispatchToProps = dispatch => ({
  changeHandler: country => dispatch(change(GENERAL_INFORMATION, GeneralInformationFields.COUNTRY, country.value))
});

const mapStateToProps = state => ({
  storedFieldValue: sectionFieldSelector(state, GENERAL_INFORMATION, GeneralInformationFields.COUNTRY)
});

const GeneralInformationCountriesDropDown = props => (
  <DropdownList
    filter="contains"
    searchIcon={<span className="naf-icon-pencil" />}
    defaultValue={props.storedFieldValue}
    data={countries}
    textField="label"
    valueField="name"
    onChange={props.changeHandler}
    placeholder="Please select a Country"
  />
);

GeneralInformationCountriesDropDown.propTypes = {
  changeHandler: PropTypes.func,
  storedFieldValue: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInformationCountriesDropDown);
