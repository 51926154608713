import React, { Fragment } from 'react';
import { DropdownList } from 'react-widgets';
import countries from '../../core/constants/countries';

const renderCountriesDropDown = field => (
  <Fragment>
    <label htmlFor="countries-dd" className="d-flex justify-content-between">
      <span>Country</span>
      {field.meta.touched && field.meta.error && !field.meta.active &&
      <span className="req">{field.meta.error}</span>}
    </label>
    <DropdownList
      id="countries-dd"
      data={countries.map(country => country.value)}
      filter="contains"
      searchIcon={<span className="naf-icon-pencil" />}
      value={field.input.value}
      onChange={field.input.onChange}
      placeholder="Please select a country"
      containerClassName="form-control"
    />
  </Fragment>
);

export default renderCountriesDropDown;
