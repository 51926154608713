import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutAction } from '../../Login/actions/index';
import UnauthenticatedHeader from './UnauthenticatedHeader';
import AuthenticatedHeader from './AuthenticatedHeader';
import { CONTACT_US, MAIN, TERMS } from '../routePaths';
import { burgerUISelector } from '../../core/selectors';
import { setUIStateAction } from '../../core/actions';
import { BURGER } from '../../core/constants';

const mapStateToProps = state => ({
  shouldShowBurgerMenu: burgerUISelector(state)
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutAction()),
  toggleBurgerShow: currentState => dispatch(setUIStateAction(BURGER, !currentState))
});

const HeaderComponent = ({ token, shouldShowBurgerMenu, toggleBurgerShow }) => (
  <nav className="navbar navbar-expand-md navbar-light bg-light naf-navbar">
    <div className="container d-flex">
      <div className="d-flex justify-content-between align-items-center menu-container">
        <Link
          to={MAIN}
          className="navbar-brand text-hide naf-logo-header"
        />
        <button
          className="navbar-toggler"
          onClick={() => toggleBurgerShow(shouldShowBurgerMenu)}
          type="button"
          data-toggle="collapse"
          data-target="#main-nav"
          aria-controls="main-nav"
          aria-expanded={!!shouldShowBurgerMenu}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon show" />
        </button>
      </div>
      <div className={'collapse navbar-collapse '.concat(shouldShowBurgerMenu ? 'show' : '')} id="main-nav">
        <ul className="navbar-nav ml-auto align-items-center">
          <li className="nav-item active">
            <Link to={MAIN} className="nav-link">NEEDaFIXER</Link>
          </li>
          <li className="nav-item active">
            <Link to={CONTACT_US} className="nav-link">CONTACT US</Link>
          </li>
          <li className="nav-item active">
            <Link to={TERMS} className="nav-link">TERMS</Link>
          </li>
          {
            token
              ? <AuthenticatedHeader />
              : <UnauthenticatedHeader />
          }
        </ul>
      </div>
    </div>
  </nav>

);

HeaderComponent.propTypes = {
  token: PropTypes.string,
  toggleBurgerShow: PropTypes.func,
  shouldShowBurgerMenu: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)((HeaderComponent));
