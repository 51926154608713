export default [
  {
    value: 'USD',
    label: 'United States dollar (USD)'
  },
  {
    value: 'EUR',
    label: 'Euro (EUR)'
  },
  {
    value: 'GBP',
    label: 'British pound (GBP)'
  },
  {
    value: 'RUB',
    label: 'Russian ruble (RUB)'
  },
  {
    value: 'AFN',
    label: 'Afghan afghani (AFN)'
  },
  {
    value: 'ALL',
    label: 'Albanian lek (ALL)'
  },
  {
    value: 'GGP',
    label: 'Guernsey pound (GGP)'
  },
  {
    value: 'DZD',
    label: 'Algerian dinar (DZD)'
  },
  {
    value: 'AOA',
    label: 'Angolan kwanza (AOA)'
  },
  {
    value: 'XCD',
    label: 'East Caribbean dollar (XCD)'
  },
  {
    value: 'ARS',
    label: 'Argentine peso (ARS)'
  },
  {
    value: 'AMD',
    label: 'Armenian dram (AMD)'
  },
  {
    value: 'AWG',
    label: 'Aruban florin (AWG)'
  },
  {
    value: 'SHP',
    label: 'Saint Helena pound (SHP)'
  },
  {
    value: 'AUD',
    label: 'Australian dollar (AUD)'
  },
  {
    value: 'AZN',
    label: 'Azerbaijani manat (AZN)'
  },
  {
    value: 'BSD',
    label: 'Bahamian dollar (BSD)'
  },
  {
    value: 'BHD',
    label: 'Bahraini dinar (BHD)'
  },
  {
    value: 'BDT',
    label: 'Bangladeshi taka (BDT)'
  },
  {
    value: 'BBD',
    label: 'Barbadian dollar (BBD)'
  },
  {
    value: 'BYR',
    label: 'Belarusian ruble (BYR)'
  },
  {
    value: 'BZD',
    label: 'Belize dollar (BZD)'
  },
  {
    value: 'XOF',
    label: 'West African CFA franc (XOF)'
  },
  {
    value: 'BMD',
    label: 'Bermudian dollar (BMD)'
  },
  {
    value: 'BTN',
    label: 'Bhutanese ngultrum (BTN)'
  },
  {
    value: 'INR',
    label: 'Indian rupee (INR)'
  },
  {
    value: 'BOB',
    label: 'Bolivian boliviano (BOB)'
  },
  {
    value: 'BAM',
    label: 'Bosnia and Herzegovina convertible mark (BAM)'
  },
  {
    value: 'BWP',
    label: 'Botswana pula (BWP)'
  },
  {
    value: 'BRL',
    label: 'Brazilian real (BRL)'
  },
  {
    value: 'BND',
    label: 'Brunei dollar (BND)'
  },
  {
    value: 'SGD',
    label: 'Singapore dollar (SGD)'
  },
  {
    value: 'BGN',
    label: 'Bulgarian lev (BGN)'
  },
  {
    value: 'BIF',
    label: 'Burundian franc (BIF)'
  },
  {
    value: 'KHR',
    label: 'Cambodian riel (KHR)'
  },
  {
    value: 'XAF',
    label: 'Central African CFA franc (XAF)'
  },
  {
    value: 'CAD',
    label: 'Canadian dollar (CAD)'
  },
  {
    value: 'CVE',
    label: 'Cape Verdean escudo (CVE)'
  },
  {
    value: 'KYD',
    label: 'Cayman Islands dollar (KYD)'
  },
  {
    value: 'CLP',
    label: 'Chilean peso (CLP)'
  },
  {
    value: 'CNY',
    label: 'Chinese yuan (CNY)'
  },
  {
    value: 'COP',
    label: 'Colombian peso (COP)'
  },
  {
    value: 'KMF',
    label: 'Comorian franc (KMF)'
  },
  {
    value: 'CDF',
    label: 'Congolese franc (CDF)'
  },
  {
    value: 'NZD',
    label: 'New Zealand dollar (NZD)'
  },
  {
    value: 'CRC',
    label: 'Costa Rican colón (CRC)'
  },
  {
    value: 'HRK',
    label: 'Croatian kuna (HRK)'
  },
  {
    value: 'CUC',
    label: 'Cuban convertible peso (CUC)'
  },
  {
    value: 'CUP',
    label: 'Cuban peso (CUP)'
  },
  {
    value: 'ANG',
    label: 'Netherlands Antillean guilder (ANG)'
  },
  {
    value: 'CZK',
    label: 'Czech koruna (CZK)'
  },
  {
    value: 'DKK',
    label: 'Danish krone (DKK)'
  },
  {
    value: 'DJF',
    label: 'Djiboutian franc (DJF)'
  },
  {
    value: 'DOP',
    label: 'Dominican peso (DOP)'
  },
  {
    value: 'EGP',
    label: 'Egyptian pound (EGP)'
  },
  {
    value: 'ERN',
    label: 'Eritrean nakfa (ERN)'
  },
  {
    value: 'ETB',
    label: 'Ethiopian birr (ETB)'
  },
  {
    value: 'FKP',
    label: 'Falkland Islands pound (FKP)'
  },
  {
    value: '',
    label: 'Faroese króna ()'
  },
  {
    value: 'FJD',
    label: 'Fijian dollar (FJD)'
  },
  {
    value: 'XPF',
    label: 'CFP franc (XPF)'
  },
  {
    value: 'GMD',
    label: 'Gambian dalasi (GMD)'
  },
  {
    value: 'GEL',
    label: 'Georgian lari (GEL)'
  },
  {
    value: 'GHS',
    label: 'Ghana cedi (GHS)'
  },
  {
    value: 'GIP',
    label: 'Gibraltar pound (GIP)'
  },
  {
    value: 'GTQ',
    label: 'Guatemalan quetzal (GTQ)'
  },
  {
    value: 'GNF',
    label: 'Guinean franc (GNF)'
  },
  {
    value: 'GYD',
    label: 'Guyanese dollar (GYD)'
  },
  {
    value: 'HTG',
    label: 'Haitian gourde (HTG)'
  },
  {
    value: 'HNL',
    label: 'Honduran lempira (HNL)'
  },
  {
    value: 'HKD',
    label: 'Hong Kong dollar (HKD)'
  },
  {
    value: 'HUF',
    label: 'Hungarian forint (HUF)'
  },
  {
    value: 'ISK',
    label: 'Icelandic króna (ISK)'
  },
  {
    value: 'IDR',
    label: 'Indonesian rupiah (IDR)'
  },
  {
    value: 'IRR',
    label: 'Iranian rial (IRR)'
  },
  {
    value: 'IQD',
    label: 'Iraqi dinar (IQD)'
  },
  {
    value: 'ILS',
    label: 'Israeli new shekel (ILS)'
  },
  {
    value: 'JMD',
    label: 'Jamaican dollar (JMD)'
  },
  {
    value: 'JPY',
    label: 'Japanese yen (JPY)'
  },
  {
    value: 'JOD',
    label: 'Jordanian dinar (JOD)'
  },
  {
    value: 'KES',
    label: 'Kenyan shilling (KES)'
  },
  {
    value: 'KPW',
    label: 'North Korean won (KPW)'
  },
  {
    value: 'KRW',
    label: 'South Korean won (KRW)'
  },
  {
    value: 'KWD',
    label: 'Kuwaiti dinar (KWD)'
  },
  {
    value: 'KGS',
    label: 'Kyrgyzstani som (KGS)'
  },
  {
    value: 'LAK',
    label: 'Lao kip (LAK)'
  },
  {
    value: 'LBP',
    label: 'Lebanese pound (LBP)'
  },
  {
    value: 'LSL',
    label: 'Lesotho loti (LSL)'
  },
  {
    value: 'ZAR',
    label: 'South African rand (ZAR)'
  },
  {
    value: 'LRD',
    label: 'Liberian dollar (LRD)'
  },
  {
    value: 'LYD',
    label: 'Libyan dinar (LYD)'
  },
  {
    value: 'CHF',
    label: 'Swiss franc (CHF)'
  },
  {
    value: 'MOP',
    label: 'Macanese pataca (MOP)'
  },
  {
    value: 'MKD',
    label: 'Macedonian denar (MKD)'
  },
  {
    value: 'MGA',
    label: 'Malagasy ariary (MGA)'
  },
  {
    value: 'MWK',
    label: 'Malawian kwacha (MWK)'
  },
  {
    value: 'MYR',
    label: 'Malaysian ringgit (MYR)'
  },
  {
    value: 'MVR',
    label: 'Maldivian rufiyaa (MVR)'
  },
  {
    value: 'MRO',
    label: 'Mauritanian ouguiya (MRO)'
  },
  {
    value: 'MUR',
    label: 'Mauritian rupee (MUR)'
  },
  {
    value: 'MXN',
    label: 'Mexican peso (MXN)'
  },
  {
    value: 'MDL',
    label: 'Moldovan leu (MDL)'
  },
  {
    value: 'MNT',
    label: 'Mongolian tögrög (MNT)'
  },
  {
    value: 'MAD',
    label: 'Moroccan dirham (MAD)'
  },
  {
    value: 'MZN',
    label: 'Mozambican metical (MZN)'
  },
  {
    value: 'MMK',
    label: 'Burmese kyat (MMK)'
  },
  {
    value: 'NAD',
    label: 'Namibian dollar (NAD)'
  },
  {
    value: 'NPR',
    label: 'Nepalese rupee (NPR)'
  },
  {
    value: 'NIO',
    label: 'Nicaraguan córdoba (NIO)'
  },
  {
    value: 'NGN',
    label: 'Nigerian naira (NGN)'
  },
  {
    value: 'TRY',
    label: 'Turkish lira (TRY)'
  },
  {
    value: 'NOK',
    label: 'Norwegian krone (NOK)'
  },
  {
    value: 'OMR',
    label: 'Omani rial (OMR)'
  },
  {
    value: 'PKR',
    label: 'Pakistani rupee (PKR)'
  },
  {
    value: 'PAB',
    label: 'Panamanian balboa (PAB)'
  },
  {
    value: 'PGK',
    label: 'Papua New Guinean kina (PGK)'
  },
  {
    value: 'PYG',
    label: 'Paraguayan guaraní (PYG)'
  },
  {
    value: 'PEN',
    label: 'Peruvian nuevo sol (PEN)'
  },
  {
    value: 'PHP',
    label: 'Philippine peso (PHP)'
  },
  {
    value: 'PLN',
    label: 'Polish złoty (PLN)'
  },
  {
    value: 'QAR',
    label: 'Qatari riyal (QAR)'
  },
  {
    value: 'RON',
    label: 'Romanian leu (RON)'
  },
  {
    value: 'RWF',
    label: 'Rwandan franc (RWF)'
  },
  {
    value: 'WST',
    label: 'Samoan tālā (WST)'
  },
  {
    value: 'STD',
    label: 'São Tomé and Príncipe dobra (STD)'
  },
  {
    value: 'SAR',
    label: 'Saudi riyal (SAR)'
  },
  {
    value: 'RSD',
    label: 'Serbian dinar (RSD)'
  },
  {
    value: 'SCR',
    label: 'Seychellois rupee (SCR)'
  },
  {
    value: 'SLL',
    label: 'Sierra Leonean leone (SLL)'
  },
  {
    value: 'SBD',
    label: 'Solomon Islands dollar (SBD)'
  },
  {
    value: 'SOS',
    label: 'Somali shilling (SOS)'
  },
  {
    value: 'SSP',
    label: 'South Sudanese pound (SSP)'
  },
  {
    value: 'LKR',
    label: 'Sri Lankan rupee (LKR)'
  },
  {
    value: 'SDG',
    label: 'Sudanese pound (SDG)'
  },
  {
    value: 'SRD',
    label: 'Surinamese dollar (SRD)'
  },
  {
    value: 'SZL',
    label: 'Swazi lilangeni (SZL)'
  },
  {
    value: 'SEK',
    label: 'Swedish krona (SEK)'
  },
  {
    value: 'SYP',
    label: 'Syrian pound (SYP)'
  },
  {
    value: 'TWD',
    label: 'New Taiwan dollar (TWD)'
  },
  {
    value: 'TJS',
    label: 'Tajikistani somoni (TJS)'
  },
  {
    value: 'TZS',
    label: 'Tanzanian shilling (TZS)'
  },
  {
    value: 'THB',
    label: 'Thai baht (THB)'
  },
  {
    value: 'TOP',
    label: 'Tongan paʻanga (TOP)'
  },
  {
    value: 'TTD',
    label: 'Trinidad and Tobago dollar (TTD)'
  },
  {
    value: 'TND',
    label: 'Tunisian dinar (TND)'
  },
  {
    value: 'TMT',
    label: 'Turkmenistan manat (TMT)'
  },
  {
    value: 'UGX',
    label: 'Ugandan shilling (UGX)'
  },
  {
    value: 'UAH',
    label: 'Ukrainian hryvnia (UAH)'
  },
  {
    value: 'AED',
    label: 'United Arab Emirates dirham (AED)'
  },
  {
    value: 'UYU',
    label: 'Uruguayan peso (UYU)'
  },
  {
    value: 'UZS',
    label: 'Uzbekistani som (UZS)'
  },
  {
    value: 'VUV',
    label: 'Vanuatu vatu (VUV)'
  },
  {
    value: 'VEF',
    label: 'Venezuelan bolívar (VEF)'
  },
  {
    value: 'VND',
    label: 'Vietnamese đồng (VND)'
  },
  {
    value: 'YER',
    label: 'Yemeni rial (YER)'
  },
  {
    value: 'ZMW',
    label: 'Zambian kwacha (ZMW)'
  }
];
