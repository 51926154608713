import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset, submit } from 'redux-form/immutable';
import { setUIStateAction } from '../../../../../core/actions/index';
import { MORE_INFORMATION } from '../../../../constants/index';

const mapDispatchToProps = dispatch => ({
  setViewMode: () => {
    dispatch(reset(MORE_INFORMATION));
    dispatch(setUIStateAction([MORE_INFORMATION, 'edit'], ''));
    dispatch(setUIStateAction([MORE_INFORMATION, 'add'], ''));
  },
  submitMoreInformationSection: () => dispatch(submit(MORE_INFORMATION))
});


const Buttons = ({ submitMoreInformationSection, setViewMode }) => (
  <React.Fragment>
    <button className="btn btn-outline-success naf-icon-btn" type="button" onClick={() => submitMoreInformationSection()}>
      <span className="naf-icon-check" />
    </button>
    <button className="btn btn-outline-danger naf-icon-btn" type="button" onClick={() => setViewMode()}>
      <span className="naf-icon-times" />
    </button>
  </React.Fragment>
);

Buttons.propTypes = {
  submitMoreInformationSection: PropTypes.func,
  setViewMode: PropTypes.func

};


export default connect(null, mapDispatchToProps)(Buttons);

