import React from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { setUIStateAction } from '../../../../../core/actions/index';

const mapDispatchToProps = (dispatch, { input, meta: { form } }) => ({
  setEditMode: () => {
    dispatch(reset(form));
    dispatch(setUIStateAction([form, 'edit'], input.name));
  }
});

const MoreInformationFieldView = ({
  label, RenderView, input, setEditMode, fieldConfig
}) => (
  <div className="mb-3 naf-hoverable">
    <strong>{label.toUpperCase()}:
      <button
        type="button"
        className="btn btn-link naf-edit-btn btn-sm naf-hoverable-show"
        onClick={() => setEditMode()}
      ><span
        className="naf-icon-pencil"
      />
      </button>
    </strong>
    <RenderView input={input} fieldConfig={fieldConfig} />
  </div>
);

MoreInformationFieldView.propTypes = {
  label: PropTypes.string,
  RenderView: PropTypes.node,
  setEditMode: PropTypes.func,
  input: PropTypes.shape(),
  fieldConfig: PropTypes.arrayOf(PropTypes.shape())
};

export default connect(null, mapDispatchToProps)(MoreInformationFieldView);
