import React, { Fragment } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form/immutable';
import {
  accountEmailChangeModalUISelector,
  accountEmailModeUIStateSelector,
  existingAccountEmailSelector,
  accountPasswordModeUIStateSelector,
  createdDateSelector,
  profileStatusSelector,
  accountEmailModalStatusSelector,
  accountEmailModalUpdatingSelector
} from '../selectors';
import { profileStatusRenderer, statusTrafficLightRenderer } from './utils';
import { EDIT_MODE, UPDATE_ACCOUNT_EMAIL_FORM, VIEW_MODE } from '../constants';
import AccountUpdateEmailForm from './AccountUpdateEmailForm';
import AccountUpdateEmailView from './AccountUpdateEmailView';
import AccountUpdatePasswordView from './AccountUpdatePasswordView';
import AccountUpdatePasswordForm from './AccountUpdatePasswordForm';
import { updateAccountPasswordAction } from '../actions';
import { ACCOUNT, EMAIL, MODAL, MODAL_STATUS, WARNING } from '../../core/constants';
import { setUIStateAction } from '../../core/actions';
import { CONFIRM_NEW_PASSWORD, EXISTING_PASSWORD } from './constants';

const mapStateToProps = state => ({
  createdDate: createdDateSelector(state) || '',
  profileStatus: profileStatusSelector(state),
  emailMode: accountEmailModeUIStateSelector(state),
  passwordMode: accountPasswordModeUIStateSelector(state),
  existingAccountEmail: existingAccountEmailSelector(state),
  newAccountEmail: formValueSelector(UPDATE_ACCOUNT_EMAIL_FORM)(state, EMAIL),
  shouldShowEmailChangeModal: accountEmailChangeModalUISelector(state),
  emailChangeModalStatus: accountEmailModalStatusSelector(state),
  emailChangeModalUpdating: accountEmailModalUpdatingSelector(state),
});

const Account = (props) => {
  const {
    createdDate,
    profileStatus,
    emailMode,
    passwordMode,
    existingAccountEmail,
    newAccountEmail,
    shouldShowEmailChangeModal,
    emailChangeModalUpdating,
    emailChangeModalStatus
  } = props;
  return (
    <Fragment>
      <div className="naf-black-box mt-5 mb-2">
        <div className="d-flex flex-column flex-md-row justify-content-start align-items-center">
          <h4 className="mb-0">Account</h4>
        </div>
      </div>
      <div className="card naf-card">
        <div className="card naf-experience-item mb-4">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-lg-6 naf-my-account-col">
                <div className="naf-my-account-col-inner">
                  <div className="naf-my-account-label">Status</div>
                  <div className="naf-my-account-value naf-my-account-hr">
                    { profileStatusRenderer(profileStatus)}
                    <span className={`naf-status naf-status-${statusTrafficLightRenderer(profileStatus)} ml-2`} />
                  </div>
                  <div className="naf-my-account-label">Member Since</div>
                  <div className="naf-my-account-value naf-my-account-hr">{ createdDate }</div>
                  <div className="naf-hoverable naf-edit-row">
                    <div className="naf-my-account-label">Email</div>
                    {
                      emailMode === EDIT_MODE
                        ? <AccountUpdateEmailForm
                            shouldShowModal={shouldShowEmailChangeModal}
                            status={emailChangeModalStatus}
                            updating={emailChangeModalUpdating}
                            newAccountEmail={newAccountEmail}
                            onSubmit={(formValues, dispatch) => {
                            if (existingAccountEmail !== formValues.get(EMAIL)) {
                              dispatch(setUIStateAction([ACCOUNT, MODAL], true));
                              dispatch(setUIStateAction([ACCOUNT, MODAL_STATUS], WARNING));
                            } else {
                              dispatch(setUIStateAction([ACCOUNT, EMAIL], VIEW_MODE));
                            }
                          }}
                            initialValues={Map({ email: existingAccountEmail })}
                        />
                        : <AccountUpdateEmailView existingAccountEmail={existingAccountEmail} />
                    }
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 naf-my-account-col">
                <div className="naf-my-account-col-inner">
                  <div className="naf-hoverable naf-edit-row">
                    <div className="naf-my-account-label">Password</div>
                    {
                      passwordMode === EDIT_MODE
                        ? <AccountUpdatePasswordForm
                            onSubmit={(formValues, dispatch) =>
                            dispatch(updateAccountPasswordAction(formValues.get(EXISTING_PASSWORD), formValues.get(CONFIRM_NEW_PASSWORD)))}
                        />
                        : <AccountUpdatePasswordView />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Account.propTypes = {
  createdDate: PropTypes.string,
  profileStatus: PropTypes.string,
  emailMode: PropTypes.string,
  passwordMode: PropTypes.string,
  existingAccountEmail: PropTypes.string,
  newAccountEmail: PropTypes.string,
  emailChangeModalStatus: PropTypes.string,
  shouldShowEmailChangeModal: PropTypes.bool,
  emailChangeModalUpdating: PropTypes.bool
};

export default connect(mapStateToProps)(Account);
