import { CONFIRM_NEW_PASSWORD, EXISTING_PASSWORD, NEW_PASSWORD } from './constants';
import { MIN_PASSWORD_LENGTH } from '../../Register/constants';

const updateAccountPasswordValidator = (values) => {
  const errors = {};
  [EXISTING_PASSWORD, NEW_PASSWORD, CONFIRM_NEW_PASSWORD].forEach((fieldName) => {
    if (!values.get(fieldName)) {
      errors[fieldName] = 'Required';
    }
    if (values.get(fieldName) && values.get(fieldName).length < MIN_PASSWORD_LENGTH) {
      errors[fieldName] = `Password must be at least ${MIN_PASSWORD_LENGTH} characters long`;
    }
  });
  const newPassword = values.get(NEW_PASSWORD);
  const existingPassword = values.get(EXISTING_PASSWORD);
  const confirmNewPassword = values.get(CONFIRM_NEW_PASSWORD);
  if (existingPassword && newPassword && (newPassword === existingPassword)) {
    errors[NEW_PASSWORD] = 'Cannot use existing password as new';
  }
  if (newPassword && confirmNewPassword && (newPassword !== confirmNewPassword)) {
    errors[CONFIRM_NEW_PASSWORD] = 'Passwords must match';
  }
  return errors;
};

export default updateAccountPasswordValidator;
