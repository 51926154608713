/* eslint-disable import/prefer-default-export */
import { change } from 'redux-form/immutable';
import { setUIStateAction } from '../../core/actions';
import { searchAction } from '../actions';
import {
  CARDS,
  CITY, CITY_FILTER_PLACEHOLDER,
  COUNTRY,
  COUNTRY_FILTER_PLACEHOLDER,
  FILTER_FORM, LIST, PAGE, RESULTS_VIEW_MODE,
  ROLE,
  ROLE_FILTER_PLACEHOLDER,
  SearchFormFields
} from '../constants';

export const searchSubmissionHandler = (formData, dispatch, { adminRole }) => {
  const role = formData.get(SearchFormFields.ROLE);
  const country = formData.get(SearchFormFields.COUNTRY) && formData.get(SearchFormFields.COUNTRY).value;
  dispatch(change(FILTER_FORM, ROLE, role || ROLE_FILTER_PLACEHOLDER));
  dispatch(change(FILTER_FORM, COUNTRY, country || COUNTRY_FILTER_PLACEHOLDER));
  dispatch(change(FILTER_FORM, CITY, CITY_FILTER_PLACEHOLDER));
  dispatch(change(FILTER_FORM, PAGE, 0));
  if (adminRole === 'ROLE_COORDINATOR') {
    dispatch(setUIStateAction(RESULTS_VIEW_MODE, CARDS));
  } else {
    dispatch(setUIStateAction(RESULTS_VIEW_MODE, LIST));
  }
  dispatch(searchAction(role, country));
};
