import { Map } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import ui from './ui';
import error from './errorReducer';
import profile from '../../Profile/reducers/index';
import { CLEAR_STATE } from '../actions/index';
import search from '../../Search/reducers';
import user from '../../Login/reducers';

const navigation = (state = Map(), action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case 'SET_INTENDED_ROUTE':
      return state.set('intendedRoute', action.payload);
    default:
      return state;
  }
};

const combinedReducers = combineReducers({
  form: formReducer,
  error,
  ui,
  profile,
  search,
  user,
  navigation
});

/**
 * The root reducer.
 */
const rootReducer = (state, action) => {
  let finalState = state;
  if (action.type === CLEAR_STATE) {
    // Clear the state but keep the selected locale
    // and any form data
    finalState = Map()
      .set('form', state.get('form'));
  }
  return combinedReducers(finalState, action);
};

export default rootReducer;
