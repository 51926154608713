import React from 'react';
import PropTypes from 'prop-types';
import { SKILLS } from './constants/index';
import nafCardEditableDeletable from '../widgets/ProfileSectionForm/SectionType/FieldArraySection';

const SkillsForm = nafCardEditableDeletable(SKILLS);

const RenderSkillEdit = ({ input, meta }) => (
  <input
    type="text"
    className={`form-control ${meta.error && meta.touched && !meta.active ? 'is-invalid' : null}`}
    placeholder="New skill..."
    {...input}
  />
);

RenderSkillEdit.propTypes = {
  input: PropTypes.shape(),
  meta: PropTypes.shape()
};

const RenderSkillView = ({ input }) => (
  <span>{input.value}</span>
);

RenderSkillView.propTypes = {
  input: PropTypes.shape()
};

const Skills = ({ initialValues }) => (
  <SkillsForm initialValues={initialValues} inputType="text" RenderFieldView={RenderSkillView} RenderFieldEdit={RenderSkillEdit} hasTwoColumns />
);

Skills.propTypes = {
  initialValues: PropTypes.shape()
};

export default Skills;
