/* eslint-disable import/prefer-default-export */
import { formValueSelector } from 'redux-form/immutable';
import {
  CITY,
  CONTENT,
  COUNTRY,
  DAILY_RATE_FROM,
  DAILY_RATE_TO, FILTER_FORM,
  FILTERS, FIRST, LAST,
  MODE, NAME, NUMBER,
  PROFILE_STATUS_CATEGORY, PROFILE_STATUS_CHANGE_MODAL,
  ProfileStatus, RATE,
  RESULTS, RESULTS_VIEW_MODE, ROLE,
  SEARCH, SORTING,
  SUMMARY, TOTAL_PAGES
} from '../constants';
import { UI } from '../../core/constants';

const filtersSelector = formValueSelector(FILTER_FORM);

export const fullMembersCountSelector = state => state.getIn([SEARCH, SUMMARY, ProfileStatus.FULL]);
export const underReviewCountSelector = state => state.getIn([SEARCH, SUMMARY, ProfileStatus.UNDER_REVIEW]);
export const missingInformationCountSelector = state => state.getIn([SEARCH, SUMMARY, ProfileStatus.MISSING_INFORMATION]);
export const migratedCountSelector = state => state.getIn([SEARCH, SUMMARY, ProfileStatus.MIGRATED]);
export const ignoreCountSelector = state => state.getIn([SEARCH, SUMMARY, ProfileStatus.IGNORE]);

export const searchResultsSelector = state => state.getIn([SEARCH, RESULTS, CONTENT]);
export const totalPagesSelector = state => state.getIn([SEARCH, RESULTS, TOTAL_PAGES]);
export const isFirstPageSelector = state => state.getIn([SEARCH, RESULTS, FIRST]);
export const isLastPageSelector = state => state.getIn([SEARCH, RESULTS, LAST]);
export const pageNumberSelector = state => state.getIn([SEARCH, RESULTS, NUMBER]);


export const roleFilterValueSelector = state => filtersSelector(state, ROLE);
export const countryFilterValueSelector = state => filtersSelector(state, COUNTRY);
export const dailyRateFromFilterValueSelector = state => filtersSelector(state, DAILY_RATE_FROM);
export const dailyRateToFilterValueSelector = state => filtersSelector(state, DAILY_RATE_TO);
export const nameFilterValueSelector = state => filtersSelector(state, NAME);

export const cityFilterValueSelector = state => filtersSelector(state, CITY);

export const sortingSelector = state => state.getIn([SEARCH, SORTING]);

export const nameSortingSelector = state => state.getIn([SEARCH, SORTING, NAME]);
export const roleSortingSelector = state => state.getIn([SEARCH, SORTING, ROLE]);
export const rateSortingSelector = state => state.getIn([SEARCH, SORTING, RATE]);
export const countrySortingSelector = state => state.getIn([SEARCH, SORTING, COUNTRY]);
export const citySortingSelector = state => state.getIn([SEARCH, SORTING, CITY]);

export const roleFilterUIModeSelector = state => state.getIn([UI, SEARCH, FILTERS, ROLE]);
export const countryFilterUIModeSelector = state => state.getIn([UI, SEARCH, FILTERS, COUNTRY]);
export const cityFilterUIModeSelector = state => state.getIn([UI, SEARCH, FILTERS, CITY]);
export const rateFilterUIModeSelector = state => state.getIn([UI, SEARCH, FILTERS, RATE]);
export const nameFilterSelector = state => state.getIn([UI, SEARCH, FILTERS, NAME]);

export const profileStatusChangeModalUISelector = state => state.getIn([UI, PROFILE_STATUS_CHANGE_MODAL]);

export const resultsViewModeSelector = state => state.getIn([UI, RESULTS_VIEW_MODE]);

export const profileStatusCategorySelector = state => state.getIn([UI, SEARCH, PROFILE_STATUS_CATEGORY]);
export const searchUIModeSelector = state => state.getIn([UI, SEARCH, MODE]);
