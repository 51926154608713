import { SUCCESS } from '../../core/constants';

export const REQUEST_RESET_PASSWORD = 'requestResetPasswordForm';
export const REQUEST_RESET_PASSWORD_RESULT = 'requestResetPasswordResult';
export const REQUEST_RESET_PASSWORD_BTN_DISABLED = 'requestResetPasswordBtnDisabled';
export const REQUEST_RESET_PASSWORD_RESULT_MODAL_VISIBLE = 'showResetPasswordRequestResultModal';
export const FORGOT_PASSWORD_LITERAL = 'FORGOT MY PASSWORD';
export const RESET_EMAIL_FIELD = 'email';
export const SET_NEW_PASSWORD_SUBMIT = 'SET_NEW_PASSWORD_SUBMIT';

export const requestResetPasswordModalMessageRenderer = (result, email) => {
  if (result === SUCCESS) {
    return `Success! Reset password instructions have been sent to ${email}`;
  }
  return 'Failed to reset password.';
};
