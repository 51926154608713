import { call, takeLatest } from 'redux-saga/effects';
import { SEND_ERROR_LOG } from '../actions';
import { unauthorizedRequest } from '../restClient';

function* handleSendErrorLogSaga(action) {
  const { error } = action.payload;
  try {
    yield call(unauthorizedRequest, 'POST', '/client-error', error);
  } catch (err) {
    yield alert('Could not sent client error');
  }
}

export default function* watchCoreRequests() {
  yield takeLatest(
    SEND_ERROR_LOG,
    handleSendErrorLogSaga
  );
}
