import { call } from 'redux-saga/effects';
import moment from 'moment';
import { isNafEmail, isValidEmail, isValidMonetaryAmount, isValidPhoneNumber } from '../../core/utils';
import { checkEmailAvailability } from '../../utils/sagas';
import { GeneralInformationFields } from '../constants';

export function* validateEmail(email, uuid) {
  const errors = {};
  if (!email) {
    errors[GeneralInformationFields.EMAIL] = 'Required';
  } else {
    if (!isValidEmail(email) || isNafEmail(email)) {
      errors[GeneralInformationFields.EMAIL] = 'Invalid Email';
    }
    if (!errors[GeneralInformationFields.EMAIL]) {
      const isAvailable = yield call(checkEmailAvailability, email, uuid);
      if (isAvailable === false) {
        errors[GeneralInformationFields.EMAIL] = 'E-mail already registered';
      }
      if (isAvailable === undefined) {
        errors[GeneralInformationFields.EMAIL] = 'Could not check email availability. Try again.';
      }
    }
  }
  return errors;
}

export const validatePhones = (phone, secondaryPhone) => {
  const errors = {};
  if (phone && !isValidPhoneNumber(phone)) {
    errors[GeneralInformationFields.PHONE] = 'Invalid phone number';
  }
  if (secondaryPhone && !isValidPhoneNumber(secondaryPhone)) {
    errors[GeneralInformationFields.SECONDARY_PHONE] = 'Invalid phone number';
  }
  return errors;
};

export const validateAmount = (amount) => {
  let errors = {};
  if (amount && !isValidMonetaryAmount(amount)) {
    errors = { dailyRate: { amount: 'Invalid Amount' } };
  }
  return errors;
};

export const required = (value) => {
  if (!value) {
    return 'Required Field';
  }
  return undefined;
};

export const requiredEmail = (value) => {
  if (!value) {
    return 'Required Field';
  }
  if (!isValidEmail(value)) {
    return 'Invalid Email';
  }
  return undefined;
};

export const requiredPhoneNumber = (value) => {
  if (!value) {
    return 'Required Field';
  }
  if (!isValidPhoneNumber(value)) {
    return 'Invalid Phone Number';
  }
  return undefined;
};

const getPropIndexFromMember = (member) => {
  const prop = member.split('[')[0];
  const index = Number.parseInt(member.split(']')[0].split('[')[1], 2);
  return { prop, index };
};

export const isLaterThan = (fieldName, member) => (value, values) => {
  const compareDate = values.getIn([
    getPropIndexFromMember(member).prop,
    getPropIndexFromMember(member).index,
    fieldName
  ]);
  if (moment(value).isBefore(moment(compareDate))) {
    return 'Should be later than the start date';
  }
  return undefined;
};

export const isEarlierThan = (fieldName, member) => (value, values) => {
  const compareDate = values.getIn([
    getPropIndexFromMember(member).prop,
    getPropIndexFromMember(member).index,
    fieldName
  ]);
  if (moment(value).isAfter(moment(compareDate))) {
    return 'Should be earlier than the end date';
  }
  return undefined;
};
