import React from 'react';
import PropTypes from 'prop-types';
import { change, formValueSelector } from 'redux-form/immutable';
import { Multiselect } from 'react-widgets';
import { connect } from 'react-redux';
import roles from '../../core/constants/roles';
import { GENERAL_INFORMATION, GeneralInformationFields, MAX_ROLES_SIZE } from '../constants';
import { sectionFieldSelector } from '../selectors';

const mapStateToProps = (state) => {
  const currentRolesValue = formValueSelector(GENERAL_INFORMATION)(state, GeneralInformationFields.ROLES);
  const savedRoles = sectionFieldSelector(state, GENERAL_INFORMATION, GeneralInformationFields.ROLES)
    && (sectionFieldSelector(state, GENERAL_INFORMATION, GeneralInformationFields.ROLES)).toArray();
  return {
    savedRoles,
    disabledValues: (currentRolesValue && (currentRolesValue.length >= MAX_ROLES_SIZE || currentRolesValue.size >= MAX_ROLES_SIZE)
      && roles.filter(role => !currentRolesValue.includes(role)))
  };
};


const mapDispatchToProps = dispatch => ({
  handleChange: val => dispatch(change(GENERAL_INFORMATION, GeneralInformationFields.ROLES, val))
});

const GeneralInformationRolesMultiSelect = (props) => {
  const { savedRoles, handleChange, disabledValues } = props;
  return (
    <Multiselect
      placeholder="Please select up to 3 roles"
      data={roles}
      defaultValue={savedRoles}
      onChange={handleChange}
      disabled={disabledValues}
    />
  );
};


GeneralInformationRolesMultiSelect.propTypes = {
  savedRoles: PropTypes.arrayOf(PropTypes.string),
  disabledValues: PropTypes.arrayOf(PropTypes.string),
  handleChange: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInformationRolesMultiSelect);
