/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { change } from 'redux-form/immutable';
import { connect } from 'react-redux';
import {
  isFirstPageSelector,
  isLastPageSelector,
  pageNumberSelector,
  totalPagesSelector
} from './selectors';
import { FILTER_FORM, PAGE } from './constants';
import { searchAction } from './actions';

const mapStateToProps = state => ({
  totalPages: totalPagesSelector(state),
  currentPage: pageNumberSelector(state),
  isFirstPage: isFirstPageSelector(state),
  isLastPage: isLastPageSelector(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handlePageClick: (data) => {
    console.log('data is: ', data);
    const {
      role,
      country,
      dailyRateFrom,
      dailyRateTo,
      city,
      name
    } = ownProps;
    dispatch(change(FILTER_FORM, PAGE, data.selected));
    dispatch(searchAction(role, country, dailyRateFrom, dailyRateTo, city, data.selected, name));
  }
});

const Pagination = (props) => {
  const {
    totalPages,
    handlePageClick,
    currentPage
  } = props;
  return (
    <ReactPaginate
      initialPage={currentPage}
      disabledClassName="disabled"
      previousLabel="«"
      nextLabel="»"
      breakLabel={<a className="page-link" href="">...</a>}
      breakClassName="page-item"
      pageCount={totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageClick}
      containerClassName="pagination justify-content-center"
      activeClassName="active"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
    />
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number,
  handlePageClick: PropTypes.func,
  currentPage: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
