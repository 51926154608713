import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset, submit } from 'redux-form/immutable';
import {
  HIDE_ADD_PRJ_BTN,
  HIDE_SUBMIT_CANCEL_PRJ_BTN,
  UPDATE_EXISTING_PROJECT_FORM, VIEW_MODE
} from '../constants';
import ProjectForm from './ProjectForm';
import { ADD, HIDE_CONTROLS, MODE, PROJECTS, UPDATE } from '../../core/constants';
import { setUIStateAction } from '../../core/actions';
import { submitCancelUpdatePrjBtnUISelector } from '../selectors';
import { updateExistingProjectAction } from '../actions';

const mapStateToProps = (state, { index }) => ({
  shouldHideSubmitCancelBtns: submitCancelUpdatePrjBtnUISelector(state, index) === true
});

const mapDispatchToProps = (dispatch, { index, projects }) => ({
  handleSubmitUpdatePrj: () => dispatch(submit(UPDATE_EXISTING_PROJECT_FORM)),
  handleCancelUpdatePrj: () => {
    projects.forEach((prj, ind) => {
      dispatch(setUIStateAction([PROJECTS, UPDATE, HIDE_CONTROLS, ind], false));
    });
    dispatch(reset(UPDATE_EXISTING_PROJECT_FORM));
    dispatch(setUIStateAction([PROJECTS, UPDATE, HIDE_SUBMIT_CANCEL_PRJ_BTN, index], true));
    dispatch(setUIStateAction([PROJECTS, UPDATE, MODE, index], VIEW_MODE));
    dispatch(setUIStateAction([PROJECTS, ADD, HIDE_ADD_PRJ_BTN], false));
  },
});

const UpdateExistingProjectForm = (props) => {
  const {
    shouldHideSubmitCancelBtns,
    handleSubmitUpdatePrj,
    handleCancelUpdatePrj,
    index
  } = props;
  return (
    <Fragment>
      <ProjectForm
        form={UPDATE_EXISTING_PROJECT_FORM}
        onSubmit={(formValues, dispatch) => dispatch(updateExistingProjectAction(formValues, index))}
      />
      <div className="row">
        <div className="card naf-card col-md-12">
          <div className="card-body">
            <div className="card naf-experience-item mb-4">
              <div className="d-flex justify-content-between flex-md-row flex-column">
                {
                  !shouldHideSubmitCancelBtns && (
                    <div className="d-flex justify-content-center justify-content-md-end naf-input-group">
                      {/* <div className="naf-error-msg mr-2">Error message here</div> */ }
                      <button
                        className="btn btn-outline-success naf-icon-btn"
                        type="button"
                        onClick={handleSubmitUpdatePrj}
                      >
                        <span className="naf-icon-check" />
                      </button>
                      <button
                        className="btn btn-outline-danger naf-icon-btn mr-3"
                        type="button"
                        onClick={handleCancelUpdatePrj}
                      >
                        <span className="naf-icon-times" />
                      </button>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

UpdateExistingProjectForm.propTypes = {
  shouldHideSubmitCancelBtns: PropTypes.bool,
  index: PropTypes.number,
  handleSubmitUpdatePrj: PropTypes.func,
  handleCancelUpdatePrj: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateExistingProjectForm);
