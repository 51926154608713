import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset, submit } from 'redux-form/immutable';
import { DismissButton, SubmitButton } from '../Buttons';
import { setUIStateAction } from '../../../../core/actions/index';

const mapDispatchToProps = (dispatch, { meta: { form } }) => ({
  setViewMode: () => {
    dispatch(reset(form));
    dispatch(setUIStateAction([form, 'edit'], ''));
    dispatch(setUIStateAction([form, 'add'], ''));
  },
  submitForm: () => dispatch(submit(form))
});

const NafHoverableLiEdit = ({
  input, setViewMode, submitForm, meta, RenderFieldEdit
}) => (
  <li className="naf-hoverable">
    <div className={`input-group naf-input-group ${meta.error && 'is-invalid'}`}>
      <RenderFieldEdit input={input} meta={meta} />
      <div className="input-group-append">
        <SubmitButton callback={submitForm} />
        <DismissButton callback={setViewMode} />
      </div>
      <div className="invalid-feedback">{meta.error}</div>
    </div>
  </li>
);

NafHoverableLiEdit.propTypes = {
  setViewMode: PropTypes.func,
  submitForm: PropTypes.func,
  input: PropTypes.shape(),
  meta: PropTypes.shape(),
  RenderFieldEdit: PropTypes.node,
};

export default connect(null, mapDispatchToProps)(NafHoverableLiEdit);
