import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Field, reduxForm, getFormError } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { FORGOT_PASSWORD, REGISTER } from '../Layout/routePaths';
import { EMAIL_FIELD, LOGIN_FORM, PASSWORD_FIELD, REMEMBER_ME_FIELD } from './constants';
import { login } from './sagas';
import { LOGIN_GOOGLE_REDIRECT } from './constants/googleConstants';
import { LOGIN_FACEBOOK_REDIRECT } from './constants/facebookConstants';
import Social from './Social';

const mapStateToProps = state => ({
  error: getFormError(LOGIN_FORM)(state),
  intendedRoute: state.getIn(['navigation', 'intendedRoute'])
});

const LoginComponent = props => (
  <div id="naf-header-register" className="container-fluid naf-bg-header d-flex flex-column justify-content-center">
    <main role="main" className="container d-flex justify-content-center">
      <div className="d-flex flex-column naf-reg-box-wrap">
        <div className="naf-reg-box">
          <h2>LOG IN TO NEEDaFIXER COMMUNITY</h2>
          <form onSubmit={props.handleSubmit(login(props.intendedRoute))}>
            <div className="form-group">
              <label htmlFor="email" className="d-flex justify-content-between">
                <span>Email</span>
                <span className="req" />
              </label>
              <Field
                className="form-control"
                id={EMAIL_FIELD}
                name={EMAIL_FIELD}
                component="input"
                type="text"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="d-flex justify-content-between">
                <span>Password</span>
                <span className="req" />
              </label>
              <Field
                className="form-control"
                id={PASSWORD_FIELD}
                name={PASSWORD_FIELD}
                component="input"
                type="password"
              />
            </div>
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <Field
                  className="custom-control-input"
                  id={REMEMBER_ME_FIELD}
                  name={REMEMBER_ME_FIELD}
                  component="input"
                  type="checkbox"
                />
                <label className="custom-control-label" htmlFor={REMEMBER_ME_FIELD}>Remember me</label>
              </div>
            </div>
            <div className="form-group d-flex justify-content-center">
              <div className="form-group d-flex flex-column justify-content-center align-items-center">
                <button type="submit" className={`btn naf-btn-red naf-btn-wide mt-3 form-control ${props.error && 'is-invalid'}`}>LOG IN</button>
                <div className="invalid-feedback text-center">{props.error}</div>
              </div>
            </div>
          </form>
        </div>
        <div className="naf-reg-box mt-4">
          <ul className="list-unstyled naf-link-list">
            <li>
              <Link to={FORGOT_PASSWORD}>Forgot your pass?</Link>
            </li>
            <li>
              <Link to={REGISTER}>Don&apos;t have an account?</Link>
            </li>
          </ul>
        </div>
        <div className="naf-reg-box mt-4">
          <Social googleRedirect={LOGIN_GOOGLE_REDIRECT} facebookRedirect={LOGIN_FACEBOOK_REDIRECT} />
        </div>
      </div>
    </main>
  </div>
);

LoginComponent.propTypes = {
  intendedRoute: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

const Login = connect(mapStateToProps)(withRouter(LoginComponent));

export default reduxForm({
  form: LOGIN_FORM
})(Login);
