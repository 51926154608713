import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, change, formValueSelector, submit } from 'redux-form/immutable';
import { GENERAL_INFORMATION, GeneralInformationFields, VIEW_MODE } from '../constants';
import { setUIStateAction } from '../../core/actions';
import { MODAL, SUBMITTING_FIELD } from '../../core/constants';
import { sectionFieldSelector } from '../selectors';
import GeneralInfoCurrencyDropdown from './GeneralInfoCurrencyDropdown';

const mapStateToProps = (state, { input }) => ({
  fieldValue: formValueSelector(GENERAL_INFORMATION)(state, input.name),
  storedFieldValue: sectionFieldSelector(state, GENERAL_INFORMATION, input.name)
});

const mapDispatchToProps = (dispatch, { input }) => ({
  updateSection: (value) => {
    if (!value || value === '0') {
      dispatch(setUIStateAction([GENERAL_INFORMATION, MODAL], true));
    }
    dispatch(setUIStateAction(`${SUBMITTING_FIELD}-${GENERAL_INFORMATION}`, input.name));
    dispatch(submit(GENERAL_INFORMATION));
  },
  revertTo: (storedFieldValue) => {
    dispatch(change(GENERAL_INFORMATION, input.name, storedFieldValue));
    dispatch(setUIStateAction(`${GENERAL_INFORMATION}-${input.name}`, VIEW_MODE));
  }
});


const DailyRateInlineFields = (props) => {
  const {
    input,
    fieldValue,
    storedFieldValue,
    updateSection,
    revertTo,
    meta
  } = props;
  return (
    <div className="form-row">
      <div className="col-3">
        <Field
          name={`${input.name}.amount`}
          type="text"
          component="input"
          className={`form-control ${meta && meta.error ? 'is-invalid' : ''}`}
        />
        {meta && meta.touched && meta.error &&
        <div className="invalid-feedback">{meta && meta.error.get(GeneralInformationFields.AMOUNT)}</div>
        }
      </div>
      <div className="col-9">
        <div className="input-group naf-input-group">
          <Field
            component={GeneralInfoCurrencyDropdown}
            name={`${input.name}.currency`}
            id="currency"
            className="form-control mb-2 mr-lg-2"
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-success naf-icon-btn mr-lg-2"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                if ((fieldValue.get(GeneralInformationFields.AMOUNT) !== storedFieldValue.get(GeneralInformationFields.AMOUNT))
                  || (fieldValue.get(GeneralInformationFields.CURRENCY) !== storedFieldValue.get(GeneralInformationFields.CURRENCY))) {
                  updateSection(fieldValue.get(GeneralInformationFields.AMOUNT));
                } else {
                  revertTo(storedFieldValue);
                }
              }}
            >
              <span className="naf-icon-check" />
            </button>
            <button
              className="btn btn-outline-danger naf-icon-btn mr-lg-2"
              type="button"
              onClick={() => revertTo(storedFieldValue)}
            >
              <span className="naf-icon-times" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DailyRateInlineFields.propTypes = {
  input: PropTypes.shape(),
  meta: PropTypes.shape(),
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  storedFieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  revertTo: PropTypes.func,
  updateSection: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyRateInlineFields);
