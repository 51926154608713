import { takeEvery, takeLatest, call, all, put, select } from 'redux-saga/effects';
import { stopSubmit, getFormValues, clearSubmitErrors } from 'redux-form/immutable';
import { authorizedRequest, unauthorizedRequest } from '../../core/restClient';
import { CHECK_REGISTRATION_EMAIL_AVAILABILITY, REGISTRATION } from '../actions';
import { setUIStateAction } from '../../core/actions';
import {
  CONFIRM_ACCOUNT_EMAIL_UPDATE,
  CONFIRM_EMAIL_MODAL_STATE,
  REGISTRATION_BUTTON_DISABLED, REGISTRATION_RESULT, REGISTRATION_RESULT_MODAL_VISIBLE,
  REGISTRATION_STEP,
  REGISTRATION_STEP_ONE,
  REGISTRATION_STEP_TWO,
  RegistrationFormFields
} from '../constants';
import { ERROR, SUCCESS, WARNING } from '../../core/constants';
import { checkEmailAvailability } from '../../utils/sagas';
import { ACCOUNT_EMAIL_UPDATE_CONFIRMATION, EMAIL_CONFIRMATION } from '../../Login/actions';

function* checkRegistrationEmailAvailability(action) {
  const isRegistrationEmailAvailable = yield call(checkEmailAvailability, action.payload.email);
  switch (isRegistrationEmailAvailable) {
    case false:
      yield put(stopSubmit(REGISTRATION_STEP_ONE, { email: 'E-mail already registered' }));
      break;
    case true:
      yield put(clearSubmitErrors(REGISTRATION_STEP_ONE));
      yield put(setUIStateAction(REGISTRATION_STEP, 2));
      break;
    default:
      yield put(stopSubmit(REGISTRATION_STEP_ONE, { email: 'Could not check email availability. Try again.' }));
  }
}

function* performRegistration() {
  yield put(setUIStateAction(REGISTRATION_BUTTON_DISABLED, true));
  const firstStepData = yield select(getFormValues(REGISTRATION_STEP_ONE));
  const secondStepData = yield select(getFormValues(REGISTRATION_STEP_TWO));
  const requestBody = {
    email: firstStepData.get(RegistrationFormFields.firstStep.EMAIL),
    password: secondStepData.get(RegistrationFormFields.secondStep.PASSWORD),
    firstName: firstStepData.get(RegistrationFormFields.firstStep.FIRST_NAME),
    lastName: firstStepData.get(RegistrationFormFields.firstStep.LAST_NAME),
    phone: firstStepData.get(RegistrationFormFields.firstStep.PHONE),
    country: firstStepData.get(RegistrationFormFields.firstStep.COUNTRY),
    city: firstStepData.get(RegistrationFormFields.firstStep.CITY)
  };
  try {
    yield call(authorizedRequest, 'POST', '/register', requestBody);
    yield put(setUIStateAction(REGISTRATION_RESULT, SUCCESS));
    yield put(setUIStateAction(REGISTRATION_RESULT_MODAL_VISIBLE, true));
  } catch (err) {
    yield put(setUIStateAction(REGISTRATION_RESULT, ERROR));
    yield put(setUIStateAction(REGISTRATION_RESULT_MODAL_VISIBLE, true));
  }
}

function* handleConfirmRegistrationEmail(action) {
  try {
    yield put(setUIStateAction([CONFIRM_EMAIL_MODAL_STATE], WARNING));
    yield call(
      unauthorizedRequest,
      'get',
      `/register?hash=${action.payload.hash}`
    );
    yield put(setUIStateAction([CONFIRM_EMAIL_MODAL_STATE], SUCCESS));
  } catch (e) {
    yield put(setUIStateAction([CONFIRM_EMAIL_MODAL_STATE], ERROR));
  }
}

function* handleConfirmAccountEmailChange(action) {
  const { hash } = action.payload;
  try {
    yield put(setUIStateAction([CONFIRM_ACCOUNT_EMAIL_UPDATE], WARNING));
    yield call(
      unauthorizedRequest,
      'get',
      `/accounts/email/confirm?hash=${hash}`
    );
    yield put(setUIStateAction([CONFIRM_ACCOUNT_EMAIL_UPDATE], SUCCESS));
  } catch (e) {
    yield put(setUIStateAction([CONFIRM_ACCOUNT_EMAIL_UPDATE], ERROR));
  }
}

export default function* watchRegistrationRequests() {
  yield all([
    takeEvery(CHECK_REGISTRATION_EMAIL_AVAILABILITY, checkRegistrationEmailAvailability),
    takeLatest(REGISTRATION, performRegistration),
    takeLatest(EMAIL_CONFIRMATION, handleConfirmRegistrationEmail),
    takeLatest(ACCOUNT_EMAIL_UPDATE_CONFIRMATION, handleConfirmAccountEmailChange)
  ]);
}
