import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutAction } from '../../Login/actions/index';
import { LOGIN } from '../routePaths';

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutAction())
});

const HeaderComponent = props => (
  <React.Fragment>
    <li className="nav-item">
      <Link to={LOGIN} className="nav-link" onClick={props.logout} >
        LOG OUT
      </Link>
    </li>
  </React.Fragment>
);

HeaderComponent.propTypes = {
  logout: PropTypes.func
};

export default connect(null, mapDispatchToProps)((HeaderComponent));
