import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, formValueSelector, reduxForm, destroy } from 'redux-form/immutable';
import { withRouter } from 'react-router-dom';
import renderField from '../../core/renderers/renderTextFormField';
import secondStepSyncValidator from '../validators/secondStepSyncValidator';
import { clearUIStateAction, setUIStateAction } from '../../core/actions';
import renderRecaptcha from '../renderers/renderRecaptcha';
import renderTermsCheckbox from '../renderers/renderTermsCheckbox';
import { registrationAction } from '../actions';
import NafModal from '../../widgets/NafModal';
import {
  isRegistrationBtnDisabledSelector,
  registrationResultSelector,
  showRegistrationResultModalSelector
} from '../selectors';
import {
  modalMessageRenderer,
  renderModalTitle,
  REGISTRATION_STEP,
  REGISTRATION_STEP_ONE,
  REGISTRATION_STEP_TWO,
  RegistrationFormFields,
} from '../constants';
import { PASSWORD_INPUT_TYPE } from '../../core/constants';
import { MAIN } from '../../Layout/routePaths';

const mapDispatchToProps = (dispatch, { history }) => ({
  submitRegistration: () => dispatch(registrationAction()),
  backToStepOne: () => dispatch(setUIStateAction(REGISTRATION_STEP, 1)),
  modalBtnCLickHandler: () => {
    dispatch(clearUIStateAction());
    history.push(MAIN);
    dispatch(destroy(REGISTRATION_STEP_ONE, REGISTRATION_STEP_TWO));
  }
});

const mapStateToProps = state => ({
  email: formValueSelector(REGISTRATION_STEP_ONE)(state, RegistrationFormFields.firstStep.EMAIL),
  registrationResult: registrationResultSelector(state),
  shouldShowResultModal: showRegistrationResultModalSelector(state),
  isRegistrationBtnDisabled: isRegistrationBtnDisabledSelector(state),
});

const SecondStepTextFields = {
  passwordField: renderField(RegistrationFormFields.secondStep.PASSWORD, PASSWORD_INPUT_TYPE),
  passwordConfirmField: renderField(RegistrationFormFields.secondStep.PASSWORD_CONFIRM, PASSWORD_INPUT_TYPE)
};

const RegWizardFormSecondPage = (props) => {
  const {
    handleSubmit,
    submitRegistration,
    backToStepOne,
    registrationResult,
    shouldShowResultModal,
    modalBtnCLickHandler,
    email,
    isRegistrationBtnDisabled
  } = props;
  return (
    <form onSubmit={handleSubmit(submitRegistration)}>
      <Field
        name={RegistrationFormFields.secondStep.PASSWORD}
        component={SecondStepTextFields.passwordField}
      />
      <Field
        name={RegistrationFormFields.secondStep.PASSWORD_CONFIRM}
        component={SecondStepTextFields.passwordConfirmField}
      />
      <Field
        name={RegistrationFormFields.secondStep.RECAPTCHA}
        component={renderRecaptcha}
      />
      <Field
        name={RegistrationFormFields.secondStep.TERMS}
        component={renderTermsCheckbox}
      />
      <div className="form-group d-flex flex-column flex-sm-row justify-content-between">
        <button
          type="button"
          className="btn naf-btn-red naf-btn-wide mt-3"
          onClick={backToStepOne}
        >
          BACK
        </button>
        <button
          type="submit"
          className="btn naf-btn-red naf-btn-wide mt-3"
          disabled={isRegistrationBtnDisabled}
        >
          REGISTER
        </button>
      </div>
      { shouldShowResultModal &&
      <NafModal
        title={renderModalTitle(registrationResult)}
        message={modalMessageRenderer(registrationResult, email)}
        leftBtnClickHandler={modalBtnCLickHandler}
        leftBtnLabel="OK"
        type={registrationResult}
      /> }
    </form>
  );
};

RegWizardFormSecondPage.propTypes = {
  handleSubmit: PropTypes.func,
  backToStepOne: PropTypes.func,
  submitRegistration: PropTypes.func,
  modalBtnCLickHandler: PropTypes.func,
  shouldShowResultModal: PropTypes.bool,
  isRegistrationBtnDisabled: PropTypes.bool,
  registrationResult: PropTypes.string,
  email: PropTypes.string
};

const RegWizardFormSecondPageContainer = connect(mapStateToProps, mapDispatchToProps)(RegWizardFormSecondPage);

export default reduxForm({
  form: REGISTRATION_STEP_TWO,
  validate: secondStepSyncValidator
})(withRouter(RegWizardFormSecondPageContainer));
