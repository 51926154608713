import React, { Fragment } from 'react';
import { fieldHasError } from './utils';

const renderDescription = field => (
  <Fragment>
    <label htmlFor="edit-exp-descr">Description</label>
    <textarea
      value={field.input.value}
      onChange={field.input.onChange}
      rows="4"
      className={`form-control ${fieldHasError(field) ? 'is-invalid' : ''} naf-v-resize`}
      id="edit-exp-descr"
    />
    {
      fieldHasError(field) && <div className="invalid-feedback">{ field.meta.error }</div>
    }
  </Fragment>
);

export default renderDescription;
