export const LOGOUT = 'logout';
export const EMAIL_CONFIRMATION = 'EMAIL_CONFIRMATION';
export const ACCOUNT_EMAIL_UPDATE_CONFIRMATION = 'ACCOUNT_EMAIL_UPDATE_CONFIRMATION';
export const SET_USER_ROLE = 'SET_USER_ROLE';

export const logoutAction = () => (
  {
    type: LOGOUT
  }
);

export const confirmPasswordAction = data => ({
  type: EMAIL_CONFIRMATION,
  payload: {
    email: data.email,
    hash: data.hash
  }
});

export const confirmUpdateAccountEmailAction = data => ({
  type: ACCOUNT_EMAIL_UPDATE_CONFIRMATION,
  payload: {
    hash: data.hash
  }
});

export const setUserRoleAction = role => ({
  type: SET_USER_ROLE,
  payload: { role }
});
