import React from 'react';
import { reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { notesUiStateSelector } from '../selectors';
import { NOTES, EDIT_MODE } from '../constants';
import NotesEdit from './NotesEdit';
import NotesView from './NotesView';
import { submitNotesFormAction } from '../actions';

const submitNotesForm = (values, dispatch) => {
  dispatch(submitNotesFormAction(values.toJS()));
};

const mapStateToProps = state => ({
  mode: notesUiStateSelector(state),
});

const Notes = ({
  mode
}) => (
  mode !== EDIT_MODE ? <NotesView /> : <NotesEdit />
);

Notes.propTypes = {
  mode: PropTypes.string
};

export default reduxForm({
  form: NOTES,
  onSubmit: submitNotesForm,
  enableReinitialize: true
})(connect(mapStateToProps)(Notes));
