import React from 'react';
import { FieldArray, reduxForm } from 'redux-form/immutable';
import renderProjects from './formFields/renderProjects';

const ProjectFormComponent = () => (
  <div className="card naf-experience-item mb-4">
    <div className="card-body">
      <form>
        <FieldArray
          name="projects"
          component={renderProjects}
        />
      </form>
    </div>
  </div>
);

const ProjectForm = reduxForm({})(ProjectFormComponent);

export default ProjectForm;
