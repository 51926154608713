import { ProfileStatus } from '../constants';

const isValidMonetaryAmount = val => /^\d+\.?\d{0,2}$/.test(val);

/**
 * Finds the first category with one or more results.
 * If there are no results at all the Full category is returned.
 *
 * @param summary
 * @returns {*|string}
 */
export const findInitialCategory = (summary) => {
  let initialProfileStatusCategory = null;
  Object.keys(summary).forEach((category) => {
    if (!initialProfileStatusCategory && summary[category].length > 0) {
      initialProfileStatusCategory = category;
    }
  });
  return initialProfileStatusCategory || ProfileStatus.FULL;
};

export const computeSortingQueryString = (sortingObj) => {
  if (Object.keys(sortingObj).length === 0) {
    return '';
  }
  const key = Object.keys(sortingObj)[0];
  const val = sortingObj[key];
  return `&sort=${key}&${key}.dir=${val}`;
};

export const initSummary = {
  [ProfileStatus.FULL]: 0,
  [ProfileStatus.UNDER_REVIEW]: 0,
  [ProfileStatus.MISSING_INFORMATION]: 0,
  [ProfileStatus.MIGRATED]: 0,
  [ProfileStatus.IGNORE]: 0,
};

export const processResultsSummaryResponse = (summaryResponse) => {
  const fullInitSummary = {
    [ProfileStatus.FULL]: 0,
    [ProfileStatus.UNDER_REVIEW]: 0,
    [ProfileStatus.MISSING_INFORMATION]: 0,
    [ProfileStatus.MIGRATED]: 0,
    [ProfileStatus.MIGRATED_NO_EMAIL]: 0,
    [ProfileStatus.IGNORE]: 0,
  };
  // eslint-disable-next-line no-return-assign
  summaryResponse.summary.forEach(summ => fullInitSummary[summ.status] = summ.count);
  return {
    [ProfileStatus.FULL]: fullInitSummary[ProfileStatus.FULL],
    [ProfileStatus.UNDER_REVIEW]: fullInitSummary[ProfileStatus.UNDER_REVIEW],
    [ProfileStatus.MISSING_INFORMATION]: fullInitSummary[ProfileStatus.MISSING_INFORMATION],
    [ProfileStatus.MIGRATED]: fullInitSummary[ProfileStatus.MIGRATED] + fullInitSummary[ProfileStatus.MIGRATED_NO_EMAIL],
    [ProfileStatus.IGNORE]: fullInitSummary[ProfileStatus.IGNORE]
  };
};

export const monetaryAmountNormalizer = (value, previousValue) => {
  if (isValidMonetaryAmount(value) || !value) {
    return value;
  }
  return previousValue;
};

export const appendDotsAfterChars = (inputText, limit) => {
  if (!inputText) {
    return '';
  }
  return inputText.length > limit ? `${inputText.slice(0, limit)}...` : inputText;
};
