import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fieldInputPropTypes, fieldMetaPropTypes, submit } from 'redux-form/immutable';
import { UPDATE_ACCOUNT_EMAIL_FORM, VIEW_MODE } from '../../constants';
import { ACCOUNT, EMAIL } from '../../../core/constants';
import { setUIStateAction } from '../../../core/actions';

const mapDispatchToProps = dispatch => ({
  handleCancel: () => dispatch(setUIStateAction([ACCOUNT, EMAIL], VIEW_MODE)),
  submitForm: () => dispatch(submit(UPDATE_ACCOUNT_EMAIL_FORM))
});

const UpdateEmailField = (props) => {
  const {
    input,
    meta,
    handleCancel,
    submitForm
  } = props;
  return (
    <Fragment>
      <input
        {...input}
        type="email"
        className={`form-control ${meta && meta.error ? 'is-invalid' : 'naf-short-ctrl'} `}
      />
      <div className="input-group-append">
        <button
          className="btn btn-outline-success naf-icon-btn naf-short-ctrl"
          type="button"
          onClick={submitForm}
        >
          <span className="naf-icon-check" />
        </button>
        <button
          className="btn btn-outline-danger naf-icon-btn naf-short-ctrl"
          type="button"
          onClick={handleCancel}
        >
          <span className="naf-icon-times" />
        </button>
      </div>
      {
        meta.error && <div className="invalid-feedback">{ meta.error }</div>
      }
    </Fragment>
  );
};

UpdateEmailField.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes),
  meta: PropTypes.shape(fieldMetaPropTypes),
  handleCancel: PropTypes.func,
  submitForm: PropTypes.func
};

export default connect(null, mapDispatchToProps)(UpdateEmailField);
