export const SocialMediaAppConfig = {
  FACEBOOK: {
    icon: 'facebook',
    abbreviation: 'fb'
  },
  TWITTER: {
    icon: 'twitter',
    abbreviation: 'tw'
  },
  YOUTUBE: {
    icon: 'youtube-play',
    abbreviation: 'yt'
  },
  VIMEO: {
    icon: 'vimeo',
    abbreviation: 'vm'
  },
  LINKEDIN: {
    icon: 'linkedin',
    abbreviation: 'li'
  },
  INSTAGRAM: {
    icon: 'instagram',
    abbreviation: 'ig'
  },
  WEBSITE: {
    icon: 'link',
    abbreviation: 'link'
  },
};

export const SocialMediaFieldName = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
  VIMEO: 'vimeo',
  LINKEDIN: 'linkedIn',
  INSTAGRAM: 'instagram',
  WEBSITE: 'website'
};

export const availableSocialMedia = [
  {
    name: SocialMediaFieldName.FACEBOOK,
    aClassName: SocialMediaAppConfig.FACEBOOK.abbreviation,
    spanClassName: SocialMediaAppConfig.FACEBOOK.icon
  },
  {
    name: SocialMediaFieldName.LINKEDIN,
    aClassName: SocialMediaAppConfig.LINKEDIN.abbreviation,
    spanClassName: SocialMediaAppConfig.LINKEDIN.icon
  },
  {
    name: SocialMediaFieldName.TWITTER,
    aClassName: SocialMediaAppConfig.TWITTER.abbreviation,
    spanClassName: SocialMediaAppConfig.TWITTER.icon
  },
  {
    name: SocialMediaFieldName.VIMEO,
    aClassName: SocialMediaAppConfig.VIMEO.abbreviation,
    spanClassName: SocialMediaAppConfig.VIMEO.icon
  },
  {
    name: SocialMediaFieldName.YOUTUBE,
    aClassName: SocialMediaAppConfig.YOUTUBE.abbreviation,
    spanClassName: SocialMediaAppConfig.YOUTUBE.icon
  },
  {
    name: SocialMediaFieldName.INSTAGRAM,
    aClassName: SocialMediaAppConfig.INSTAGRAM.abbreviation,
    spanClassName: SocialMediaAppConfig.INSTAGRAM.icon
  },
  {
    name: SocialMediaFieldName.WEBSITE,
    aClassName: SocialMediaAppConfig.WEBSITE.abbreviation,
    spanClassName: SocialMediaAppConfig.WEBSITE.icon
  },
];

export const enrichLinksWithHttpPrefix = (socialMedia) => {
  const enriched = {};
  Object.keys(socialMedia).forEach((k) => {
    if (socialMedia[k] && !(socialMedia[k]).startsWith('http')) {
      enriched[k] = `http://${socialMedia[k]}`;
    } else {
      enriched[k] = socialMedia[k];
    }
  });
  return enriched;
};

export const renderSocialMessage = (socialMediaObj) => {
  if (!socialMediaObj) {
    return 'ADD SOCIAL';
  }
  return `ADD ${
    Object.keys(socialMediaObj)
      .map(soc => ({ [soc]: socialMediaObj[soc] }))
      .filter(socObj => Object.values(socObj)[0])
      .length > 0 ? 'MORE ' : ''}SOCIAL`;
};
