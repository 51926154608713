import React from 'react';
import PropTypes from 'prop-types';

export const SubmitButton = ({ callback }) => (
  <button className="btn btn-outline-success naf-icon-btn" type="button" onClick={() => callback()}>
    <span className="naf-icon-check" />
  </button>
);

SubmitButton.propTypes = {
  callback: PropTypes.func
};

export const DismissButton = ({ callback }) => (
  <button className="btn btn-outline-danger naf-icon-btn" type="button" onClick={() => callback()}>
    <span className="naf-icon-times" />
  </button>
);

DismissButton.propTypes = {
  callback: PropTypes.func
};


export const PencilButton = ({ callback }) => (
  <button type="button" className="btn btn-link naf-edit-btn btn-sm" onClick={() => callback()}><span
    className="naf-icon-pencil"
  />
  </button>
);

PencilButton.propTypes = {
  callback: PropTypes.func
};

export const XButton = ({ callback }) => (
  <button onClick={() => callback()} type="button" className="btn btn-link naf-del-btn btn-sm"><span
    className="naf-icon-times"
  />
  </button>
);

XButton.propTypes = {
  callback: PropTypes.func
};
