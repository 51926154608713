import React, { Fragment } from 'react';
import { fieldHasError } from './utils';

const renderTextField = label => field => (
  <Fragment>
    <label htmlFor={`edit-exp-${field.input.name}`}>{ label }</label>
    <input
      onChange={field.input.onChange}
      value={field.input.value}
      type="text"
      placeholder={field.placeholder}
      className={`form-control ${fieldHasError(field) && 'is-invalid'}`}
      id={`edit-exp-${field.input.name}`}
    />
    {
      fieldHasError(field) && <div className="invalid-feedback">{ field.meta.error }</div>
    }
  </Fragment>
);

export default renderTextField;
