import React from 'react';
import MonetaryAmount from '../MoreInformationFields/MonetaryAmount';

const AccommodationCost = () => (
  <MonetaryAmount
    label="Average 2-Bedroom cost per night (3-4* Hotel)"
    name="accommodationCost"
    fieldConfig={[{
      label: 'Per Night',
      id: 'accomodation-costs',
      name: 'amount'
    }]}
  />
);

export default AccommodationCost;
