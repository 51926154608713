export default [
  '1st AC',
  '1st AD',
  '2D animator',
  '2nd AC',
  '2nd AD',
  '360 cinematographer',
  '360 director',
  '360 filmmaker',
  '3D animator',
  '3D environments lead',
  '3D scanning',
  '3rd AD',
  '4K editor',
  'ADR editor',
  'ADR mixer',
  'ADR recordist',
  'AV technician',
  'Account manager',
  'Accountant',
  'Accounting clerk',
  'Accounting technician',
  'Actor',
  'Actress',
  'Administrator',
  'Advertising executive',
  'Aerial DOP',
  'Aerial DP',
  'Aerial cinematographer',
  'Agency producer',
  'Agent',
  'Animal trainer',
  'Animal wrangler',
  'Animatic artist',
  'Animation Director',
  'Animation Supervisor',
  'Animator',
  'Applications developer',
  'Archivist',
  'Art department coordinator',
  'Art director',
  'Assistant art director',
  'Assistant editor',
  'Assistant graphic designer',
  'Assistant location manager',
  'Assistant producer',
  'Assistant production manager',
  'Assistant props master',
  'Associate director',
  'Associate producer',
  'Background costumer',
  'Audio technician',
  'Banker',
  'Best boy',
  'Blogger',
  'Bluescreen director',
  'Body double',
  'Boom operator',
  'Brand manager',
  'Broadcast engineer',
  'Buyer',
  'CEO',
  'CG Artist',
  'CIO',
  'Cable cam operator',
  'Camera lead',
  'Camera operator',
  'Camera scenic artist',
  'Camera technical director',
  'Career adviser',
  'Carpenter',
  'Casting assistant',
  'Casting director',
  'Caterer',
  'Character Artist',
  'Character animator',
  'Charity fundraiser',
  'Chief technology officer',
  'Choreographer',
  'Cinemagraph artist',
  'Cinematographer',
  'Clapper loader',
  'Color corrector',
  'Color grader',
  'Colorist',
  'Commercial director',
  'Commissioner',
  'Commissioning editor',
  'Communications engineer',
  'Composites photographer',
  'Compositing sequence supervisor',
  'Computer games developer',
  'Computer graphics designer',
  'Concept artist',
  'Construction coordinator',
  'Content strategist',
  'Copywriter',
  'Corporate investment banker',
  'Corporate treasurer',
  'Costume designer',
  'Costume graphic designer',
  'Costume maker',
  'Costume superior',
  'Courier',
  'Craft service',
  'Crane grip',
  'Crane operator',
  'Crane technician',
  'Creative director',
  'Creative manager',
  'Creature supervisor',
  'Curator',
  'Customer service manager',
  'DIT',
  'DOP',
  'DP',
  'Department manager',
  'Depth artist',
  'Designer',
  'Developer',
  'Dialect coach',
  'Dialog coach',
  'Dialogue editor',
  'Digital &amp; visual effects artist',
  'Digital artist',
  'Digital compositor',
  'Digital director',
  'Digital imaging technician',
  'Digital marketer',
  'Digital matte painter',
  'Digital opticals',
  'Digital prototyping',
  'Digital restoration',
  'Digital tech assistant',
  'Digital tech supervisor',
  'Digital utility',
  'Director',
  'Director of photography',
  'Dolly Grip',
  'Drafter',
  'Draftperson',
  'Dramatic instructor',
  'Draper',
  'Driver',
  'Drone DOP',
  'Drone cinematographer',
  'Drone pilot',
  'EFP',
  'ENG',
  'Editor',
  'Editor assistant',
  'Editorial assistant',
  'Editorial team',
  'Effects technical director',
  'Electronics engineer',
  'Emcee',
  'Engineer',
  'Environment supervisor',
  'Estate agent',
  'Event organiser',
  'Events videographer',
  'Executive assistant',
  'Executive producer',
  'Executive vice president',
  'Explosives engineer',
  'Facilities manager',
  'Film director',
  'Film editor',
  'Film producer',
  'Filmmaker',
  'Final layout artist',
  'Financial adviser',
  'Fixer',
  'Fixtures LED tech',
  'Flame artist',
  'Flying camera programmer',
  'Focus puller',
  'Foley artist',
  'Foley editor',
  'Foley mixer',
  'Food stylist',
  'Gaffer',
  'Generator operator',
  'Graphic artist',
  'Graphic designer',
  'Graphics coordinator',
  'Greenscreen director',
  'Grip',
  'Grip supervisor',
  'HD editor',
  'Hair &amp; make-up artist',
  'Head accountant',
  'Head of stereography',
  'Head writer',
  'Headshots photographer',
  'Human resources management advisor',
  'Human resources management consultant',
  'Hyperlapse',
  'IT consultant',
  'IT coordinator',
  'IT sales professional',
  'IT technical support officer',
  'IT trainer',
  'Illustrator',
  'Impersonator',
  'In-house compositor',
  'Indexer',
  'Integration artist',
  'Jib operator',
  'Journalist',
  'Junior compositor',
  'Junior designer',
  'Junior marketing analyst',
  'Key costumer',
  'Key grip',
  'Key make-up',
  'Key scenic',
  'Key set costumer',
  'Key video engineer',
  'Lamp operator',
  'Layout artist',
  'Lead animator',
  'Lead modeler',
  'Lead motion tracking',
  'Lead person',
  'Lead shader writer',
  'Lead stereoscopic compositor',
  'Lecturer',
  'Lidar scanning',
  'Lidar supervisor',
  'Lighting DOP',
  'Lighting DP',
  'Lighting artist',
  'Lighting camera operator',
  'Lighting crew member',
  'Lighting desk operator',
  'Lighting director',
  'Lighting lead',
  'Lighting technical director',
  'Lighting technician',
  'Line producer',
  'Live host',
  'Live hostess',
  'Location assistant',
  'Location manager',
  'Location scout',
  'Logistics and distribution manager',
  'Management consultant',
  'Manager',
  'Manicurist',
  'Marine coordinator',
  'Marketing account director',
  'Marketing account manager',
  'Marketing assistant',
  'Marketing coordinator',
  'Marketing director',
  'Martial arts fighter',
  'Martial arts stunt',
  'Matchmove artist',
  'Matchmove lead',
  'Matte artist',
  'Media consultant',
  'Medic',
  'Miniature effects',
  'Miniature unit',
  'MoVI operator',
  'Model',
  'Model maker',
  'Modeling supervisor',
  'Mold maker',
  'Motion capture technician',
  'Motion editor',
  'Motion graphics artist',
  'Music composer',
  'Music producer',
  'Music scoring mixer',
  'Music supervisor',
  'Musician',
  'Newscaster',
  'Nurse',
  'OB operator',
  'Office manager',
  'Office production assistant',
  'On camera host',
  'Online marketing assistant',
  'Online marketing manager',
  'PR account director',
  'PR account executive',
  'PR account manager',
  'PR director',
  'Paint supervisor',
  'Payroll accountant',
  'Personal assistant',
  'Personal trainer',
  'Personnel officer',
  'Photographer',
  'Plasterer',
  'Plate producer',
  'Post production supervisor',
  'Post-production coordinator',
  'President',
  'Previsualization producer',
  'Producer',
  'Production accountant',
  'Production assistant',
  'Production coordinator',
  'Production designer',
  'Production director',
  'Production engineer',
  'Production manager',
  'Production secretary',
  'Production sound mixer',
  'Production supervisor',
  'Programmer',
  'Project director',
  'Project manager',
  'Proofreader',
  'Propmaker',
  'Props master',
  'Props person',
  'Public Relations officer',
  'Publisher',
  'Publishing rights manager',
  'Quality manager',
  'Radio broadcast assistant',
  'Radio broadcaster',
  'Radio producer',
  'Records manager',
  'Recruitment consultant',
  'Remote head technician',
  'Researcher',
  'Retoucher',
  'Rigging electrician',
  'Rigging gaffer',
  'Rigging grip',
  'Ronin operator',
  'Rotoscope artist',
  'Runner',
  'SAT operator',
  'SFX assistant',
  'SFX coordinator',
  'SFX make-up artist',
  'SFX pyrotechnic',
  'SNG operator',
  'Sales assistant',
  'Sales coordinator',
  'Sales executive',
  'Satellite truck operator',
  'Screenwriter',
  'Script supervisor',
  'Scriptwriter',
  'Secretary',
  'Self shooting PD',
  'Self shooting director',
  'Senior advertising executive',
  'Senior data wrangler',
  'Senior designer',
  'Senior digital artist',
  'Senior layout artist',
  'Senior marketing analyst',
  'Senior paint artist',
  'Senior systems administrator',
  'Sequence supervisor',
  'Set decorator',
  'Set designer',
  'Set dresser',
  'Set production assistant',
  'Shaders coordinator',
  'Shopper',
  'Singer',
  'Slider technician',
  'Social media manager',
  'Software developer',
  'Software engineer',
  'Software tester',
  'Sound assistant',
  'Sound composer',
  'Sound designer',
  'Sound mixer',
  'Sound re-recording mixer',
  'Sound recordist',
  'Sound utility',
  'Spark',
  'Special effects assistant',
  'Special effects coordinator',
  'Special effects make-up artist',
  'Special effects pyrotechnic',
  'Special effects senior technician',
  'Staff assistant',
  'Stage manager',
  'Stand in',
  'Steadicam operator',
  'Stereo depth artist',
  'Stereo production coordinator',
  'Stereo roto',
  'Stereoscopic Compositor',
  'Stereoscopic depth artist',
  'Still photographer',
  'Storyboard artist',
  'Storyboarder',
  'Stunt',
  'Stunt coordinator',
  'Stunt driver',
  'Stunt pilot',
  'Stunt supervisor',
  'Stuntman',
  'Stuntwoman',
  'Stylist',
  'TV director',
  'TV producer',
  'Tax adviser',
  'Technical advisor',
  'Technical director',
  'Technical support',
  'Teleprompter operator',
  'Texture artist',
  'Textures coordinator',
  'Theatre director',
  'Theatre manager',
  'Theatre stage manager',
  'Timelapse Photographer',
  'Translator',
  'Transport captain',
  'Tutor',
  'Underwater camera operator',
  'Underwater lighting technician',
  'Unit production manager',
  'Unit publicist',
  'Utility person',
  'VFX assistant',
  'VFX assistant editor',
  'VFX coordinator',
  'VFX director',
  'VFX director of photography',
  'VFX editor',
  'VFX executive producer',
  'VFX photographer',
  'VFX producer',
  'VFX production assistant',
  'VFX production manager',
  'VFX supervisor',
  'VR director',
  'VR filmmaker',
  'VR producer',
  'Vehicle coordinator',
  'Vice president',
  'Video assistant',
  'Video control operator',
  'Video editor',
  'Video engineers',
  'Video producer',
  'Videographer',
  'Visual merchandiser'
];
