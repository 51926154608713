import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import socialMediaTextField from './socialMediaTextField';
import { SocialMediaFieldName, SocialMediaAppConfig } from './utils';
import { SHOW_SOCIAL_MEDIA_POPUP, SOCIAL_MEDIA } from '../constants';
import { setUIStateAction } from '../../core/actions';

const facebookFieldComponent = socialMediaTextField(SocialMediaAppConfig.FACEBOOK);
const twitterFieldComponent = socialMediaTextField(SocialMediaAppConfig.TWITTER);
const youtubeFieldComponent = socialMediaTextField(SocialMediaAppConfig.YOUTUBE);
const vimeoFieldComponent = socialMediaTextField(SocialMediaAppConfig.VIMEO);
const linkedInFieldComponent = socialMediaTextField(SocialMediaAppConfig.LINKEDIN);
const instagramFieldComponent = socialMediaTextField(SocialMediaAppConfig.INSTAGRAM);
const websiteFieldComponent = socialMediaTextField(SocialMediaAppConfig.WEBSITE);


const mapDispatchToProps = dispatch => ({
  handleCancel: () => dispatch(setUIStateAction(SHOW_SOCIAL_MEDIA_POPUP, false))
});

const SocialMediaFormComponent = ({ handleCancel, handleSubmit }) => (
  <form
    onSubmit={handleSubmit}
    className="px-3 py-1"
  >
    <Field
      id={SocialMediaFieldName.FACEBOOK}
      name={SocialMediaFieldName.FACEBOOK}
      component={facebookFieldComponent}
    />
    <Field
      id={SocialMediaFieldName.TWITTER}
      name={SocialMediaFieldName.TWITTER}
      component={twitterFieldComponent}
    />
    <Field
      id={SocialMediaFieldName.YOUTUBE}
      name={SocialMediaFieldName.YOUTUBE}
      component={youtubeFieldComponent}
    />
    <Field
      id={SocialMediaFieldName.VIMEO}
      name={SocialMediaFieldName.VIMEO}
      component={vimeoFieldComponent}
    />
    <Field
      id={SocialMediaFieldName.LINKEDIN}
      name={SocialMediaFieldName.LINKEDIN}
      component={linkedInFieldComponent}
    />
    <Field
      id={SocialMediaFieldName.INSTAGRAM}
      name={SocialMediaFieldName.INSTAGRAM}
      component={instagramFieldComponent}
    />
    <Field
      id={SocialMediaFieldName.WEBSITE}
      name={SocialMediaFieldName.WEBSITE}
      component={websiteFieldComponent}
    />
    <div className="form-group d-flex flex-column flex-sm-row justify-content-between">
      <button
        type="submit"
        className="btn naf-btn-red naf-btn-wide mt-3"
      >
        SAVE
      </button>
      <button
        type="button"
        className="btn naf-btn-red naf-btn-wide mt-3"
        onClick={handleCancel}
      >
        CANCEL
      </button>
    </div>
  </form>
);

SocialMediaFormComponent.propTypes = {
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func
};

const SocialMediaFormContainer = connect(null, mapDispatchToProps)(SocialMediaFormComponent);

export default reduxForm({
  form: SOCIAL_MEDIA
})(SocialMediaFormContainer);
