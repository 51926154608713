import React from 'react';
import { referencePropTypes } from '../constants/propTypes';

const Reference = ({ reference }) => {
  const {
    name,
    title,
    email,
    contactNumber
  } = reference;
  return (
    <ul className="list-inline">
      <li>
        <ul className="list-inline naf-list-bullets">
          <li className="list-inline-item"><strong>NAME: </strong>{ name }</li>
          <li className="list-inline-item"><strong>TITLE: </strong>{ title }</li>
          <li className="list-inline-item"><strong>EMAIL: </strong> { email }</li>
          <li className="list-inline-item"><strong>CONTACT NUMBER: </strong> ​{ contactNumber }</li>
        </ul>
        <div className="d-flex align-items-center" />
      </li>
    </ul>
  );
};

Reference.propTypes = {
  reference: referencePropTypes
};

export default Reference;
