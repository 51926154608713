import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setUIStateAction } from '../../core/actions';
import { ACCOUNT, PASSWORD } from '../../core/constants';
import { EDIT_MODE } from '../constants';

const mapDispatchToProps = dispatch => ({
  toggleEditMode: () => dispatch(setUIStateAction([ACCOUNT, PASSWORD], EDIT_MODE))
});

const AccountUpdatePasswordView = ({ toggleEditMode }) => (
  <div className="naf-my-account-value">
    <small>&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</small>
    <button
      type="button"
      className="btn btn-link naf-edit-btn btn-sm naf-hoverable-show"
      onClick={toggleEditMode}
    >
      <span className="naf-icon-pencil" />
    </button>
  </div>
);

AccountUpdatePasswordView.propTypes = {
  toggleEditMode: PropTypes
};

export default connect(null, mapDispatchToProps)(AccountUpdatePasswordView);
