import { MIN_PASSWORD_LENGTH, RegistrationFormFields } from '../constants';

const validator = (values) => {
  const errors = {};
  Object.values(RegistrationFormFields.secondStep).forEach((fieldName) => {
    if (!values.get(fieldName)) {
      errors[fieldName] = 'Required';
    }
  });
  const password = values.get(RegistrationFormFields.secondStep.PASSWORD);
  const passwordConfirm = values.get(RegistrationFormFields.secondStep.PASSWORD_CONFIRM);
  if (password && passwordConfirm && (password !== passwordConfirm)) {
    errors[RegistrationFormFields.secondStep.PASSWORD_CONFIRM] = 'Passwords must match';
  }
  if (password && password.length < MIN_PASSWORD_LENGTH) {
    errors[RegistrationFormFields.secondStep.PASSWORD] =
      `Password must be at least ${MIN_PASSWORD_LENGTH} characters long`;
  }
  return errors;
};

export default validator;
