import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EDIT_MODE, VIEW_MODE } from '../constants';
import { ACCOUNT, EMAIL } from '../../core/constants';
import { setUIStateAction } from '../../core/actions';

const mapDispatchToProps = dispatch => ({
  handleCancel: () => dispatch(setUIStateAction([ACCOUNT, EMAIL], VIEW_MODE)),
  toggleEditMode: () => dispatch(setUIStateAction([ACCOUNT, EMAIL], EDIT_MODE)),
});

const AccountUpdateEmailView = ({ toggleEditMode, existingAccountEmail }) => (
  <div className="naf-my-account-value">
    { existingAccountEmail }
    <button
      type="button"
      className="btn btn-link naf-edit-btn btn-sm naf-hoverable-show"
      onClick={toggleEditMode}
    >
      <span className="naf-icon-pencil" />
    </button>
  </div>
);

AccountUpdateEmailView.propTypes = {
  toggleEditMode: PropTypes.func,
  existingAccountEmail: PropTypes.string
};

export default connect(null, mapDispatchToProps)(AccountUpdateEmailView);
