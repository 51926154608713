import React from 'react';
import MonetaryAmount from './MoreInformationFields/MonetaryAmount';

const fieldConfig = [
  {
    name: 'amount',
    label: 'Per Day'
  }
];

const AccommodationCost = () => (
  <MonetaryAmount
    label="Average car rental const of car/mini-van"
    name="carRentalCost"
    fieldConfig={fieldConfig}
  />
);

export default AccommodationCost;
