import React from 'react';
import { DropdownList } from 'react-widgets';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import DefaultAvatar from '../../resources/media/avatar/default-avatar.png';
import { dailyRateFormatter, rolesFormatter } from './formatters';
import { MaxViewFieldLength, PROFILE_STATUS_CHANGE_MODAL, ViewProfileStatusArray } from './constants';
import { handleSelectProfileAction, changeProfileStatusAction } from './actions';
import { setUIStateAction } from '../core/actions';
import NafModal from '../widgets/NafModal';
import { profileStatusChangeModalUISelector } from './selectors';
import { appendDotsAfterChars } from './utils';

const mapDispatchToProps = dispatch => ({
  showProfileStatusChangeModal: (profileStatus, name, uuid) => dispatch(setUIStateAction(PROFILE_STATUS_CHANGE_MODAL, { profileStatus, name, uuid })),
  changeProfileStatus: (uuid, profileStatus) => dispatch(changeProfileStatusAction(uuid, profileStatus)),
  handleViewProfile: (uuid, history) => dispatch(handleSelectProfileAction(uuid, history)),
  closeModal: () => dispatch(setUIStateAction(PROFILE_STATUS_CHANGE_MODAL, null))
});

const mapStateToProps = state => ({
  statusData: profileStatusChangeModalUISelector(state)
});

const ResultListView = (props) => {
  const {
    userData,
    changeProfileStatus,
    showProfileStatusChangeModal,
    statusData,
    closeModal,
    handleViewProfile,
    history
  } = props;
  const { avatar } = userData;
  const {
    name,
    roles,
    dailyRate,
    country,
    city
  } = userData.generalInformation;

  const { uuid } = userData;
  return (
    <div className="naf-profile-row d-flex justify-content-between mb-5">
      <div
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.preventDefault();
          handleViewProfile(uuid, history);
        }}
      >
        <div className="naf-profile-photo-xs" style={{ backgroundImage: avatar ? `url(static/${avatar})` : `url(${DefaultAvatar})` }} />
      </div>
      <div className="naf-profile-row d-flex justify-content-around align-items-start align-items-md-center flex-column flex-md-row ml-2 ml-md-0">
        <span>
          NAME
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <strong
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              handleViewProfile(uuid, history);
            }}
            className="ml-2"
          >{appendDotsAfterChars(name, MaxViewFieldLength.NAME)}
          </strong>
        </span>
        <span>ROLES <strong className="ml-2">{appendDotsAfterChars(rolesFormatter(roles), MaxViewFieldLength.ROLES)}</strong></span>
        <span>DAILY RATE <strong className="ml-2">{dailyRateFormatter(dailyRate)}</strong></span>
        <span>COUNTRY <strong className="ml-2">{appendDotsAfterChars(country, MaxViewFieldLength.COUNTRY)}</strong></span>
        <span>CITY <strong className="ml-2">{appendDotsAfterChars(city, MaxViewFieldLength.CITY)}</strong></span>
      </div>
      <div className="d-flex flex-column justify-content-between">
        <div className="p-1">
          <DropdownList
            containerClassName="custom-select naf-dark-select"
            data={ViewProfileStatusArray}
            value={userData.profileStatus}
            onChange={(val) => {
              if (val !== userData.profileStatus) {
                showProfileStatusChangeModal(val, name, uuid);
              }
            }}
          />
          {
            statusData && statusData.name && statusData.uuid && statusData.profileStatus &&
            <NafModal
              title="Confirm Change"
              type="warning"
              message={`Are you sure you want to change the profile status of user ${statusData.name} to ${statusData.profileStatus}?`}
              leftBtnLabel="OK"
              rightBtnLabel="Cancel"
              rightBtnClickHandler={closeModal}
              leftBtnClickHandler={() => changeProfileStatus(statusData.uuid, statusData.profileStatus)}
            />
          }
        </div>
        <button className="btn btn-default naf-btn-red-deep align-self-end" onClick={() => handleViewProfile(uuid, history)}>VIEW PROFILE</button>
      </div>
    </div>
  );
};

ResultListView.propTypes = {
  userData: PropTypes.shape,
  changeProfileStatus: PropTypes.func,
  handleViewProfile: PropTypes.func,
  closeModal: PropTypes.func,
  showProfileStatusChangeModal: PropTypes.func,
  history: PropTypes.shape,
  statusData: PropTypes.shape
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResultListView));
