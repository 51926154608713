import React from 'react';
import { reduxForm } from 'redux-form/immutable';
import CarField from './Fields/CarField';
import { MORE_INFORMATION } from '../constants';
import PermitCostField from './Fields/PermitCostField';
import { submitSectionForm } from '../../widgets/ProfileSectionForm/SectionType/FieldArraySection';
import Spinner from '../../widgets/Spinner';
import EaseOfPermitField from './Fields/EaseOfPermit';
import AccommodationCost from './Fields/AccommodationCost/AccommodationCost';
import CarRentalCost from './Fields/CarRentalCost';
import CountryEventInformation from './Fields/CountryEventInformation';

const MoreInformation = () => (
  <div className="card naf-card col-md-12">
    <Spinner element={MORE_INFORMATION} />
    <div className="card-body">
      <h4 className="card-title"><span>MORE INFORMATION</span></h4>
      <div className="row">
        <div className="col-md-6">
          <CarField />
          <PermitCostField />
          <EaseOfPermitField />
          <AccommodationCost />
        </div>
        <div className="col-md-6">
          <CarRentalCost />
          <CountryEventInformation />
        </div>
      </div>
    </div>
  </div>
);

export default reduxForm({
  form: MORE_INFORMATION,
  enableReinitialize: true,
  onSubmit: submitSectionForm(MORE_INFORMATION, true)
})(MoreInformation);

