import { takeLatest, all, call, put } from 'redux-saga/effects';
import { socialLoginRequest } from '../../core/utils/restClient';
import { SET_AUTH } from '../../core/auth/reducer';
import { getGlobalHistory } from '../../core/app';
import { getProfileAction, updateMissingInformationAction } from '../../Profile/actions';
import { setUIStateAction } from '../../core/actions';
import { IS_UNDER_REVIEW, MISSING_INFORMATION } from '../../Profile/constants';
import { MAIN_SECTION, PERSONAL_INFORMATION } from '../../core/constants';

function* loginWithSocialSaga(action) {
  try {
    yield call(
      socialLoginRequest,
      action.payload.code,
      action.payload.redirect,
      action.payload.provider
    );
    yield put({ type: SET_AUTH });
  } finally {
    yield put(getProfileAction());
    yield put(updateMissingInformationAction());
    yield put(setUIStateAction(MISSING_INFORMATION, true));
    yield put(setUIStateAction(IS_UNDER_REVIEW, true));
    yield put(setUIStateAction(MAIN_SECTION, PERSONAL_INFORMATION));
    getGlobalHistory().push('/login');
  }
}

export default function* loginWithGoogleWatcher() {
  yield all([
    takeLatest('LOGIN_WITH_SOCIAL', loginWithSocialSaga)
  ]);
}
