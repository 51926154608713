import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import EditableProfileField from './EditableProfileField';
import { GENERAL_INFORMATION, GeneralInformationFields } from '../constants';
import { initGeneralInfoUIStateAction } from '../actions';
import SocialMedia from '../SocialMedia/SocialMedia';
import generalInformationSubmissionHandler from './generalInformationSubmissionHandler';
import validations from '../../core/utils/validations';
import NafModal from '../../widgets/NafModal';
import { MODAL, WARNING } from '../../core/constants';
import { setUIStateAction } from '../../core/actions';
import { fieldRemovalWarningModalUISelector } from '../selectors';
import { adminRoleSelector } from '../../Login/selectors';

const mapStateToProps = state => ({
  shouldShowFieldRemovalWarningModal: fieldRemovalWarningModalUISelector(state),
  isAdmin: adminRoleSelector(state)
});

const mapDispatchToProps = dispatch => ({
  initGeneralInfoUIState: () => dispatch(initGeneralInfoUIStateAction()),
  dismissWarning: () => dispatch(setUIStateAction([GENERAL_INFORMATION, MODAL], false))
});

const maxLengthValidators = new Map(Object.entries({
  [GeneralInformationFields.ADDRESS]: validations.maxLength(60),
  [GeneralInformationFields.NAME]: validations.maxLength(60),
  [GeneralInformationFields.EMAIL]: validations.maxLength(60),
  [GeneralInformationFields.CITY]: validations.maxLength(60),
  [GeneralInformationFields.PHONE]: validations.maxLength(15),
  [GeneralInformationFields.SKYPE]: validations.maxLength(30),
  [GeneralInformationFields.WHATSAPP]: validations.maxLength(15),
  [GeneralInformationFields.VIBER]: validations.maxLength(15),
  [GeneralInformationFields.SECONDARY_PHONE]: validations.maxLength(15)
}));

const renderDirectLink = uuid => (
  <div className="d-flex justify-content-start align-items-center naf-hoverable naf-edit-row">
    <span className="mr-2 ">Direct Link:</span>
    <div>
      <CopyToClipboard
        text={`${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/direct-link?uuid=${uuid}`}
      >
        <button className="btn-sm">Copy to clipboard</button>
      </CopyToClipboard>
    </div>
  </div>
);

const renderEditableField = fieldName =>
  (
    maxLengthValidators.has(fieldName) ?
      <Field
        key={fieldName}
        name={fieldName}
        component={EditableProfileField}
        validate={[
            maxLengthValidators.get(fieldName)
        ]}
      /> :
      <Field
        key={fieldName}
        name={fieldName}
        component={EditableProfileField}
      />
  );

class GeneralInformationForm extends Component {
  componentDidMount() {
    this.props.initGeneralInfoUIState();
  }
  render() {
    return (
      <div className="col-xl-8">
        <div className="row">
          <div className="naf-profile-fields col-xl-10 py-3">
            <div className="row naf-full-height">
              <div className="col-lg-12">
                <div className="mb-3">
                  {this.props.isAdmin && renderDirectLink(this.props.uuid)}
                  {[GeneralInformationFields.NAME, GeneralInformationFields.EMAIL, GeneralInformationFields.ROLES, GeneralInformationFields.DAILY_RATE]
                    .map(fieldName => renderEditableField(fieldName))}
                </div>
                <div className="mb-3">
                  { [GeneralInformationFields.ADDRESS, GeneralInformationFields.CITY, GeneralInformationFields.COUNTRY]
                    .map(fieldName => renderEditableField(fieldName)) }
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3 mb-lg-0">
                  {
                    [GeneralInformationFields.PHONE, GeneralInformationFields.SKYPE]
                      .map(fieldName => renderEditableField(fieldName))
                  }
                </div>
              </div>
              <div className="col-lg-6">
                {
                  [GeneralInformationFields.WHATSAPP, GeneralInformationFields.VIBER, GeneralInformationFields.SECONDARY_PHONE]
                    .map(fieldName => renderEditableField(fieldName))
                }
              </div>
              {
                this.props.shouldShowFieldRemovalWarningModal &&
                <NafModal
                  type={WARNING}
                  message="Leaving this field empty will move your profile to the Under Review category"
                  title="Profile field removal"
                  leftBtnLabel="OK"
                  leftBtnClickHandler={this.props.dismissWarning}
                />
              }
            </div>
          </div>
          <SocialMedia />
        </div>
      </div>
    );
  }
}

GeneralInformationForm.propTypes = {
  isAdmin: PropTypes.shape(),
  initGeneralInfoUIState: PropTypes.func,
  shouldShowFieldRemovalWarningModal: PropTypes.bool,
  dismissWarning: PropTypes.func,
  uuid: PropTypes.string
};

const GeneralInformationFormContainer = connect(mapStateToProps, mapDispatchToProps)(GeneralInformationForm);

export default reduxForm({
  form: GENERAL_INFORMATION,
  enableReinitialize: true,
  onSubmit: generalInformationSubmissionHandler
})(GeneralInformationFormContainer);
