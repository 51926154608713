import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import { loginRedirect as facebookLoginRedirect } from './constants/facebookConstants';
import { loginRedirect as googleLoginRedirect } from './constants/googleConstants';

const socialParams = {
  '/oauth2-google': {
    redirect: googleLoginRedirect,
    provider: 'google'
  },
  '/oauth2-facebook': {
    redirect: facebookLoginRedirect,
    provider: 'facebook'
  }
};

const mapDispatchToProps = (dispatch, { location }) => ({
  getUserInfo: ({ code }) => {
    if (Object.keys(socialParams).includes(location.pathname)) {
      dispatch({ type: 'LOGIN_WITH_SOCIAL', payload: { code, ...socialParams[location.pathname] } });
    }
  }
});

class Oauth2WaitingScreen extends React.Component {
  componentWillMount() {
    this.props.getUserInfo(parse(this.props.location.search));
  }
  render() {
    return <div style={{ minHeight: '500px' }}>Please wait...</div>;
  }
}

Oauth2WaitingScreen.propTypes = {
  getUserInfo: PropTypes.func,
  location: PropTypes.shape({ search: PropTypes.string })
};

export default connect(null, mapDispatchToProps)(Oauth2WaitingScreen);
