import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fieldInputPropTypes } from 'redux-form/immutable';
import { DropdownList } from 'react-widgets';
import countries from '../../core/constants/countries';
import { COUNTRY, FILTERS, filtersSearchStyle, SEARCH } from '../constants';
import { setUIStateAction } from '../../core/actions';
import { VIEW } from '../../core/constants';

const mapDispatchToProps = dispatch => ({
  handleChange: () => dispatch(setUIStateAction([SEARCH, FILTERS, COUNTRY], VIEW))
});

const CountriesFilterSearchableDropDown = ({ input, handleChange }) => (
  <Fragment>
    <DropdownList
      id="search-country"
      containerClassName={filtersSearchStyle}
      filter="contains"
      searchIcon={<span className="naf-icon-pencil" />}
      data={countries}
      valueField="value"
      textField="label"
      value={input.value}
      onChange={(country) => {
        input.onChange(country.value);
        handleChange();
      }}
      placeholder="Country"
    />
  </Fragment>
);

CountriesFilterSearchableDropDown.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes),
  handleChange: PropTypes.func
};

export default connect(null, mapDispatchToProps)(CountriesFilterSearchableDropDown);
