import React from 'react';
import { reduxForm, Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { parse } from 'query-string';
import validations from '../core/utils/validations';
import { SET_NEW_PASSWORD_SUBMIT } from './constants';

let historyRef;

export const setNewPasswordSubmitAction = values => ({
  type: SET_NEW_PASSWORD_SUBMIT,
  payload: {
    values,
    history: historyRef
  }
});
let queryString;

const submitForm = (values, dispatch) => {
  dispatch(setNewPasswordSubmitAction(values.set('hash', queryString.hash).toJS()));
};

const RenderField = ({
  label, name, input, meta
}) => (
  <div className="form-group">
    <label htmlFor={name} className="d-flex justify-content-between">
      <span>{label}</span>
    </label>
    <input
      {...input}
      name={name}
      type="password"
      className={`form-control ${meta.error && 'is-invalid'}`}
      id={name}

    />
    <div className="invalid-feedback">{meta.error}</div>
  </div>
);

RenderField.propTypes = {
  input: PropTypes.shape(),
  label: PropTypes.string,
  name: PropTypes.string,
  meta: PropTypes.string
};

const minLengthValidator = validations.minLength(8);
const passwordsMatchValidator = (value, values) => (value === values.get('password') ? undefined : 'Passwords must match.');

const SetNewPassword = (props) => {
  const {
    handleSubmit,
    location,
    history
  } = props;
  queryString = parse(location.search);
  historyRef = history;
  return (
    <div id="naf-header-register" className="container-fluid naf-bg-header d-flex flex-column justify-content-center">
      <main role="main" className="container d-flex justify-content-center">
        <div className="d-flex flex-column naf-reg-box-wrap">
          <div className="naf-reg-box">
            <h2>SET NEW PASSWORD</h2>
            <div className="px-5 py-2">
              <p>
                Please set your new password and press the submit button.
              </p>
              <p>
                You will be prompted to login with your new password.
              </p>
            </div>
            <form onSubmit={handleSubmit(submitForm)}>
              <Field
                label="Set new password"
                name="password"
                component={RenderField}
                validate={[
                  minLengthValidator
                ]}
              />
              <Field
                label="Confirm new password"
                name="password-confirm"
                component={RenderField}
                validate={[
                  passwordsMatchValidator
                ]}
              />
              <div className="form-group d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn naf-btn-red naf-btn-wide mt-3"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

SetNewPassword.propTypes = {
  handleSubmit: PropTypes.func,
  location: PropTypes.shape(),
  history: PropTypes.shape()
};

export default reduxForm({
  submit: submitForm,
  form: 'SET_NEW_PASSWORD',
})(withRouter(SetNewPassword));
