/* eslint-disable arrow-body-style */
import getSymbolFromCurrency from 'currency-symbol-map';
import { RATE_FILTER_PLACEHOLDER } from '../constants';

export const dailyRateFormatter = (dailyRate) => {
  return dailyRate && dailyRate.amount && dailyRate.currency
    ? `${dailyRate.amount} ${getSymbolFromCurrency(dailyRate.currency)}`
    : '';
};

export const rolesFormatter = (roles) => {
  switch (roles.length) {
    case 0:
      return '';
    case 1:
      return roles[0];
    default:
      return roles.join(' | ');
  }
};

export const dailyRateRangeFormatter = (dailyRateFrom, dailyRateTo) => {
  if (dailyRateFrom && (!dailyRateTo || dailyRateTo <= 0)) {
    return `From ${dailyRateFrom}`;
  }
  if ((!dailyRateFrom || dailyRateFrom <= 0) && dailyRateTo) {
    return `Up to ${dailyRateTo}`;
  }
  if ((!dailyRateFrom || dailyRateFrom <= 0) && (!dailyRateTo || dailyRateTo <= 0)) {
    return RATE_FILTER_PLACEHOLDER;
  }
  return `${dailyRateFrom} - ${dailyRateTo}`;
};
