import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { stopSubmit, getFormValues, arrayRemove, submit, reset } from 'redux-form/immutable';
import { fromJS } from 'immutable';
import { authorizedRequest, downloadFile, multipartFormDataRequest, updateEmail } from '../../core/restClient';
import {
  DELETE_ATTACHMENT,
  DOWNLOAD_ATTACHMENT,
  GET_PROFILE,
  INIT_GENERAL_INFO_UI,
  REMOVE_FIELD_THEN_SUBMIT,
  setProfileAction,
  SUBMIT_BIO_FORM,
  SUBMIT_FILE_UPLOAD,
  SUBMIT_INTERESTS_FORM,
  SUBMIT_SKILLS_FORM,
  updateBioAction,
  setGeneralInformationAction,
  updateInterestsAction,
  updateSectionAction,
  updateSkillsAction,
  UPLOAD_AVATAR,
  UPDATE_GENERAL_INFORMATION,
  setMissingInformationAction,
  UPDATE_MISSING_INFORMATION,
  updateMissingInformationAction,
  ADD_NEW_PROJECT,
  setProjects,
  UPDATE_EXISTING_PROJECT,
  DELETE_PROJECT,
  UPDATE_SOCIAL_MEDIA,
  setSocialMediaAction,
  UPDATE_ACCOUNT_EMAIL,
  UPLOAD_CV,
  DOWNLOAD_CV, SUBMIT_INVOICE_UPLOAD, DELETE_INVOICE, DOWNLOAD_PO_DOCUMENT,
  UPDATE_ACCOUNT_PASSWORD, updateNotesAction, SUBMIT_NOTES_FORM
} from '../actions';
import { clearSectionSubmittingFieldAction, setUIStateAction } from '../../core/actions';
import {
  GENERAL_INFORMATION,
  GeneralInformationFields,
  VIEW_MODE,
  ATTACHMENTS,
  BIO,
  INTERESTS,
  SKILLS,
  UPLOAD_FILE_MODAL,
  HIDE_MODE,
  UPLOAD_AVATAR_MODAL,
  AVATAR,
  HIDE_ADD_PRJ_BTN,
  PROJECTS,
  SHOW_SUBMIT_CANCEL_PRJ_BTN,
  HIDE_SUBMIT_CANCEL_PRJ_BTN, SHOW_SOCIAL_MEDIA_POPUP,
  UPDATE_ACCOUNT_EMAIL_FORM,
  UPLOAD_CV_MODAL, LANGUAGES, IS_UNDER_REVIEW, NOTES
} from '../constants';
import {
  ACCOUNT,
  ADD,
  DELETE,
  ERROR,
  HIDE_CONTROLS, INVOICES,
  MODAL, MODAL_STATUS, MODAL_UPDATING,
  MODE,
  PASSWORD,
  SPINNER, SUCCESS,
  UPDATE
} from '../../core/constants';
import { sleep } from '../../core/utils';
import {
  accountUUIDSelector,
  generalInformationSelector,
  generalInformationSubmittingFieldSelector,
  modalIndexUIStateSelector,
  projectsSelector
} from '../selectors';
import { validateAmount, validateEmail, validatePhones } from '../validators';
import { constructProjectPayload } from '../Projects/utils';
import { enrichLinksWithHttpPrefix } from '../SocialMedia/utils';
import { checkEmailAvailability } from '../../utils/sagas';
import { adminRoleSelector } from '../../Login/selectors';

export function* getIdentifier(url) {
  const isAdmin = yield select(adminRoleSelector);
  const uuid = yield select(accountUUIDSelector);
  return isAdmin ? url.replace('/me/', `/${uuid}/`) : url;
}

function* fetchProfileSaga(action) {
  try {
    const url = action.payload.uuid ? `/profile/${action.payload.uuid}/` : '/profile/me/';
    const profile = yield call(authorizedRequest, 'GET', url);
    yield put(setProfileAction(fromJS(profile)));
  } catch (err) {
    alert('Could not fetch profile');
  }
}

function* fetchMissingInformationSaga() {
  try {
    const url = yield getIdentifier('/profile/me/missing-information');
    const missingInformation = yield call(authorizedRequest, 'GET', url);
    if (missingInformation.currentStatus === 'UNDER_REVIEW' && missingInformation.missingInformation.length === 0) {
      yield put(setUIStateAction(IS_UNDER_REVIEW, true));
    }
    yield put(setMissingInformationAction(fromJS(missingInformation)));
  } catch (err) {
    alert('Could not fetch missing information');
  }
}

function* generalInformationUpdateValidator(data) {
  let emailErrors = {};
  const currentEmail = (yield select(generalInformationSelector)).get(GeneralInformationFields.EMAIL);
  const currentProfileUUID = yield select(accountUUIDSelector);
  if (currentEmail !== data[GeneralInformationFields.EMAIL]) {
    emailErrors = yield call(validateEmail, data[GeneralInformationFields.EMAIL], currentProfileUUID);
  }
  const phoneErrors = yield call(validatePhones, data[GeneralInformationFields.PHONE], data[GeneralInformationFields.SECONDARY_PHONE]);
  const amountErrors = yield call(validateAmount, data[GeneralInformationFields.DAILY_RATE][GeneralInformationFields.AMOUNT]);
  return Object.assign({}, emailErrors, phoneErrors, amountErrors);
}

function* updateSocialMediaSaga(action) {
  const { data } = action.payload;
  try {
    const url = yield getIdentifier('/profile/me/social-media');
    const payload = enrichLinksWithHttpPrefix(data.toJS());
    const updatedSocialMedia = yield call(authorizedRequest, 'PUT', url, payload);
    yield put(updateMissingInformationAction());
    yield put(setSocialMediaAction(updatedSocialMedia));
    yield put(setUIStateAction(SHOW_SOCIAL_MEDIA_POPUP, false));
  } catch (err) {
    alert('Could not update social media');
  }
}

function* updateGeneralInformationSaga(action) {
  const { data } = action.payload;
  const errors = yield call(generalInformationUpdateValidator, data);
  if (Object.keys(errors).length) {
    yield put(stopSubmit(GENERAL_INFORMATION, errors));
  } else {
    try {
      const submittingField = yield select(generalInformationSubmittingFieldSelector);
      const url = yield getIdentifier('/profile/me/general-information');
      const updatedGeneralInfo = yield call(authorizedRequest, 'PUT', url, data);
      yield put(updateMissingInformationAction());
      yield put(setGeneralInformationAction(updatedGeneralInfo));
      yield put(setUIStateAction(`${GENERAL_INFORMATION}-${submittingField}`, VIEW_MODE));
      yield put(clearSectionSubmittingFieldAction(GENERAL_INFORMATION));
    } catch (err) {
      alert('Could not update general information');
    }
  }
}

function* addNewProjectSaga(action) {
  const { data } = action.payload;
  const existingProjectsList = yield select(projectsSelector);
  const existingProjects = (existingProjectsList && existingProjectsList.size && existingProjectsList.toJS()) || [];
  const newProject = constructProjectPayload(data.toJS().projects[0]);
  existingProjects.push(newProject);
  try {
    const url = yield getIdentifier('/profile/me/projects');
    const updatedProjectsResponse = yield call(authorizedRequest, 'PUT', url, existingProjects);
    yield put(setProjects(updatedProjectsResponse));
    yield put(updateMissingInformationAction());
    yield put(setUIStateAction([PROJECTS, ADD, MODE], VIEW_MODE));
    yield put(setUIStateAction([PROJECTS, ADD, HIDE_ADD_PRJ_BTN], false));
    yield put(setUIStateAction([PROJECTS, ADD, SHOW_SUBMIT_CANCEL_PRJ_BTN], false));
  } catch (err) {
    alert('Could not add new project.');
  }
}

function* updateExistingProjectSaga(action) {
  const { data, index } = action.payload;
  const existingProjects = (yield select(projectsSelector)).toJS();
  // swap
  existingProjects[index] = constructProjectPayload(data.toJS().projects[0]);
  try {
    const url = yield getIdentifier('/profile/me/projects');
    const updatedProjectsResponse = yield call(authorizedRequest, 'PUT', url, existingProjects);
    yield existingProjects.map((prj, ind) => put(setUIStateAction([PROJECTS, UPDATE, HIDE_CONTROLS, ind], false)));
    yield put(setProjects(updatedProjectsResponse));
    yield put(updateMissingInformationAction());
    yield put(setUIStateAction([PROJECTS, UPDATE, HIDE_SUBMIT_CANCEL_PRJ_BTN, index], true));
    yield put(setUIStateAction([PROJECTS, UPDATE, MODE, index], VIEW_MODE));
    yield put(setUIStateAction([PROJECTS, ADD, HIDE_ADD_PRJ_BTN], false));
  } catch (err) {
    alert('Could not update project.');
  }
}

function* deleteProjectSaga() {
  const index = yield select(modalIndexUIStateSelector);
  const existingProjects = (yield select(projectsSelector)).toJS();
  existingProjects.splice(index, 1);
  try {
    const url = yield getIdentifier('/profile/me/projects');
    const updatedProjectsResponse = yield call(authorizedRequest, 'PUT', url, existingProjects);
    yield put(setProjects(updatedProjectsResponse));
    yield put(updateMissingInformationAction());
    yield put(setUIStateAction([PROJECTS, DELETE, MODAL], false));
  } catch (err) {
    alert('Could not delete project.');
  }
}

function* submitBioSaga(action) {
  try {
    if (!action.payload || !action.payload.bio) {
      yield put(setUIStateAction([GENERAL_INFORMATION, MODAL], true));
    }
    yield put(setUIStateAction(SPINNER, BIO));
    yield sleep(1000).then(() => console.log('slept for 1s'));
    const url = yield getIdentifier('/profile/me/bio');
    const response = yield call(authorizedRequest, 'PUT', url, action.payload);
    yield put(updateMissingInformationAction());
    yield put(updateBioAction(response.bio));
  } catch (e) {
    alert('Could not update bio');
    yield put(reset(BIO));
  } finally {
    yield put(setUIStateAction(BIO, VIEW_MODE));
    yield put(setUIStateAction(SPINNER, ''));
  }
}

function* submitSkillsSaga() {
  try {
    yield put(setUIStateAction(SPINNER, SKILLS));
    yield sleep(1000).then(() => console.log('slept for 1s'));
    const formValues = (yield select(getFormValues(SKILLS))).toJS();
    const url = yield getIdentifier('/profile/me/skills');
    const response = yield call(authorizedRequest, 'PUT', url, {
      skills: formValues.skills
    });
    yield put(updateSkillsAction(response.skills));
  } catch (e) {
    alert('Could not update skills');
    yield put(reset(INTERESTS));
  } finally {
    yield put(setUIStateAction([SKILLS, 'edit'], ''));
    yield put(setUIStateAction([SKILLS, 'add'], ''));
    yield put(setUIStateAction(SPINNER, ''));
  }
}

function* submitInterestsSaga() {
  try {
    yield put(setUIStateAction(SPINNER, INTERESTS));
    yield sleep(1000).then(() => console.log('slept for 1s'));
    const formValues = (yield select(getFormValues(INTERESTS))).toJS();
    const url = yield getIdentifier('/profile/me/interests');
    const response = yield call(authorizedRequest, 'PUT', url, {
      interests: formValues.interests
    });
    yield put(updateInterestsAction(response.interests));
  } catch (e) {
    alert('Could not update interests');
    yield put(reset(INTERESTS));
  } finally {
    yield put(setUIStateAction([INTERESTS, 'edit'], ''));
    yield put(setUIStateAction([INTERESTS, 'add'], ''));
    yield put(setUIStateAction(SPINNER, ''));
  }
}

function* removeFieldThenSubmitBlocking(form, field, index) {
  yield put.resolve(arrayRemove(form, field, index));
  if (form === LANGUAGES) {
    const languagesMap = (yield select(getFormValues(LANGUAGES)));
    const languagesCount = (languagesMap.get(LANGUAGES)).size;
    if (languagesCount === 0) {
      yield put(setUIStateAction([GENERAL_INFORMATION, MODAL], true));
    }
  }
  yield put(submit(form));
}

function* removeFieldThenSubmit(action) {
  yield call(removeFieldThenSubmitBlocking, action.payload.form, action.payload.field, action.payload.index);
}

function* submitFileUploadSaga(action) {
  try {
    yield put(setUIStateAction(SPINNER, UPLOAD_FILE_MODAL));
    yield sleep(1000).then(() => console.log('slept for 1s'));
    const url = yield getIdentifier('/profile/me/attachments');
    const response = yield call(
      multipartFormDataRequest,
      url,
      action.payload
    );
    yield put(updateSectionAction('attachments', response.attachments));
  } catch (e) {
    const errors = e;
    alert(`Could not update attachments${errors.message}`);
  } finally {
    yield put(setUIStateAction(SPINNER, ''));
    yield put(setUIStateAction(UPLOAD_FILE_MODAL, false));
  }
}

function* submitInvoiceUploadSaga(action) {
  try {
    yield put(setUIStateAction(SPINNER, UPLOAD_FILE_MODAL));
    const response = yield call(
      multipartFormDataRequest,
      '/profile/me/invoice-requests',
      action.payload
    );
    yield put(updateSectionAction('invoiceRequests', response.invoiceRequests));
    yield put(setUIStateAction(UPLOAD_FILE_MODAL, false));
  } catch (e) {
    const errors = e;
    yield put(stopSubmit('upload-file', { _error: errors.message }));
  } finally {
    yield put(setUIStateAction(SPINNER, ''));
  }
}

function* submitCvUploadSaga(action) {
  try {
    const url = yield getIdentifier('/profile/me/cv');
    const response = yield call(
      multipartFormDataRequest,
      url,
      action.payload
    );
    yield put(updateSectionAction('cv', response.cv));
    yield put(updateMissingInformationAction());
  } catch (e) {
    alert('Could not update cv');
  } finally {
    yield put(setUIStateAction(UPLOAD_CV_MODAL, false));
  }
}

function* submitAvatarUploadSaga(action) {
  try {
    const url = yield getIdentifier('/profile/me/avatar');
    const response = yield call(
      multipartFormDataRequest,
      url,
      action.payload,
      'PUT'
    );
    yield put(updateSectionAction(AVATAR, response.avatar));
  } catch (e) {
    alert('Could not update attachments');
  } finally {
    yield put(setUIStateAction(UPLOAD_AVATAR_MODAL, false));
  }
}

function* deleteAttachmentSaga(action) {
  try {
    const url = yield getIdentifier(`/profile/me/attachments/${action.payload}`);
    yield put(setUIStateAction(SPINNER, ATTACHMENTS));
    yield sleep(1000).then(() => console.log('slept for 1s'));
    const response = yield call(
      authorizedRequest,
      'delete',
      url
    );
    yield put(updateSectionAction('attachments', response.attachments));
  } catch (e) {
    alert('Could not delete attachment');
  } finally {
    yield put(setUIStateAction(SPINNER, ''));
  }
}

function* deleteInvoiceSaga(action) {
  try {
    yield put(setUIStateAction(SPINNER, INVOICES));
    yield sleep(1000).then(() => console.log('slept for 1s'));
    const response = yield call(
      authorizedRequest,
      'delete',
      `/profile/me/invoices/${action.payload}`
    );
    yield put(updateSectionAction('invoices', response.invoices));
  } catch (e) {
    alert('Could not delete invoice');
  } finally {
    yield put(setUIStateAction(SPINNER, ''));
  }
}

function* downloadAttachmentSaga(action) {
  try {
    const url = yield getIdentifier(`/api/profile/me/download/attachments/${action.payload.fileId}`);
    yield call(
      downloadFile,
      action.payload,
      url
    );
  } catch (e) {
    alert('Could not download file.');
  }
}

function* downloadPoDocumentSaga(action) {
  try {
    const url = yield getIdentifier(`/api/profile/me/download/po/${action.payload.invoiceRequestId}`);
    yield call(
      downloadFile,
      action.payload,
      url
    );
  } catch (e) {
    alert('Could not download file.');
  }
}

function* downloadCvSaga(action) {
  try {
    const url = yield getIdentifier('/api/profile/me/download/cv');
    yield call(
      downloadFile,
      action.payload,
      url
    );
  } catch (e) {
    alert('Could not download file.');
  }
}

function* initGeneralInfoUI() {
  yield Object.values(GeneralInformationFields)
    .map(field => put(setUIStateAction(`${GENERAL_INFORMATION}-${field}`, VIEW_MODE)));
  yield [GeneralInformationFields.AMOUNT, GeneralInformationFields.CURRENCY]
    .map(field => put(setUIStateAction(`${GENERAL_INFORMATION}-${field}`, HIDE_MODE)));
}

function* updateAccountEmailSaga(action) {
  yield put(setUIStateAction([ACCOUNT, MODAL_UPDATING], true));
  const { email } = action.payload;
  const isRegistrationEmailAvailable = yield call(checkEmailAvailability, email);
  switch (isRegistrationEmailAvailable) {
    case false: {
      yield put(stopSubmit(UPDATE_ACCOUNT_EMAIL_FORM, { email: 'E-mail already registered' }));
      break;
    }
    case undefined: {
      yield put(stopSubmit(UPDATE_ACCOUNT_EMAIL_FORM, { email: 'Could not check email availability. Try again.' }));
      break;
    }
    default:
      try {
        const accountUUID = yield select(accountUUIDSelector);
        yield call(updateEmail, email, accountUUID);
        yield put(setUIStateAction([ACCOUNT, MODAL_STATUS], SUCCESS));
        yield put(setUIStateAction([ACCOUNT, MODAL_UPDATING], false));
      } catch (err) {
        yield put(setUIStateAction([ACCOUNT, MODAL_STATUS], ERROR));
        yield put(setUIStateAction([ACCOUNT, MODAL_UPDATING], false));
      }
  }
}

function* updateAccountPasswordSaga(action) {
  const { password, newPassword } = action.payload;
  const accountUUID = yield select(accountUUIDSelector);
  try {
    yield call(authorizedRequest, 'PUT', `/accounts/${accountUUID}/password`, { password, newPassword });
    yield put(setUIStateAction([ACCOUNT, PASSWORD], VIEW_MODE));
  } catch (err) {
    alert('Could not update account password');
    yield put(setUIStateAction([ACCOUNT, PASSWORD], VIEW_MODE));
  }
}

function* submitNotesSaga(action) {
  try {
    const uuid = yield select(accountUUIDSelector);
    yield put(setUIStateAction(SPINNER, NOTES));
    yield sleep(1000).then(() => console.log('slept for 1s'));
    const response = yield call(authorizedRequest, 'PUT', `/profile/${uuid}/notes`, action.payload);
    yield put(updateMissingInformationAction());
    yield put(updateNotesAction(response.notes));
  } catch (e) {
    alert('Could not update bio');
    yield put(reset(NOTES));
  } finally {
    yield put(setUIStateAction(NOTES, VIEW_MODE));
    yield put(setUIStateAction(SPINNER, ''));
  }
}

export default function* watchProfileActions() {
  yield all([
    takeLatest(GET_PROFILE, fetchProfileSaga),
    takeLatest(UPDATE_MISSING_INFORMATION, fetchMissingInformationSaga),
    takeLatest(INIT_GENERAL_INFO_UI, initGeneralInfoUI),
    takeLatest(UPDATE_GENERAL_INFORMATION, updateGeneralInformationSaga),
    takeLatest(UPDATE_SOCIAL_MEDIA, updateSocialMediaSaga),
    takeLatest(ADD_NEW_PROJECT, addNewProjectSaga),
    takeLatest(UPDATE_EXISTING_PROJECT, updateExistingProjectSaga),
    takeLatest(DELETE_PROJECT, deleteProjectSaga),
    takeLatest(SUBMIT_BIO_FORM, submitBioSaga),
    takeLatest(SUBMIT_INTERESTS_FORM, submitInterestsSaga),
    takeLatest(INIT_GENERAL_INFO_UI, initGeneralInfoUI),
    takeLatest(SUBMIT_SKILLS_FORM, submitSkillsSaga),
    takeLatest(REMOVE_FIELD_THEN_SUBMIT, removeFieldThenSubmit),
    takeLatest(SUBMIT_FILE_UPLOAD, submitFileUploadSaga),
    takeLatest(SUBMIT_INVOICE_UPLOAD, submitInvoiceUploadSaga),
    takeLatest(DELETE_ATTACHMENT, deleteAttachmentSaga),
    takeLatest(DELETE_INVOICE, deleteInvoiceSaga),
    takeLatest(UPLOAD_AVATAR, submitAvatarUploadSaga),
    takeLatest(DOWNLOAD_ATTACHMENT, downloadAttachmentSaga),
    takeLatest(UPDATE_ACCOUNT_EMAIL, updateAccountEmailSaga),
    takeLatest(UPLOAD_CV, submitCvUploadSaga),
    takeLatest(DOWNLOAD_CV, downloadCvSaga),
    takeLatest(UPDATE_ACCOUNT_PASSWORD, updateAccountPasswordSaga),
    takeLatest(UPLOAD_CV, submitCvUploadSaga),
    takeLatest(SUBMIT_NOTES_FORM, submitNotesSaga),
    takeLatest(DOWNLOAD_PO_DOCUMENT, downloadPoDocumentSaga)
  ]);
}
