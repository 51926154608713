import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form/immutable';
import GeneralInformationLayout from './GeneralInformation/GeneralInformationLayout';
import {
  nameSelector,
  missingInformationUIStateSelector,
  renderedMainSectionSelector,
  socialMediaUIStateSelector, projectsSelector
} from './selectors';
import MissingInformation from './MissingInformation';
import {
  ACCOUNT,
  ADD,
  HIDE_CONTROLS, INVOICES,
  MAIN_SECTION,
  MODE,
  PERSONAL_INFORMATION,
  PROJECTS,
  UPDATE
} from '../core/constants';
import PersonalInformation from './PersonalInformation';
import Projects from './Projects/Projects';
import { setUIStateAction } from '../core/actions';
import SocialMediaUpsertPopUp from './SocialMedia/SocialMediaUpsertPopUp';
import Account from './Account/Account';
import {
  ADD_NEW_PROJECT_FORM,
  HIDE_ADD_PRJ_BTN, HIDE_SUBMIT_CANCEL_PRJ_BTN,
  SHOW_SUBMIT_CANCEL_PRJ_BTN, UPDATE_EXISTING_PROJECT_FORM,
  VIEW_MODE
} from './constants';
import { getProfileAction } from './actions';
import Invoices from './Invoices/Invoices';


const mainSectionRenderer = (sectionName) => {
  switch (sectionName) {
    case PERSONAL_INFORMATION:
      return <PersonalInformation />;
    case PROJECTS:
      return <Projects />;
    case ACCOUNT:
      return <Account />;
    case INVOICES:
      return <Invoices />;
    default:
      return <span />;
  }
};

const mapStateToProps = state => ({
  renderedMainSection: renderedMainSectionSelector(state),
  projects: projectsSelector(state),
  shouldShowMissingInfo: missingInformationUIStateSelector(state),
  shouldShowSocialMediaPopUp: socialMediaUIStateSelector(state),
  name: nameSelector(state)
});

const mapDispatchToProps = dispatch => ({
  switchTo: (selectedSection, projects) => {
    // reinit projects ui state when leaving from projects section.
    dispatch(setUIStateAction(MAIN_SECTION, selectedSection));
    if (selectedSection === ACCOUNT || selectedSection === INVOICES) {
      dispatch(getProfileAction());
    }
    if (selectedSection !== PROJECTS) {
      dispatch(reset(ADD_NEW_PROJECT_FORM));
      dispatch(setUIStateAction([PROJECTS, ADD, MODE], VIEW_MODE));
      dispatch(setUIStateAction([PROJECTS, ADD, HIDE_ADD_PRJ_BTN], false));
      dispatch(setUIStateAction([PROJECTS, ADD, SHOW_SUBMIT_CANCEL_PRJ_BTN], false));
      dispatch(reset(UPDATE_EXISTING_PROJECT_FORM));
      if (projects) {
        projects.toArray().forEach((prj, index) => {
          dispatch(setUIStateAction([PROJECTS, UPDATE, HIDE_CONTROLS, index], false));
          dispatch(setUIStateAction([PROJECTS, UPDATE, HIDE_SUBMIT_CANCEL_PRJ_BTN, index], true));
          dispatch(setUIStateAction([PROJECTS, UPDATE, MODE, index], VIEW_MODE));
        });
      }
    }
  }
});

const MainSection = (props) => {
  const {
    renderedMainSection,
    shouldShowMissingInfo,
    shouldShowSocialMediaPopUp,
    switchTo,
    projects,
    name
  } = props;
  return (
    <main role="main" className="container">
      <GeneralInformationLayout />
      { shouldShowMissingInfo && <MissingInformation /> }
      <div className="naf-red-box mb-2">
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <h1>{ name }</h1>
          <div>
            <button
              className={`btn naf-btn-${renderedMainSection === PERSONAL_INFORMATION ? 'orange' : 'red-deeper'}`}
              onClick={() => switchTo(PERSONAL_INFORMATION, projects)}
            >
              PROFILE
            </button>
            <button
              className={`btn naf-btn-${renderedMainSection === PROJECTS ? 'orange' : 'red-deeper'}`}
              onClick={() => switchTo(PROJECTS)}
            >
              PROJECTS
            </button>
            <button
              className={`btn naf-btn-${renderedMainSection === ACCOUNT ? 'orange' : 'red-deeper'}`}
              onClick={() => switchTo(ACCOUNT)}
            >
              ACCOUNT
            </button>
            <button
              className={`btn naf-btn-${renderedMainSection === INVOICES ? 'orange' : 'red-deeper'}`}
              onClick={() => switchTo(INVOICES)}
            >
              INVOICES
            </button>
          </div>
        </div>
      </div>
      { shouldShowSocialMediaPopUp && <SocialMediaUpsertPopUp /> }
      { mainSectionRenderer(renderedMainSection) }
    </main>
  );
};

MainSection.propTypes = {
  renderedMainSection: PropTypes.string,
  name: PropTypes.string,
  projects: PropTypes.shape(),
  shouldShowMissingInfo: PropTypes.bool,
  shouldShowSocialMediaPopUp: PropTypes.bool,
  switchTo: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(MainSection);
