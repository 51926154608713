export const MAIN = '/';
export const CONTACT_US = '/contact-us';
export const TERMS = '/terms';
export const FORGOT_PASSWORD = '/forgot-password';
export const SET_NEW_PASSWORD = '/set-new-password';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const PROFILE = '/profile';
export const EMAIL_CONFIRMATION = '/email-confirmation';
export const ACCOUNT_EMAIL_UPDATE = '/account-email-update';
export const SEARCH = '/search';
export const FACEBOOK_LOGIN_REDIRECT_PATH = '/oauth2-facebook';
export const GOOGLE_LOGIN_REDIRECT_PATH = '/oauth2-google';
