import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  citySortingSelector,
  countrySortingSelector,
  nameSortingSelector,
  rateSortingSelector,
  roleSortingSelector
} from './selectors';
import { ASCENDING, CITY, COUNTRY, DESCENDING, NAME, RATE, ROLE } from './constants';
import { resetSortingAction, setSortingAction } from './actions';

const mapStateToProps = state => ({
  nameSorting: nameSortingSelector(state),
  roleSorting: roleSortingSelector(state),
  rateSorting: rateSortingSelector(state),
  countrySorting: countrySortingSelector(state),
  citySorting: citySortingSelector(state)
});

const mapDispatchToProps = dispatch => ({
  toggleSorting: (field, currentState) => {
    const nextState = (!currentState || currentState === DESCENDING) ? ASCENDING : DESCENDING;
    dispatch(resetSortingAction());
    dispatch(setSortingAction(field, nextState));
  }
});

const SortingControls = (props) => {
  const {
    nameSorting,
    roleSorting,
    citySorting,
    rateSorting,
    countrySorting,
    toggleSorting
  } = props;
  return (
    <div className="d-flex flex-column flex-md-row">
      <p className="mr-4 lead font-weight-bold naf-red-label">Sort by:</p>
      <ul className="list-unstyled d-flex naf-sort-filters">
        <li>
          <a
            href="#"
            className={nameSorting ? 'active' : ''}
            onClick={(e) => {
              e.preventDefault();
              toggleSorting(NAME, nameSorting);
            }}
          >
            NAME<span className={`fa fa-fw fa-long-arrow-${(nameSorting && nameSorting === ASCENDING) ? 'up' : 'down'}`} />
          </a>
        </li>
        <li>
          <a
            href="#"
            className={roleSorting ? 'active' : ''}
            onClick={(e) => {
              e.preventDefault();
              toggleSorting(ROLE, roleSorting);
            }}
          >
            ROLE<span className={`fa fa-fw fa-long-arrow-${(roleSorting && roleSorting === ASCENDING) ? 'up' : 'down'}`} />
          </a>
        </li>
        <li>
          <a
            href="#"
            className={rateSorting ? 'active' : ''}
            onClick={(e) => {
              e.preventDefault();
              toggleSorting(RATE, rateSorting);
            }}
          >
            RATE<span className={`fa fa-fw fa-long-arrow-${(rateSorting && rateSorting === ASCENDING) ? 'up' : 'down'}`} />
          </a>
        </li>
        <li>
          <a
            href="#"
            className={countrySorting ? 'active' : ''}
            onClick={(e) => {
              e.preventDefault();
              toggleSorting(COUNTRY, countrySorting);
            }}
          >
            COUNTRY<span className={`fa fa-fw fa-long-arrow-${(countrySorting && countrySorting === ASCENDING) ? 'up' : 'down'}`} />
          </a>
        </li>
        <li>
          <a
            href="#"
            className={citySorting ? 'active' : ''}
            onClick={(e) => {
              e.preventDefault();
              toggleSorting(CITY, citySorting);
            }}
          >
            CITY<span className={`fa fa-fw fa-long-arrow-${(citySorting && citySorting === ASCENDING) ? 'up' : 'down'}`} />
          </a>
        </li>
      </ul>
    </div>
  );
};

SortingControls.propTypes = {
  nameSorting: PropTypes.oneOf([ASCENDING, DESCENDING, null]),
  roleSorting: PropTypes.oneOf([ASCENDING, DESCENDING, null]),
  citySorting: PropTypes.oneOf([ASCENDING, DESCENDING, null]),
  rateSorting: PropTypes.oneOf([ASCENDING, DESCENDING, null]),
  countrySorting: PropTypes.oneOf([ASCENDING, DESCENDING, null]),
  toggleSorting: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(SortingControls);
