import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reset, submit } from 'redux-form/immutable';
import { BIO, VIEW_MODE } from '../constants';
import { setUIStateAction } from '../../core/actions';
import Spinner from '../../widgets/Spinner';

const mapDispatchToProps = dispatch => ({
  onCancelButtonClick: () => {
    dispatch(reset(BIO));
    dispatch(setUIStateAction(BIO, VIEW_MODE));
  },
  onSubmitButtonClick: () => {
    dispatch(submit(BIO));
  }
});

const BioEdit = ({ onCancelButtonClick, onSubmitButtonClick }) => (
  <React.Fragment>
    <div className="naf-pos-relative">
      <Spinner element={BIO} />
      <Field
        name={BIO}
        className="form-control naf-v-resize mb-1"
        rows="6"
        component="textarea"
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-outline-success naf-icon-btn mr-1" type="button" onClick={() => onSubmitButtonClick()}><span
          className="naf-icon-check"
        />
        </button>
        <button className="btn btn-outline-danger naf-icon-btn mr-3" type="button" onClick={() => onCancelButtonClick()}><span
          className="naf-icon-times"
        />
        </button>
      </div>
    </div>
  </React.Fragment>
);

BioEdit.propTypes = {
  onCancelButtonClick: PropTypes.func,
  onSubmitButtonClick: PropTypes.func
};

export default connect(null, mapDispatchToProps)(BioEdit);
