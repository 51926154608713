import React from 'react';
import { change, submit } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  CITY,
  CITY_FILTER_PLACEHOLDER,
  COUNTRY,
  COUNTRY_FILTER_PLACEHOLDER,
  DAILY_RATE_FROM,
  DAILY_RATE_TO,
  FILTER_FORM, NAME, NAME_FILTER_PLACEHOLDER, PAGE, PROFILE_STATUS_CATEGORY, ProfileStatus, ROLE,
  ROLE_FILTER_PLACEHOLDER, SEARCH
} from './constants';
import { setUIStateAction } from '../core/actions';
import { resetSortingAction, searchAction } from './actions';

const mapDispatchToProps = dispatch => ({
  handleCancel: () => {
    dispatch(change(FILTER_FORM, DAILY_RATE_FROM, null));
    dispatch(change(FILTER_FORM, DAILY_RATE_TO, null));
    dispatch(change(FILTER_FORM, COUNTRY, COUNTRY_FILTER_PLACEHOLDER));
    dispatch(change(FILTER_FORM, CITY, null));
    dispatch(change(FILTER_FORM, ROLE, ROLE_FILTER_PLACEHOLDER));
    dispatch(change(FILTER_FORM, NAME, null));
    dispatch(change(FILTER_FORM, PAGE, 0));
    dispatch(resetSortingAction());
    dispatch(setUIStateAction([SEARCH, PROFILE_STATUS_CATEGORY], ProfileStatus.FULL));
    dispatch(searchAction(ROLE_FILTER_PLACEHOLDER, COUNTRY_FILTER_PLACEHOLDER, null, null, CITY_FILTER_PLACEHOLDER, 0, NAME_FILTER_PLACEHOLDER));
  },
  handleSearch: () => dispatch(submit(FILTER_FORM))
});

const SearchControls = (props) => {
  const {
    handleCancel,
    handleSearch
  } = props;
  return (
    <div className="d-flex justify-content-end flex-grow-1">
      <button
        type="submit"
        className="btn naf-btn-round naf-btn-round--save mr-3"
        onClick={handleSearch}
      >
        <span className="fa fa-fw fa-search" />
      </button>
      <button
        type="button"
        className="btn naf-btn-round naf-btn-round--cancel"
        onClick={handleCancel}
      >
        <span className="fa fa-fw fa-times" />
      </button>
    </div>
  );
};

SearchControls.propTypes = {
  handleCancel: PropTypes.func,
  handleSearch: PropTypes.func
};

export default connect(null, mapDispatchToProps)(SearchControls);
