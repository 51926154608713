import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import nafCardEditableDeletable from '../../widgets/ProfileSectionForm/SectionType/FieldArraySection';
import { LANGUAGES } from '../constants/index';
import { capitalizeFirstLetter } from '../../core/utils/StringFormatters';
import { languageProficiency, languages } from './data';

const LanguagesForm = nafCardEditableDeletable(LANGUAGES);

const RenderLanguageEdit = ({ input }) => (
  <React.Fragment>
    <Field
      className="custom-select"
      component="select"
      name={`${input.name}.name`}
    >
      <option>Select a language</option>
      {
        languages.map(language => <option value={language.name}>{language.name}</option>)
      }
    </Field>
    <Field
      className="custom-select"
      component="select"
      name={`${input.name}.languageProficiency`}
    >
      <option>Select level of proficiency</option>
      {
        languageProficiency.map(proficiency => <option value={proficiency.level}>{capitalizeFirstLetter(proficiency.level)}</option>)
      }
    </Field>
  </React.Fragment>
);

RenderLanguageEdit.propTypes = {
  input: PropTypes.shape()
};

const RenderLanguageView = ({ input }) => (
  <span>{capitalizeFirstLetter(input.value.get('name'))} - <em>{capitalizeFirstLetter(input.value.get('languageProficiency'))}</em></span>
);

RenderLanguageView.propTypes = {
  input: PropTypes.shape()
};

const Languages = ({ initialValues }) => (
  <LanguagesForm initialValues={initialValues} inputType="text" RenderFieldView={RenderLanguageView} RenderFieldEdit={RenderLanguageEdit} />
);

Languages.propTypes = {
  initialValues: PropTypes.shape()
};

export default Languages;
