/* eslint-disable prefer-destructuring */
import { put, takeLatest } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form/immutable';
import { setUserRoleAction, LOGOUT } from '../actions';
import authTokenHandler from '../../core/auth/authTokenHandler';
import { clearStateAction, setUIStateAction } from '../../core/actions';
import { updateMissingInformationAction, getProfileAction } from '../../Profile/actions';
import { IS_UNDER_REVIEW, MISSING_INFORMATION } from '../../Profile/constants';
import { EligibleAdminRoles, MAIN_SECTION, PERSONAL_INFORMATION } from '../../core/constants';
import { EMAIL_FIELD, PASSWORD_FIELD, REMEMBER_ME_FIELD } from '../constants';
import { SEARCH as SEARCH_ROUTE, PROFILE } from '../../Layout/routePaths';
import { isValidEmail } from '../../core/utils';
import { MIN_PASSWORD_LENGTH } from '../../Register/constants';
import { INLINE_FORM, MODE, SEARCH } from '../../Search/constants';

const AUTH_HEADER = 'Authorization';

export const login = intendedRoute => (formData, dispatch, { history }) => {
  let token;
  const email = formData.get(EMAIL_FIELD);
  if (!email || (!isValidEmail(email))) {
    throw new SubmissionError({ _error: 'Invalid email' });
  }
  const password = formData.get(PASSWORD_FIELD);
  if (!password || (password && password.length < MIN_PASSWORD_LENGTH)) {
    throw new SubmissionError({ _error: 'Invalid password' });
  }
  const rememberMe = formData.get(REMEMBER_ME_FIELD);
  const requestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'post',
    body: JSON.stringify({ email, password })
  };
  return fetch('/api/login', requestConfig)
    .then((response) => {
      if (response.ok && response.headers.get(AUTH_HEADER)) {
        const headerValue = response.headers.get(AUTH_HEADER);
        token = headerValue.split(' ')[1];
        return response.json();
      }
      throw new SubmissionError({ _error: 'Login failed' });
    })
    .then((jsonResponse) => {
      const { userRoles } = jsonResponse;
      if (EligibleAdminRoles.includes(userRoles)) {
        authTokenHandler.storeAuthToken(token, false);
        history.push(intendedRoute || SEARCH_ROUTE);
        dispatch(setUIStateAction([SEARCH, MODE], INLINE_FORM));
        dispatch(setUserRoleAction(userRoles));
      } else {
        authTokenHandler.storeAuthToken(token, rememberMe);
        history.push(PROFILE);
        dispatch(getProfileAction());
        dispatch(updateMissingInformationAction());
        // dismissable boxes init
        dispatch(setUIStateAction(MISSING_INFORMATION, true));
        dispatch(setUIStateAction(IS_UNDER_REVIEW, true));
        dispatch(setUIStateAction(MAIN_SECTION, PERSONAL_INFORMATION));
      }
      return jsonResponse;
    });
};

function* handleLogout() {
  authTokenHandler.clearAuthToken();
  yield put(clearStateAction());
}

export default function* watchAuthenticationRequests() {
  yield takeLatest(
    LOGOUT,
    handleLogout
  );
}
