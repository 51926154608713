import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { ACCOUNT, EMAIL, ERROR, MODAL, SUCCESS, WARNING } from '../../core/constants';
import { UPDATE_ACCOUNT_EMAIL_FORM } from '../constants';
import UpdateEmailField from './formFields/UpdateEmailField';
import NafModal from '../../widgets/NafModal';
import updateAccountEmailValidator from './updateAccountEmailValidator';
import { updateAccountEmailAction } from '../actions';
import { setUIStateAction } from '../../core/actions';

const modalMessageRenderer = (status, updating, newAccountEmail) => {
  if (status === WARNING) {
    if (updating) {
      return `Sending verification email to ${newAccountEmail}...`;
    }
    return `You will have to verify ${newAccountEmail} to complete the update`;
  }
  if (status === SUCCESS) {
    return `Check your inbox at ${newAccountEmail}`;
  }
  return 'Could not update email';
};

const mapDispatchToProps = (dispatch, { newAccountEmail }) => ({
  handleEmailUpdate: (status) => {
    switch (status) {
      case WARNING:
        dispatch(updateAccountEmailAction(newAccountEmail));
        break;
      default:
        dispatch(setUIStateAction([ACCOUNT, MODAL], false));
    }
  },
  handleCancelEmailUpdate: () => dispatch(setUIStateAction([ACCOUNT, MODAL], false))
});

const AccountUpdateEmail = (props) => {
  const {
    shouldShowModal,
    status,
    updating,
    handleEmailUpdate,
    handleCancelEmailUpdate,
    newAccountEmail
  } = props;
  return (
    <div className="input-group naf-input-group">
      <Field
        id={EMAIL}
        name={EMAIL}
        component={UpdateEmailField}
        validate={updateAccountEmailValidator}
      />
      {
        shouldShowModal && status &&
        <NafModal
          type={status}
          title="Update Account"
          message={modalMessageRenderer(status, updating, newAccountEmail)}
          leftBtnLabel={!updating && 'OK'}
          rightBtnLabel={!updating && status === WARNING && 'Cancel'}
          leftBtnClickHandler={() => handleEmailUpdate(status)}
          rightBtnClickHandler={handleCancelEmailUpdate}
        />
      }
    </div>
  );
};

AccountUpdateEmail.propTypes = {
  shouldShowModal: PropTypes.bool,
  updating: PropTypes.bool,
  newAccountEmail: PropTypes.string,
  status: PropTypes.oneOf([WARNING, ERROR, SUCCESS]),
  handleEmailUpdate: PropTypes.func,
  handleCancelEmailUpdate: PropTypes.func
};

const AccountUpdateEmailForm = reduxForm({
  form: UPDATE_ACCOUNT_EMAIL_FORM
})(AccountUpdateEmail);

export default connect(null, mapDispatchToProps)(AccountUpdateEmailForm);
