import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setUIStateAction } from '../../core/actions';
import { NOTES, EDIT_MODE } from '../constants';
import { notesSelector } from '../selectors';

const mapStateToProps = state => ({
  notes: notesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  setEditMode: () => dispatch(setUIStateAction(NOTES, EDIT_MODE))
});

const NotesView = ({ setEditMode, notes }) => (
  <div className="card naf-card col-md-6">
    <div className="card-body">
      <h4 className="card-title"><span>NOTES</span></h4>
      <div className="naf-edit-btn-wrapper text-right">
        <button type="button" className="btn btn-link naf-edit-btn" onClick={() => setEditMode()}>EDIT</button>
      </div>
      <p style={{ whiteSpace: 'pre-wrap' }}>{notes}</p>
    </div>
  </div>
);

NotesView.propTypes = {
  setEditMode: PropTypes.func,
  notes: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesView);
