import React from 'react';
import PropTypes from 'prop-types';

const RenderAttachmentField = ({
  filename, fileId, handleDelete, handleDownload, contentType, invoiceData
}) => (
  <li className="naf-hoverable">
    {
      invoiceData ?
        <span>{filename} | <strong>{invoiceData.ownerEmail}</strong> | <strong>{invoiceData.jobNumber}</strong></span>
        : <span>{filename}</span>
    }
    <span className="naf-hoverable-show">
      <button type="button" className="btn btn-link naf-del-btn btn-sm pr-1" onClick={() => handleDelete(invoiceData ? invoiceData.invoiceId : fileId)}><span
        className="naf-icon-times"
      />
      </button>
      <button type="button" className="btn btn-link naf-save-btn btn-sm pl-1" onClick={() => handleDownload(fileId, filename, contentType)}><span
        className="naf-icon-download"
      />
      </button>
    </span>
  </li>
);

RenderAttachmentField.propTypes = {
  invoiceData: PropTypes.shape(),
  filename: PropTypes.string,
  fileId: PropTypes.string,
  handleDelete: PropTypes.func,
  handleDownload: PropTypes.func,
  contentType: PropTypes.string
};

export default RenderAttachmentField;
