export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
export const labelize = text => text.replace(/([A-Z])/g, g => ` ${g[0]}`).toUpperCase().concat(':');
export const camelCaseToDash = str => str
  .replace(/[^a-zA-Z0-9]+/g, '-')
  .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2')
  .replace(/([a-z])([A-Z])/g, '$1-$2')
  .replace(/([0-9])([^0-9])/g, '$1-$2')
  .replace(/([^0-9])([0-9])/g, '$1-$2')
  .replace(/-+/g, '-')
  .toLowerCase();
