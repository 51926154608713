export const SEARCH = 'SEARCH';
export const CHANGE_PROFILE_STATUS = 'CHANGE_PROFILE_STATUS';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const SET_RESULTS_SUMMARY = 'SET_RESULTS_SUMMARY';
export const SET_ROLE_FILTER = 'SET_ROLE_FILTER';
export const SET_COUNTRY_FILTER = 'SET_COUNTRY_FILTER';
export const RESET_SORTING = 'RESET_SORTING';
export const SET_SORTING = 'SET_SORTING';
export const SELECT_VIEW_PROFILE = 'SELECT_VIEW_PROFILE';

export const searchAction = (role, country, dailyRateFrom, dailyRateTo, city, page, name) => ({
  type: SEARCH,
  payload: {
    role,
    country,
    city,
    dailyRateFrom,
    dailyRateTo,
    page,
    name
  }
});

export const setSearchResultsAction = searchResults => ({
  type: SET_SEARCH_RESULTS,
  payload: { searchResults }
});

export const clearSearchResultsAction = () => ({
  type: CLEAR_SEARCH_RESULTS
});

export const setResultsSummaryAction = summary => ({
  type: SET_RESULTS_SUMMARY,
  payload: { summary }
});

export const changeProfileStatusAction = (uuid, status) => ({
  type: CHANGE_PROFILE_STATUS,
  payload: { uuid, status }
});

export const resetSortingAction = () => ({
  type: RESET_SORTING
});

export const setSortingAction = (field, sorting) => ({
  type: SET_SORTING,
  payload: { field, sorting }
});

export const handleSelectProfileAction = (uuid, history) => ({
  type: SELECT_VIEW_PROFILE,
  payload: {
    uuid,
    history
  }
});
