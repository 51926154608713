import { takeLatest, all, put, select, call } from 'redux-saga/effects';
import { getFormValues, reset, stopSubmit } from 'redux-form/immutable';
import { updateSectionAction, updateMissingInformationAction } from '../../Profile/actions';
import { sleep } from '../../core/utils/index';
import { authorizedRequest } from '../../core/restClient';
import { setUIStateAction } from '../../core/actions/index';
import { SPINNER } from '../../core/constants/index';
import { SUBMIT_FORM_SECTION } from './constants';
import { camelCaseToDash } from '../../core/utils/StringFormatters';
import { LANGUAGES } from '../../Profile/constants';
import { getIdentifier } from '../../Profile/sagas';


function* submitSectionFormData(action) {
  const { payload: { section, flat, data } } = action;
  if (section === LANGUAGES && data.languages.find(elm => !elm.name || elm.name === 'Select a language' ||
    !elm.languageProficiency || elm.languageProficiency === 'Select level of proficiency')) {
    yield put(stopSubmit(LANGUAGES, { name: 'Required' }));
  } else {
    try {
      yield put(setUIStateAction(SPINNER, section));
      yield sleep(1000).then(() => console.log('slept for 1s'));
      const formValues = (yield select(getFormValues(section))).toJS();
      const url = yield getIdentifier(`/profile/me/${camelCaseToDash(section)}`);
      const response = yield call(
        authorizedRequest,
        'PUT',
        url,
        {
          [section]: flat ? formValues : formValues[section]
        }
      );
      yield put(updateMissingInformationAction());
      yield put(updateSectionAction(section, response[section]));
    } catch (e) {
      alert(`Could not update ${section}`);
      yield put(reset(section));
    } finally {
      yield put(setUIStateAction([section, 'edit'], ''));
      yield put(setUIStateAction([section, 'add'], ''));
      yield put(setUIStateAction(SPINNER, ''));
    }
  }
}

export default function* watchSectionFormSubmissionSaga() {
  yield all([
    takeLatest(SUBMIT_FORM_SECTION, submitSectionFormData)
  ]);
}
