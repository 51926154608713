import React from 'react';
import { Link } from 'react-router-dom';
import { LOGIN, REGISTER } from '../routePaths';

const HeaderComponent = () => (
  <React.Fragment>
    <li className="nav-item">
      <Link to={REGISTER} className="nav-link">REGISTER</Link>
    </li>
    <li className="nav-item">
      <Link to={LOGIN} className="nav-link">LOGIN</Link>
    </li>
  </React.Fragment>
);


export default HeaderComponent;
