export const profileStatusRenderer = (statusEnum) => {
  switch (statusEnum) {
    case 'MISSING_INFORMATION':
      return 'Missing information';
    case 'UNDER_REVIEW':
      return 'Under review';
    case 'FULL':
      return 'Full member';
    case 'MIGRATED':
      return 'Migrated';
    case 'IGNORE':
      return 'Active';
    default:
      return 'Migrated no e-mail';
  }
};

export const statusTrafficLightRenderer = (statusEnum) => {
  switch (statusEnum) {
    case 'MISSING_INFORMATION':
      return 'inactive';
    case 'UNDER_REVIEW':
      return 'warning';
    case 'FULL':
      return 'active';
    case 'MIGRATED':
      return 'active';
    default:
      return 'warning';
  }
};
