/* eslint-disable spaced-comment,react/jsx-indent,no-confusing-arrow */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import { searchResultsSelector, searchUIModeSelector } from './selectors';
import { INLINE_FORM, RESULTS } from './constants';
import { adminRoleSelector } from '../Login/selectors';

const mapStateToProps = state => ({
  data: searchResultsSelector(state),
  uiMode: searchUIModeSelector(state),
  adminRole: adminRoleSelector(state),
});

const Search = ({ data, uiMode, adminRole }) => (
  <Fragment>
    <div id="naf-header-search" className="container-fluid naf-bg-header d-flex flex-column justify-content-end">
      <div className="container naf-bg-header-title">
        {uiMode === INLINE_FORM ? <h1>SEARCH</h1> : <h1>SEARCH RESULTS</h1>}
      </div>
    </div>
    {
      uiMode === INLINE_FORM ?
        <main role="main" className="container">
          <div className="row mb-2 justify-content-center">
            <SearchForm adminRole={adminRole} />
          </div>
        </main>
        : <SearchResults data={data} />
    }
  </Fragment>
);

Search.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  adminRole: PropTypes.string,
  uiMode: PropTypes.oneOf([INLINE_FORM, RESULTS])
};

export default connect(mapStateToProps)(Search);
