import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProjectView from './ProjectView';
import { projectUpdateIndexModeUIStateSelector } from '../selectors';
import { EDIT_MODE } from '../constants';
import UpdateExistingProjectForm from './UpdateExistingProjectForm';

const mapStateToProps = (state, { index }) => ({
  inEditMode: projectUpdateIndexModeUIStateSelector(state, index) === EDIT_MODE
});

const Project = props => (props.inEditMode ? <UpdateExistingProjectForm {...props} /> : <ProjectView {...props} />);


Project.propTypes = {
  index: PropTypes.number,
  inEditMode: PropTypes.bool
};

export default connect(mapStateToProps)(Project);
