import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UI } from '../../../../../core/constants/index';
import MoreInformationFieldEdit from './MoreInformationFieldEdit';
import MoreInformationFieldView from './MoreInformationFieldView';

const mapStateToProps = (state, { input: { name }, meta: { form } }) => ({
  inEditMode: state.getIn([UI, form, 'edit']) === name
});

const MoreInformationField = ({
  inEditMode, label, RenderView, RenderEdit, meta, input, fieldConfig
}) => (
  inEditMode
    ? <MoreInformationFieldEdit label={label} RenderEdit={RenderEdit} meta={meta} input={input} fieldConfig={fieldConfig} />
    : <MoreInformationFieldView label={label} RenderView={RenderView} input={input} meta={meta} fieldConfig={fieldConfig} />
);

MoreInformationField.propTypes = {
  label: PropTypes.string,
  RenderEdit: PropTypes.node,
  meta: PropTypes.shape(),
  input: PropTypes.shape(),
  inEditMode: PropTypes.bool,
  RenderView: PropTypes.node,
  fieldConfig: PropTypes.arrayOf(PropTypes.shape())
};

export default connect(mapStateToProps)(MoreInformationField);
