import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form/immutable';
import { ProjectFields } from '../../constants';
import renderTextField from '../formFields/renderTextField';
import renderRoles from '../formFields/renderRoles';
import renderDatePickerField from '../formFields/renderDatePickerField';
import renderDescription from '../formFields/renderDescription';
import renderReferences from '../formFields/renderReferences';
import { isEarlierThan, isLaterThan, required } from '../../validators';

const renderTitle = renderTextField('Title');
const renderLocation = renderTextField('Location');
const renderDateFrom = renderDatePickerField('From');
const renderDateTo = renderDatePickerField('To');

const renderProjects = ({ fields }) => (
  <Fragment>
    {
      fields.map(member => (
        <Fragment>
          <div className="form-row">
            <div className="form-group col-md-8">
              <Field
                id={`${member}.${ProjectFields.TITLE}`}
                name={`${member}.${ProjectFields.TITLE}`}
                component={renderTitle}
                validate={required}
              />
            </div>
            <div className="form-group col-md-4">
              <Field
                id={`${member}.${ProjectFields.ROLE}`}
                name={`${member}.${ProjectFields.ROLE}`}
                component={renderRoles}
                validate={required}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <Field
                id={`${member}.${ProjectFields.LOCATION}`}
                name={`${member}.${ProjectFields.LOCATION}`}
                component={renderLocation}
                validate={required}
              />
            </div>

            <div className="form-group col-md-3">
              <Field
                id={`${member}.${ProjectFields.DATE_FROM}`}
                name={`${member}.${ProjectFields.DATE_FROM}`}
                component={renderDateFrom}
                validate={[required, isEarlierThan(ProjectFields.DATE_TO, member)]}
              />
            </div>

            <div className="form-group col-md-3">
              <Field
                id={`${member}.${ProjectFields.DATE_TO}`}
                name={`${member}.${ProjectFields.DATE_TO}`}
                component={renderDateTo}
                validate={[required, isLaterThan(ProjectFields.DATE_FROM, member)]}
              />
            </div>
          </div>
          <div className="form-group">
            <Field
              id={`${member}.${ProjectFields.DESCRIPTION}`}
              name={`${member}.${ProjectFields.DESCRIPTION}`}
              component={renderDescription}
              validate={required}
            />
          </div>
          <h5>REFERENCES</h5>
          <FieldArray
            name={`${member}.references`}
            component={renderReferences}
            projectMember={member}
          />
        </Fragment>
      ))
    }
  </Fragment>
);

renderProjects.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape)
};

export default renderProjects;
