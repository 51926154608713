import { call } from 'redux-saga/effects';
import { authorizedRequest } from '../../core/restClient';

export function* checkEmailAvailability(email, uuid) {
  try {
    const response = yield call(authorizedRequest, 'GET', `/register/email-availability?email=${email}${uuid ? `&uuid=${uuid}` : ''}`);
    return response.isEmailAvailable;
  } catch (err) {
    return undefined;
  }
}
