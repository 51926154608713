export const languageProficiency = [
  { level: 'BASIC' },
  { level: 'FLUENT' },
  { level: 'NATIVE' }
];

export const languages = [
  { language_id: 1, name: 'English', code: 'alpha2' },
  { language_id: 2, name: 'Afar', code: 'aa' },
  { language_id: 3, name: 'Abkhazian', code: 'ab' },
  { language_id: 4, name: 'Avestan', code: 'ae' },
  { language_id: 5, name: 'Afrikaans', code: 'af' },
  { language_id: 6, name: 'Akan', code: 'ak' },
  { language_id: 7, name: 'Amharic', code: 'am' },
  { language_id: 8, name: 'Aragonese', code: 'an' },
  { language_id: 9, name: 'Arabic', code: 'ar' },
  { language_id: 10, name: 'Assamese', code: 'as' },
  { language_id: 11, name: 'Avaric', code: 'av' },
  { language_id: 12, name: 'Aymara', code: 'ay' },
  { language_id: 13, name: 'Azerbaijani', code: 'az' },
  { language_id: 14, name: 'Bashkir', code: 'ba' },
  { language_id: 15, name: 'Belarusian', code: 'be' },
  { language_id: 16, name: 'Bulgarian', code: 'bg' },
  { language_id: 17, name: 'Bihari languages', code: 'bh' },
  { language_id: 18, name: 'Bislama', code: 'bi' },
  { language_id: 19, name: 'Bambara', code: 'bm' },
  { language_id: 20, name: 'Bengali', code: 'bn' },
  { language_id: 21, name: 'Tibetan', code: 'bo' },
  { language_id: 22, name: 'Breton', code: 'br' },
  { language_id: 23, name: 'Bosnian', code: 'bs' },
  { language_id: 24, name: 'Catalan; Valencian', code: 'ca' },
  { language_id: 25, name: 'Chechen', code: 'ce' },
  { language_id: 26, name: 'Chamorro', code: 'ch' },
  { language_id: 184, name: 'Chinese', code: 'zh' },
  { language_id: 27, name: 'Corsican', code: 'co' },
  { language_id: 28, name: 'Cree', code: 'cr' },
  { language_id: 29, name: 'Czech', code: 'cs' },
  { language_id: 30, name: 'Church Slavic', code: 'cu' },
  { language_id: 31, name: 'Chuvash', code: 'cv' },
  { language_id: 32, name: 'Welsh', code: 'cy' },
  { language_id: 33, name: 'Danish', code: 'da' },
  { language_id: 34, name: 'German', code: 'de' },
  { language_id: 35, name: 'Divehi; Dhivehi; Maldivian', code: 'dv' },
  { language_id: 36, name: 'Dzongkha', code: 'dz' },
  { language_id: 37, name: 'Ewe', code: 'ee' },
  { language_id: 38, name: 'Greek', code: 'el' },
  { language_id: 39, name: 'English', code: 'en' },
  { language_id: 40, name: 'Esperanto', code: 'eo' },
  { language_id: 41, name: 'Spanish; Castilian', code: 'es' },
  { language_id: 42, name: 'Estonian', code: 'et' },
  { language_id: 43, name: 'Basque', code: 'eu' },
  { language_id: 44, name: 'Persian', code: 'fa' },
  { language_id: 45, name: 'Fulah', code: 'ff' },
  { language_id: 46, name: 'Finnish', code: 'fi' },
  { language_id: 47, name: 'Fijian', code: 'fj' },
  { language_id: 48, name: 'Faroese', code: 'fo' },
  { language_id: 49, name: 'French', code: 'fr' },
  { language_id: 50, name: 'Western Frisian', code: 'fy' },
  { language_id: 51, name: 'Irish', code: 'ga' },
  { language_id: 52, name: 'Gaelic; Scottish Gaelic', code: 'gd' },
  { language_id: 53, name: 'Galician', code: 'gl' },
  { language_id: 54, name: 'Guarani', code: 'gn' },
  { language_id: 55, name: 'Gujarati', code: 'gu' },
  { language_id: 56, name: 'Manx', code: 'gv' },
  { language_id: 57, name: 'Hausa', code: 'ha' },
  { language_id: 58, name: 'Hebrew', code: 'he' },
  { language_id: 59, name: 'Hindi', code: 'hi' },
  { language_id: 60, name: 'Hiri Motu', code: 'ho' },
  { language_id: 61, name: 'Croatian', code: 'hr' },
  { language_id: 62, name: 'Haitian; Haitian Creole', code: 'ht' },
  { language_id: 63, name: 'Hungarian', code: 'hu' },
  { language_id: 64, name: 'Armenian', code: 'hy' },
  { language_id: 65, name: 'Herero', code: 'hz' },
  { language_id: 66, name: 'Interlingua (International Auxiliary Language Association)', code: 'ia' },
  { language_id: 67, name: 'Indonesian', code: 'id' },
  { language_id: 68, name: 'Interlingue; Occidental', code: 'ie' },
  { language_id: 69, name: 'Igbo', code: 'ig' },
  { language_id: 70, name: 'Sichuan Yi; Nuosu', code: 'ii' },
  { language_id: 71, name: 'Inupiaq', code: 'ik' },
  { language_id: 72, name: 'Ido', code: 'io' },
  { language_id: 73, name: 'Icelandic', code: 'is' },
  { language_id: 74, name: 'Italian', code: 'it' },
  { language_id: 75, name: 'Inuktitut', code: 'iu' },
  { language_id: 76, name: 'Japanese', code: 'ja' },
  { language_id: 77, name: 'Javanese', code: 'jv' },
  { language_id: 78, name: 'Georgian', code: 'ka' },
  { language_id: 79, name: 'Kongo', code: 'kg' },
  { language_id: 80, name: 'Kikuyu; Gikuyu', code: 'ki' },
  { language_id: 81, name: 'Kuanyama; Kwanyama', code: 'kj' },
  { language_id: 82, name: 'Kazakh', code: 'kk' },
  { language_id: 83, name: 'Kalaallisut; Greenlandic', code: 'kl' },
  { language_id: 84, name: 'Central Khmer', code: 'km' },
  { language_id: 85, name: 'Kannada', code: 'kn' },
  { language_id: 86, name: 'Korean', code: 'ko' },
  { language_id: 87, name: 'Kanuri', code: 'kr' },
  { language_id: 88, name: 'Kashmiri', code: 'ks' },
  { language_id: 89, name: 'Kurdish', code: 'ku' },
  { language_id: 90, name: 'Komi', code: 'kv' },
  { language_id: 91, name: 'Cornish', code: 'kw' },
  { language_id: 92, name: 'Kirghiz; Kyrgyz', code: 'ky' },
  { language_id: 93, name: 'Latin', code: 'la' },
  { language_id: 94, name: 'Luxembourgish; Letzeburgesch', code: 'lb' },
  { language_id: 95, name: 'Ganda', code: 'lg' },
  { language_id: 96, name: 'Limburgan; Limburger; Limburgish', code: 'li' },
  { language_id: 97, name: 'Lingala', code: 'ln' },
  { language_id: 98, name: 'Lao', code: 'lo' },
  { language_id: 99, name: 'Lithuanian', code: 'lt' },
  { language_id: 100, name: 'Luba-Katanga', code: 'lu' },
  { language_id: 101, name: 'Latvian', code: 'lv' },
  { language_id: 102, name: 'Malagasy', code: 'mg' },
  { language_id: 103, name: 'Marshallese', code: 'mh' },
  { language_id: 104, name: 'Maori', code: 'mi' },
  { language_id: 105, name: 'Macedonian', code: 'mk' },
  { language_id: 106, name: 'Malayalam', code: 'ml' },
  { language_id: 107, name: 'Mongolian', code: 'mn' },
  { language_id: 108, name: 'Marathi', code: 'mr' },
  { language_id: 109, name: 'Malay', code: 'ms' },
  { language_id: 110, name: 'Maltese', code: 'mt' },
  { language_id: 111, name: 'Burmese', code: 'my' },
  { language_id: 112, name: 'Nauru', code: 'na' },
  { language_id: 113, name: 'Bokm\u00e5l, Norwegian; Norwegian Bokm\u00e5l', code: 'nb' },
  { language_id: 114, name: 'Ndebele, North; North Ndebele', code: 'nd' },
  { language_id: 115, name: 'Nepali', code: 'ne' },
  { language_id: 116, name: 'Ndonga', code: 'ng' },
  { language_id: 117, name: 'Dutch; Flemish', code: 'nl' },
  { language_id: 118, name: 'Norwegian Nynorsk; Nynorsk, Norwegian', code: 'nn' },
  { language_id: 119, name: 'Norwegian', code: 'no' },
  { language_id: 120, name: 'Ndebele, South; South Ndebele', code: 'nr' },
  { language_id: 121, name: 'Navajo; Navaho', code: 'nv' },
  { language_id: 122, name: 'Chichewa; Chewa; Nyanja', code: 'ny' },
  { language_id: 123, name: 'Occitan (post 1500); Proven\u00e7al', code: 'oc' },
  { language_id: 124, name: 'Ojibwa', code: 'oj' },
  { language_id: 125, name: 'Oromo', code: 'om' },
  { language_id: 126, name: 'Oriya', code: 'or' },
  { language_id: 127, name: 'Ossetian; Ossetic', code: 'os' },
  { language_id: 128, name: 'Panjabi; Punjabi', code: 'pa' },
  { language_id: 129, name: 'Pali', code: 'pi' },
  { language_id: 130, name: 'Polish', code: 'pl' },
  { language_id: 131, name: 'Pushto; Pashto', code: 'ps' },
  { language_id: 132, name: 'Portuguese', code: 'pt' },
  { language_id: 133, name: 'Quechua', code: 'qu' },
  { language_id: 134, name: 'Romansh', code: 'rm' },
  { language_id: 135, name: 'Rundi', code: 'rn' },
  { language_id: 136, name: 'Romanian; Moldavian; Moldovan', code: 'ro' },
  { language_id: 137, name: 'Russian', code: 'ru' },
  { language_id: 138, name: 'Kinyarwanda', code: 'rw' },
  { language_id: 139, name: 'Sanskrit', code: 'sa' },
  { language_id: 140, name: 'Sardinian', code: 'sc' },
  { language_id: 141, name: 'Sindhi', code: 'sd' },
  { language_id: 142, name: 'Northern Sami', code: 'se' },
  { language_id: 143, name: 'Sango', code: 'sg' },
  { language_id: 144, name: 'Sinhala; Sinhalese', code: 'si' },
  { language_id: 145, name: 'Slovak', code: 'sk' },
  { language_id: 146, name: 'Slovenian', code: 'sl' },
  { language_id: 147, name: 'Samoan', code: 'sm' },
  { language_id: 148, name: 'Shona', code: 'sn' },
  { language_id: 149, name: 'Somali', code: 'so' },
  { language_id: 150, name: 'Albanian', code: 'sq' },
  { language_id: 151, name: 'Serbian', code: 'sr' },
  { language_id: 152, name: 'Swati', code: 'ss' },
  { language_id: 153, name: 'Sotho, Southern', code: 'st' },
  { language_id: 154, name: 'Sundanese', code: 'su' },
  { language_id: 155, name: 'Swedish', code: 'sv' },
  { language_id: 156, name: 'Swahili', code: 'sw' },
  { language_id: 157, name: 'Tamil', code: 'ta' },
  { language_id: 158, name: 'Telugu', code: 'te' },
  { language_id: 159, name: 'Tajik', code: 'tg' },
  { language_id: 160, name: 'Thai', code: 'th' },
  { language_id: 161, name: 'Tigrinya', code: 'ti' },
  { language_id: 162, name: 'Turkmen', code: 'tk' },
  { language_id: 163, name: 'Tagalog', code: 'tl' },
  { language_id: 164, name: 'Tswana', code: 'tn' },
  { language_id: 165, name: 'Tonga (Tonga Islands)', code: 'to' },
  { language_id: 166, name: 'Turkish', code: 'tr' },
  { language_id: 167, name: 'Tsonga', code: 'ts' },
  { language_id: 168, name: 'Tatar', code: 'tt' },
  { language_id: 169, name: 'Twi', code: 'tw' },
  { language_id: 170, name: 'Tahitian', code: 'ty' },
  { language_id: 171, name: 'Uighur; Uyghur', code: 'ug' },
  { language_id: 172, name: 'Ukrainian', code: 'uk' },
  { language_id: 173, name: 'Urdu', code: 'ur' },
  { language_id: 174, name: 'Uzbek', code: 'uz' },
  { language_id: 175, name: 'Venda', code: 've' },
  { language_id: 176, name: 'Vietnamese', code: 'vi' },
  { language_id: 177, name: 'Volap\u00fck', code: 'vo' },
  { language_id: 178, name: 'Walloon', code: 'wa' },
  { language_id: 179, name: 'Wolof', code: 'wo' },
  { language_id: 180, name: 'Xhosa', code: 'xh' },
  { language_id: 181, name: 'Yiddish', code: 'yi' },
  { language_id: 182, name: 'Yoruba', code: 'yo' },
  { language_id: 183, name: 'Zhuang; Chuang', code: 'za' },
  { language_id: 185, name: 'Zulu', code: 'zu' }
];

