import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, startSubmit } from 'redux-form/immutable';
import { CONTACT_US_FORM, ContactUsFormFields } from './constants';
import renderTextField from '../core/renderers/renderTextFormField';
import { contactUsValidator } from './utils';
import { submitContactUsFormAction } from './actions';

const renderNameField = renderTextField(ContactUsFormFields.NAME);
const renderEmailField = renderTextField(ContactUsFormFields.EMAIL);
const renderMessageField = renderTextField(ContactUsFormFields.MESSAGE, 'textarea');

const ContactUsForm = ({ handleSubmit, submitting }) => (
  <form onSubmit={handleSubmit((formData, dispatch) => {
    dispatch(startSubmit(CONTACT_US_FORM));
    dispatch(submitContactUsFormAction(formData.toJS()));
  })}
  >
    <Field
      name={ContactUsFormFields.NAME}
      component={renderNameField}
    />
    <Field
      name={ContactUsFormFields.EMAIL}
      component={renderEmailField}
    />
    <Field
      name={ContactUsFormFields.MESSAGE}
      component={renderMessageField}
    />
    <div className="form-group text-center">
      <button
        type="submit"
        className="btn naf-btn-red naf-btn-wide mt-3"
        disabled={submitting}
      >SUBMIT
      </button>
    </div>
  </form>
);

ContactUsForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool
};

export default reduxForm({
  form: CONTACT_US_FORM,
  validate: contactUsValidator,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true
})(ContactUsForm);
