export const CHECK_REGISTRATION_EMAIL_AVAILABILITY = 'check-registration-email-availability';
export const REGISTRATION = 'registration';

export const checkRegistrationEmailAvailabilityAction = email => ({
  type: CHECK_REGISTRATION_EMAIL_AVAILABILITY,
  payload: {
    email
  }
});


export const registrationAction = () => ({
  type: REGISTRATION
});
