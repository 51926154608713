import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import getSymbolFromCurrency from 'currency-symbol-map';
import MoreInformationField from '../MoreInformationFields/ButtonSubmittedField/MoreInformationField';
import Buttons from '../MoreInformationFields/ButtonSubmittedField/Buttons';
import currencyDropDown from '../MoreInformationFields/CurrencyDropDown';
import validations from '../../../../core/utils/validations';

const MonetaryAmountField = ({ input, meta, label }) => (
  <div className="col-3">
    <input className={`form-control mb-2 mr-sm-2 ${meta.error && 'is-invalid'}`} {...input} />
    <label className="mb-2 mr-sm-2" htmlFor="permit-cost-from">{label}</label>
    <div className="invalid-feedback mt-0">{meta.error}</div>
  </div>
);

MonetaryAmountField.propTypes = {
  input: PropTypes.shape(),
  meta: PropTypes.shape(),
  label: PropTypes.string
};

const maxLengthValidator = validations.maxLength(6);

const RenderEdit = ({ input, fieldConfig }) => (
  <div className="form-row mb-3">
    {
      fieldConfig.map((config, index) => (
        <Field
          key={`${input.name}-${config.label}-${config.name}-${index}`}
          validate={[
            validations.onlyNumbers,
            maxLengthValidator
          ]}
          name={`${input.name}.${config.name}`}
          component={MonetaryAmountField}
          id={config.id}
          label={config.label}
        />
      ))
    }

    <div className="col-6">
      <div className="input-group naf-input-group">
        <Field
          parse={value => value.value}
          className="custom-select"
          component={currencyDropDown}
          name={`${input.name}.currency`}
        />
        <div className="input-group-append">
          <Buttons />
        </div>
      </div>
    </div>
  </div>
);

RenderEdit.propTypes = {
  input: PropTypes.shape(),
  fieldConfig: PropTypes.arrayOf(PropTypes.shape())
};

const RenderView = ({ input, fieldConfig }) => {
  const showMessage = input.value && fieldConfig.filter(config => input.value.get(config.name)).length < fieldConfig.length;
  return showMessage ?
    <p><i>Please, fill in the information above...</i></p> :
    <p>
      {
        fieldConfig.map((config, index) => (
          <React.Fragment>
            <strong key={`${input.name}-${config.label}-${config.name}-${index}`} className="mr-2">{config.label}</strong>
            {input.value && `${input.value.get(config.name)} ${getSymbolFromCurrency(input.value.get('currency'))}`}
          </React.Fragment>
        ))
      }
    </p>;
};

RenderView.propTypes = {
  input: PropTypes.shape(),
  fieldConfig: PropTypes.arrayOf(PropTypes.shape())
};

const MonetaryAmount = ({ label, name, fieldConfig }) => (
  <Field
    label={label}
    name={name}
    fieldConfig={fieldConfig}
    RenderView={RenderView}
    RenderEdit={RenderEdit}
    component={MoreInformationField}
  />
);

MonetaryAmount.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  fieldConfig: PropTypes.arrayOf(PropTypes.shape())
};

export default MonetaryAmount;
