import React from 'react';
import { Link } from 'react-router-dom';
import { LOGIN, REGISTER, TERMS } from './routePaths';

const Main = () => (
  <div id="naf-header-register" className="container-fluid naf-bg-header d-flex flex-column justify-content-center">
    <main role="main" className="container d-flex justify-content-center">
      <div className="d-flex flex-column justify-content-between naf-jumbotron">
        <div className="text-center">
          <h1>
            <span>
              THE LARGEST INTERNATIONAL NETWORK OF <br />FILM DIRECTORS, PRODUCERS, FIXERS, LOCATION MANAGERS, CREW AND JOURNALISTS
            </span>
          </h1>
        </div>
        <div className="d-flex justify-content-center mt-5 mb-5">
          <Link to={LOGIN} className="btn naf-btn-red naf-btn-wide mr-4">LOG IN</Link>
          <Link to={REGISTER} className="btn naf-btn-red naf-btn-wide ml-4">REGISTER</Link>
        </div>
        <div className="text-center mt-5">
          <Link to={TERMS} className="naf-terms">Terms &amp; Privacy policy</Link>
        </div>
      </div>
    </main>
  </div>
);

export default Main;
