/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { inlineSearchStyle, SEARCH_FORM, SearchFormFields } from './constants';
import renderRolesSearchableDropDown from './formFields/renderRolesSearchableDropDown';
import renderCountriesSearchableDropDown from './formFields/renderCountriesSearchableDropDown';
import { searchSubmissionHandler } from './handlers/searchSubmissionHandler';

const renderSearchFormRolesSearchableDropDown = renderRolesSearchableDropDown(inlineSearchStyle);
const renderSearchFormCountriesSearchableDropDown = renderCountriesSearchableDropDown(inlineSearchStyle);

const SearchForm = ({ handleSubmit }) => (
  <form
    className="form-inline naf-search-form"
    onSubmit={handleSubmit(searchSubmissionHandler)}
  >
    <label htmlFor="search-role" className="mr-sm-2 form-control-lg">I &apos;m looking for a </label>
    <Field
      id={SearchFormFields.ROLE}
      name={SearchFormFields.ROLE}
      component={renderSearchFormRolesSearchableDropDown}
    />
    <label htmlFor="search-country" className="mr-sm-2 form-control-lg">in </label>
    <Field
      id={SearchFormFields.COUNTRY}
      name={SearchFormFields.COUNTRY}
      component={renderSearchFormCountriesSearchableDropDown}
    />
    <button
      type="submit"
      className="btn btn-danger btn-lg"
      style={{ marginLeft: '30px' }}
    >
      SEARCH
    </button>
  </form>
);

SearchForm.propTypes = {
  handleSubmit: PropTypes.func
};

export default reduxForm({
  form: SEARCH_FORM
})(SearchForm);
