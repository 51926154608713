import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socialMediaSelector } from '../selectors';
import { setUIStateAction } from '../../core/actions';
import { SHOW_SOCIAL_MEDIA_POPUP } from '../constants';
import { availableSocialMedia, renderSocialMessage } from './utils';
import SocialMediaLink from './SocialMediaLink';
import CV from '../CV';

const mapStateToProps = state => ({
  socialMedia: socialMediaSelector(state)
});

const mapDispatchToProps = dispatch => ({
  showSocialMediaPopUp: () => dispatch(setUIStateAction(SHOW_SOCIAL_MEDIA_POPUP, true))
});

const SocialMedia = ({ socialMedia, showSocialMediaPopUp }) => (
  <div className="col-xl-2 naf-profile-sm">
    <div className="">
      <ul className="list-inline naf-sm-list mt-4 mb-0">
        {
          availableSocialMedia
            .filter(sc => socialMedia && socialMedia.toJS()[sc.name])
            .map((classNames, index) =>
              (<SocialMediaLink
                url={socialMedia.toJS()[classNames.name]}
                key={index}
                aClassName={classNames.aClassName}
                spanClassName={classNames.spanClassName}
              />))
        }
      </ul>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-link btn-sm naf-edit-btn"
          onClick={showSocialMediaPopUp}
        >
          { renderSocialMessage(socialMedia && socialMedia.toJS()) }
        </button>
      </div>
      <CV />
    </div>
  </div>
);

SocialMedia.propTypes = {
  socialMedia: PropTypes.shape(),
  showSocialMediaPopUp: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialMedia);
