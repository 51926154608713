import React, { Fragment } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setUIStateAction } from '../../core/actions';
import { EDIT_MODE, GENERAL_INFORMATION, GeneralInformationFields, VIEW_MODE } from '../constants';

const fieldValueRenderer = (fieldValue, fieldName) => {
  if (fieldName === GeneralInformationFields.ROLES && fieldValue) {
    const value = fieldValue.toArray();
    switch (value) {
      case 0:
        return '';
      case 1:
        return value[0];
      default:
        return value.join(' | ');
    }
  }
  if (fieldName === GeneralInformationFields.DAILY_RATE && fieldValue) {
    return fieldValue.get(GeneralInformationFields.AMOUNT) && fieldValue.get(GeneralInformationFields.CURRENCY) ?
      `${parseFloat(Math.round(fieldValue.get(GeneralInformationFields.AMOUNT) * 100) / 100).toFixed(2)} 
      ${getSymbolFromCurrency(fieldValue.get(GeneralInformationFields.CURRENCY))}` :
      '';
  }
  return fieldValue;
};

const mapDispatchToProps = dispatch => ({
  editModeBtnClickHandler: (fieldName) => {
    if (fieldName !== GeneralInformationFields.DAILY_RATE) {
      dispatch(setUIStateAction(`${GENERAL_INFORMATION}-${GeneralInformationFields.DAILY_RATE}`, VIEW_MODE));
    }
    if (fieldName !== GeneralInformationFields.COUNTRY) {
      dispatch(setUIStateAction(`${GENERAL_INFORMATION}-${GeneralInformationFields.COUNTRY}`, VIEW_MODE));
    }
    if (fieldName !== GeneralInformationFields.ROLES) {
      dispatch(setUIStateAction(`${GENERAL_INFORMATION}-${GeneralInformationFields.ROLES}`, VIEW_MODE));
    }
    dispatch(setUIStateAction(`${GENERAL_INFORMATION}-${fieldName}`, EDIT_MODE));
  }
});

const GeneralInformationViewField = (props) => {
  const {
    value,
    fieldName,
    editModeBtnClickHandler
  } = props;
  return (
    <Fragment>
      { fieldValueRenderer(value, fieldName) }
      <button
        type="button"
        className="btn btn-link naf-edit-btn btn-sm naf-hoverable-show"
        onClick={(e) => {
          e.preventDefault();
          editModeBtnClickHandler(fieldName);
        }}
      >
        <span className="naf-icon-pencil" />
      </button>
    </Fragment>
  );
};

GeneralInformationViewField.propTypes = {
  value: PropTypes.string,
  fieldName: PropTypes.string,
  editModeBtnClickHandler: PropTypes.func
};

export default connect(null, mapDispatchToProps)(GeneralInformationViewField);
