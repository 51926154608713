import React from 'react';
import { Field } from 'redux-form/immutable';
import RadioButtonField from './MoreInformationFields/RadionButtonField';

const options = [
  {
    id: 'car-yes',
    value: 'true',
    label: 'Yes'
  },
  {
    id: 'car-no',
    value: 'false',
    label: 'No'
  }
];

const CarField = () => (
  <Field
    parse={(value) => {
      switch (value) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return undefined;
      }
    }}
    label="Do you have a car"
    name="owningCar"
    options={options}
    component={RadioButtonField}
  />
);

export default CarField;
