import React from 'react';
import { Field } from 'redux-form/immutable';
import RadioButtonField from './MoreInformationFields/RadionButtonField';

const options = [
  {
    id: 'permit-cost-1',
    value: '1',
    label: 'Very Easy'
  },
  {
    id: 'permit-cost-2',
    value: '2',
    label: 'Easy'
  },
  {
    id: 'permit-cost-3',
    value: '3',
    label: 'Average'
  },
  {
    id: 'permit-cost-4',
    value: '4',
    label: 'Hard'
  },
  {
    id: 'permit-cost-5',
    value: '5',
    label: 'Very Hard'
  }
];

const EaseOfPermitField = () => (
  <Field
    label="Permit Issuance Degree of difficulty"
    name="easeOfPermit"
    options={options}
    component={RadioButtonField}
  />
);

export default EaseOfPermitField;
