import React from 'react';
import PropTypes from 'prop-types';

const AddNewButton = ({ callBack }) => (
  <div className="text-right">
    <button type="button" className="btn btn-link naf-edit-btn" onClick={() => callBack()}><span className="fa fa-plus" /> ADD NEW</button>
  </div>
);

AddNewButton.propTypes = {
  callBack: PropTypes.func
};

export default AddNewButton;
