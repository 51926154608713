import React from 'react';
import PropTypes from 'prop-types';
import { INTERESTS } from './constants/index';
import nafCardEditableDeletable from '../widgets/ProfileSectionForm/SectionType/FieldArraySection';

const InterestsForm = nafCardEditableDeletable(INTERESTS);

const RenderInterestEdit = ({ input, meta }) => (
  <input
    type="text"
    className={`form-control ${meta.error && meta.touched && !meta.active ? 'is-invalid' : null}`}
    placeholder="New interest..."
    {...input}
  />
);

RenderInterestEdit.propTypes = {
  input: PropTypes.shape(),
  meta: PropTypes.shape()
};

const RenderInterestView = ({ input }) => (
  <span>{input.value}</span>
);

RenderInterestView.propTypes = {
  input: PropTypes.shape()
};

const Interests = ({ initialValues }) => (
  <InterestsForm initialValues={initialValues} inputType="text" RenderFieldView={RenderInterestView} RenderFieldEdit={RenderInterestEdit} hasTwoColumns />
);


Interests.propTypes = {
  initialValues: PropTypes.shape()
};

export default Interests;
