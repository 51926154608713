import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fieldInputPropTypes } from 'redux-form/immutable';
import { DropdownList } from 'react-widgets';
import currencies from '../constants/currencies';
import { generalInformationCurrencySelector } from '../selectors';

const mapStateToProps = state => ({
  defaultValue: generalInformationCurrencySelector(state)
});


const GeneralInfoCurrencyDropdown = ({ input, defaultValue }) => (
  <DropdownList
    defaultValue={defaultValue}
    value={input.value.value}
    onChange={curr => input.onChange(curr.value)}
    id="edit-exp-role-select"
    filter="contains"
    searchIcon={<span className="naf-icon-pencil" />}
    data={currencies}
    textField="label"
    valueField="value"
    placeholder="Please select currency"
  />
);

GeneralInfoCurrencyDropdown.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes),
  defaultValue: PropTypes.string
};

export default connect(mapStateToProps)(GeneralInfoCurrencyDropdown);
