import { fromJS, Map } from 'immutable';
import {
  CLEAR_SEARCH_RESULTS, RESET_SORTING,
  SET_COUNTRY_FILTER,
  SET_RESULTS_SUMMARY,
  SET_ROLE_FILTER,
  SET_SEARCH_RESULTS, SET_SORTING
} from '../actions';
import { COUNTRY, FILTERS, RESULTS, ROLE, SORTING, SUMMARY } from '../constants';

export default (searchState = Map(), action) => {
  if (!action) {
    return searchState;
  }
  switch (action.type) {
    case SET_SEARCH_RESULTS:
      return searchState
        .set(RESULTS, fromJS(action.payload.searchResults));
    case CLEAR_SEARCH_RESULTS:
      return Map();
    case SET_RESULTS_SUMMARY:
      return searchState
        .set(SUMMARY, fromJS(action.payload.summary));
    case SET_ROLE_FILTER:
      return searchState
        .setIn([FILTERS, ROLE], fromJS(action.payload.role));
    case SET_COUNTRY_FILTER:
      return searchState
        .setIn([FILTERS, COUNTRY], fromJS(action.payload.country));
    case SET_SORTING:
      return searchState
        .setIn([SORTING, action.payload.field], fromJS(action.payload.sorting));
    case RESET_SORTING:
      return searchState
        .set(SORTING, Map());
    default:
      return searchState;
  }
};
