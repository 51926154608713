import React from 'react';
import { DropdownList } from 'react-widgets';
import roles from '../../core/constants/roles';

const renderRolesSearchableDropDown = className => field => (
  <DropdownList
    id="search-role"
    containerClassName={className}
    filter="contains"
    searchIcon={<span className="naf-icon-pencil" />}
    data={roles}
    value={field.input.value}
    onChange={field.input.onChange}
    placeholder="All"
  />
);

export default renderRolesSearchableDropDown;
