import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fieldInputPropTypes } from 'redux-form/immutable';
import { DropdownList } from 'react-widgets';
import roles from '../../core/constants/roles';
import { FILTERS, filtersSearchStyle, ROLE, SEARCH } from '../constants';
import { setUIStateAction } from '../../core/actions';
import { VIEW } from '../../core/constants';

const mapDispatchToProps = dispatch => ({
  handleChange: () => dispatch(setUIStateAction([SEARCH, FILTERS, ROLE], VIEW))
});


const RolesFilterSearchableDropDown = ({ input, handleChange }) => (
  <Fragment>
    <DropdownList
      id="search-country"
      containerClassName={filtersSearchStyle}
      filter="contains"
      searchIcon={<span className="naf-icon-pencil" />}
      data={roles}
      valueField="value"
      textField="label"
      value={input.value}
      onChange={(role) => {
        input.onChange(role);
        handleChange();
      }}
      placeholder="Role"
    />
  </Fragment>
);

RolesFilterSearchableDropDown.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes),
  handleChange: PropTypes.func
};

export default connect(null, mapDispatchToProps)(RolesFilterSearchableDropDown);
