import { SUCCESS } from '../../core/constants';

export const modalMessageRenderer = (result, email) => {
  if (result === SUCCESS) {
    return `Success! A verification email has been sent to ${email}`;
  }
  return 'Failed to register.';
};

export const renderModalTitle = (result) => {
  if (result === SUCCESS) {
    return 'Successful Registration';
  }
  return 'Unsuccessful Registration';
};

export const REGISTRATION_STEP_ONE = 'registrationStepOne';
export const REGISTRATION_STEP_TWO = 'registrationStepTwo';
export const REGISTRATION_STEP = 'registrationStep';
export const REGISTRATION_RESULT = 'registrationResult';
export const REGISTRATION_RESULT_MODAL_VISIBLE = 'showRegistrationResultModal';
export const REGISTRATION_BUTTON_DISABLED = 'registrationBtnDisabled';
export const MIN_PASSWORD_LENGTH = 6;
export const CONFIRM_EMAIL_MODAL_STATE = 'confirmEmail';
export const CONFIRM_ACCOUNT_EMAIL_UPDATE = 'confirmAccountEmailUpdate';

export const RegistrationFormFields = {
  firstStep: {
    EMAIL: 'email',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    PHONE: 'phone',
    COUNTRY: 'country',
    CITY: 'city'
  },
  secondStep: {
    PASSWORD: 'password',
    PASSWORD_CONFIRM: 'password-confirm',
    RECAPTCHA: 'recaptcha',
    TERMS: 'terms'
  }
};
