import React from 'react';
import PropTypes from 'prop-types';

const NafCard = ({
  outerClassName, title, children
}) => (
  <div className={outerClassName}>
    <div className="card-body">
      <h4 className="card-title"><span>{title.toUpperCase()}</span></h4>
      {
        children
      }
    </div>
  </div>
);

NafCard.propTypes = {
  outerClassName: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node
};

export default NafCard;
