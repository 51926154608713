import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setUIStateAction } from '../core/actions';
import { DANGER, SUCCESS, WARNING } from '../core/constants';

const mapDispatchToProps = (dispatch, { uiStateElement }) => ({
  close: () => dispatch(setUIStateAction(uiStateElement, false))
});

const areValidBullets = bullets => bullets && Array.isArray(bullets) && bullets.length > 0;

const renderBullets = dataArr => (
  <ul className="list-inline naf-list-bullets">
    {
      dataArr.map(bulletTxt => <li key={bulletTxt} className="list-inline-item">{ bulletTxt }</li>)
    }
  </ul>
);

const renderText = text => (
  <p>{ text }</p>
);

const NafDismissibleBox = (props) => {
  const {
    close,
    title,
    bullets,
    type,
    text
  } = props;
  return (
    <div className={`alert alert-${type} alert-dismissible`}>
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={close}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <h3>{ title }</h3>
      {
        areValidBullets(bullets) && renderBullets(bullets)
      }
      {
        text && renderText(text)
      }
    </div>
  );
};

NafDismissibleBox.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([DANGER, SUCCESS, WARNING]).isRequired,
  bullets: PropTypes.arrayOf(PropTypes.string).isRequired,
  text: PropTypes.string,
  close: PropTypes.func
};

export default connect(null, mapDispatchToProps)(NafDismissibleBox);
