import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { handleSelectProfileAction } from '../Profile/actions';

const mapDispatchToProps = (dispatch, { history }) => ({
  initializeProfileData: ({ uuid }) => dispatch(handleSelectProfileAction(uuid, history))
});

class DirectProfileLink extends React.Component {
  componentWillMount() {
    this.props.initializeProfileData(parse(this.props.location.search));
  }
  render() {
    return <span>Please wait...</span>;
  }
}

DirectProfileLink.propTypes = {
  initializeProfileData: PropTypes.func,
  location: PropTypes.shape()
};

export default withRouter(connect(null, mapDispatchToProps)(DirectProfileLink));
