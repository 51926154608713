const required = value => (value ? undefined : 'Required');
const maxLength = max => value =>
  (value && value.length > max ? `Must be ${max} characters or less` : undefined);
const minLength = max => value =>
  (value && value.length < max ? `Must be at least ${max} characters long` : undefined);
const email = value =>
  (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i.test(value) ?
    'Invalid email address' : undefined);

const onlyLetters = value =>
  (value && !/^[a-zA-Z\s]*$/g.test(value) ?
    'Field can only contain letters' : undefined);

const onlyNumbers = value =>
  (value && !/^-?\d*\.?\d*$/g.test(value) ?
    'Numbers only!' : undefined);

const onlyAlphanumeric = fieldName => value =>
  (value && !/^[a-z0-9\s]*$/i.test(value) ?
    `${fieldName} can only contain numbers and letters` : undefined);

const notNeedAFixerEmail = value => (value.toLowerCase().includes('needafixer.com') ?
  'You can not use your NEEDaFIXER email address to create a NEEDaFIXER Community account. Please add a different email account and try again.' :
  undefined
);

const jobNumberPattern = value =>
  (value && !/^[0-9]{2}-[a-zA-Z]*[0-9]{2,5}/i.test(value) ?
    'Job Number must be in this format: ##-#####' : undefined);

export default {
  required,
  maxLength,
  minLength,
  email,
  onlyLetters,
  onlyNumbers,
  onlyAlphanumeric,
  notNeedAFixerEmail,
  jobNumberPattern
};
