import { shape } from 'prop-types';
import { withRouter } from 'react-router';
import React from 'react';
import Layout from '../Layout/Layout';

let globalHistory;

const App = ({ history }) => {
  globalHistory = history;
  return (
    <Layout />
  );
};

App.propTypes = {
  history: shape()
};

export const getGlobalHistory = () => globalHistory;

export default withRouter(App);
