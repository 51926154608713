export const GET_PROFILE = 'get-profile';
export const SET_PROFILE = 'set-profile';
export const SET_MISSING_INFORMATION = 'set-missing-information';
export const UPDATE_MISSING_INFORMATION = 'update-missing-information';
export const SET_GENERAL_INFORMATION = 'set-general-information';
export const SET_PROJECTS = 'set-projects';
export const SET_SOCIAL_MEDIA = 'SET_SOCIAL_MEDIA';
export const UPDATE_GENERAL_INFORMATION = 'update-general-information';
export const INIT_GENERAL_INFO_UI = 'init-general-info-ui';
export const SUBMIT_BIO_FORM = 'SUBMIT_BIO_FORM';
export const SUBMIT_INTERESTS_FORM = 'SUBMIT_INTERESTS_FORM';
export const SUBMIT_SKILLS_FORM = 'SUBMIT_SKILLS_FORM';
export const UPDATE_BIO = 'UPDATE_BIO';
export const REMOVE_FIELD_THEN_SUBMIT = 'REMOVE_FIELD_THEN_SUBMIT';
export const UPDATE_SECTION = 'UPDATE_SECTION';
export const SUBMIT_FILE_UPLOAD = 'SUBMIT_FILE_UPLOAD';
export const SUBMIT_INVOICE_UPLOAD = 'SUBMIT_INVOICE_UPLOAD';
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const DOWNLOAD_ATTACHMENT = 'DOWNLOAD_ATTACHMENT';
export const UPLOAD_AVATAR = 'UPLOAD_AVATAR';
export const ADD_NEW_PROJECT = 'ADD_NEW_PROJECT';
export const UPDATE_EXISTING_PROJECT = 'UPDATE_EXISTING_PROJECT';
export const UPDATE_SOCIAL_MEDIA = 'UPDATE_SOCIAL_MEDIA';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const UPDATE_ACCOUNT_EMAIL = 'UPDATE_ACCOUNT_EMAIL';
export const UPDATE_ACCOUNT_PASSWORD = 'UPDATE_ACCOUNT_PASSWORD';
export const SET_ACCOUNT_EMAIL = 'SET_ACCOUNT_EMAIL';
export const UPLOAD_CV = 'UPLOAD_CV';
export const DOWNLOAD_CV = 'DOWNLOAD_CV';
export const DOWNLOAD_PO_DOCUMENT = 'DOWNLOAD_PO_DOCUMENT';
export const SELECT_VIEW_PROFILE = 'SELECT_VIEW_PROFILE';
export const SUBMIT_NOTES_FORM = 'SUBMIT_NOTES_FORM';
export const UPDATE_NOTES = 'UPDATE_NOTES';

export const updateNotesAction = notes => ({
  type: UPDATE_NOTES,
  payload: { notes }
});

export const handleSelectProfileAction = (uuid, history) => ({
  type: SELECT_VIEW_PROFILE,
  payload: {
    uuid,
    history
  }
});

export const submitNotesFormAction = notes => ({
  type: SUBMIT_NOTES_FORM,
  payload: notes
});

export const getProfileAction = uuid => ({
  type: GET_PROFILE,
  payload: { uuid }
});

export const updateMissingInformationAction = () => ({
  type: UPDATE_MISSING_INFORMATION
});

export const setProfileAction = profile => ({
  type: SET_PROFILE,
  payload: { profile }
});

export const setMissingInformationAction = missingInformation => ({
  type: SET_MISSING_INFORMATION,
  payload: { missingInformation }
});

export const setAccountEmail = email => ({
  type: SET_ACCOUNT_EMAIL,
  payload: { email }
});

export const setGeneralInformationAction = generalInformation => ({
  type: SET_GENERAL_INFORMATION,
  payload: { generalInformation }
});

export const setSocialMediaAction = socialMedia => ({
  type: SET_SOCIAL_MEDIA,
  payload: { socialMedia }
});

export const setProjects = projects => ({
  type: SET_PROJECTS,
  payload: { projects }
});

export const updateSectionAction = (section, data) => ({
  type: UPDATE_SECTION,
  payload: {
    section,
    data
  }
});

export const updateGeneralInformationAction = data => ({
  type: UPDATE_GENERAL_INFORMATION,
  payload: { data }
});

export const updateSocialMediaAction = data => ({
  type: UPDATE_SOCIAL_MEDIA,
  payload: { data }
});

export const addNewProjectAction = data => ({
  type: ADD_NEW_PROJECT,
  payload: { data }
});

export const updateExistingProjectAction = (data, index) => ({
  type: UPDATE_EXISTING_PROJECT,
  payload: { data, index }
});

export const deleteProjectAction = () => ({
  type: DELETE_PROJECT
});

export const initGeneralInfoUIStateAction = () => ({
  type: INIT_GENERAL_INFO_UI
});

export const submitBioFormAction = values => ({
  type: SUBMIT_BIO_FORM,
  payload: values
});

export const updateBioAction = bio => ({
  type: UPDATE_BIO,
  payload: { bio }
});

export const removeFieldThenSubmitFormAction = (form, field, index) => ({
  type: REMOVE_FIELD_THEN_SUBMIT,
  payload: {
    form,
    field,
    index
  }
});

export const submitFileUploadFormAction = values => ({
  type: SUBMIT_FILE_UPLOAD,
  payload: values
});

export const submitInvoiceUploadFormAction = values => ({
  type: SUBMIT_INVOICE_UPLOAD,
  payload: values
});

export const submitAvatarUploadAction = values => ({
  type: UPLOAD_AVATAR,
  payload: values
});

export const submitCvUploadAction = values => ({
  type: UPLOAD_CV,
  payload: values
});


export const deleteAttachmentAction = fileId => ({
  type: DELETE_ATTACHMENT,
  payload: fileId
});

export const deleteInvoiceAction = invoiceId => ({
  type: DELETE_INVOICE,
  payload: invoiceId
});

export const downloadAttachmentAction = (fileId, filename, contentType) => ({
  type: DOWNLOAD_ATTACHMENT,
  payload: {
    fileId,
    filename,
    contentType
  }
});

export const downloadPoDocumentAction = (invoiceRequestId, filename, contentType) => ({
  type: DOWNLOAD_PO_DOCUMENT,
  payload: {
    invoiceRequestId,
    filename,
    contentType
  }
});

export const downloadCvAction = (fileId, filename, contentType) => ({
  type: DOWNLOAD_CV,
  payload: {
    fileId,
    filename,
    contentType
  }
});

export const updateAccountEmailAction = email => ({
  type: UPDATE_ACCOUNT_EMAIL,
  payload: { email }
});

export const updateAccountPasswordAction = (password, newPassword) => ({
  type: UPDATE_ACCOUNT_PASSWORD,
  payload: { password, newPassword }
});
