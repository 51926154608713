import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generalInformationSelector, accountUUIDSelector } from '../selectors';
import GeneralInformationForm from './GeneralInformationForm';
import ProfilePicture from './ProfilePicture/ProfilePicture';

const mapStateToProps = state => ({
  initialValues: generalInformationSelector(state),
  uuid: accountUUIDSelector(state)
});

const GeneralInformationLayout = props => (
  <div className="row mb-2">
    <ProfilePicture />
    <GeneralInformationForm {...props} />
  </div>
);

GeneralInformationLayout.propTypes = {
  initialValues: PropTypes.instanceOf(Immutable.Map)
};

export default connect(mapStateToProps)(GeneralInformationLayout);
