import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { arrayPush, reset, submit } from 'redux-form/immutable';
import { Map } from 'immutable';
import ProjectForm from './ProjectForm';
import { ADD_NEW_PROJECT_FORM, EDIT_MODE, HIDE_ADD_PRJ_BTN, SHOW_SUBMIT_CANCEL_PRJ_BTN, VIEW_MODE } from '../constants';
import { addNewPrjBtnUISelector, addNewPrjFormUISelector, showSubmitCancelNewPrjBtnUISelector } from '../selectors';
import { ADD, MODE, PROJECTS } from '../../core/constants';
import { setUIStateAction } from '../../core/actions';
import { addNewProjectAction } from '../actions';

const mapStateToProps = state => ({
  shouldHideAddNewBtn: addNewPrjBtnUISelector(state) === true,
  shouldRenderAddNewForm: addNewPrjFormUISelector(state) === EDIT_MODE,
  shouldShowSubmitCancelBtns: showSubmitCancelNewPrjBtnUISelector(state) === true
});

const mapDispatchToProps = dispatch => ({
  handleAddNewProject: () => {
    dispatch(arrayPush(ADD_NEW_PROJECT_FORM, PROJECTS, Map({})));
    dispatch(setUIStateAction([PROJECTS, ADD, MODE], EDIT_MODE));
    dispatch(setUIStateAction([PROJECTS, ADD, HIDE_ADD_PRJ_BTN], true));
    dispatch(setUIStateAction([PROJECTS, ADD, SHOW_SUBMIT_CANCEL_PRJ_BTN], true));
  },
  handleSubmitNewPrj: () => dispatch(submit(ADD_NEW_PROJECT_FORM)),
  handleCancelNewPrj: () => {
    dispatch(reset(ADD_NEW_PROJECT_FORM));
    dispatch(setUIStateAction([PROJECTS, ADD, MODE], VIEW_MODE));
    dispatch(setUIStateAction([PROJECTS, ADD, HIDE_ADD_PRJ_BTN], false));
    dispatch(setUIStateAction([PROJECTS, ADD, SHOW_SUBMIT_CANCEL_PRJ_BTN], false));
  },
});

const AddNewProjectForm = (props) => {
  const {
    handleAddNewProject,
    shouldHideAddNewBtn,
    shouldRenderAddNewForm,
    shouldShowSubmitCancelBtns,
    handleSubmitNewPrj,
    handleCancelNewPrj
  } = props;
  return (
    <Fragment>
      { shouldRenderAddNewForm &&
      <ProjectForm
        form={ADD_NEW_PROJECT_FORM}
        onSubmit={(formValues, dispatch) => dispatch(addNewProjectAction(formValues))}
      /> }
      <div className="row">
        <div className="card naf-card col-md-12">
          <div className="card-body">
            <div className="card naf-experience-item mb-4">
              <div className="d-flex justify-content-between flex-md-row flex-column">
                {
                  !shouldHideAddNewBtn && (
                    <button
                      type="button"
                      className="btn btn-link naf-edit-btn"
                      onClick={handleAddNewProject}
                    >
                      <span className="fa fa-plus" />
                      ADD NEW PROJECT
                    </button>
                  )
                }
                {
                  shouldShowSubmitCancelBtns && (
                    <div className="d-flex justify-content-center justify-content-md-end naf-input-group">
                      {/* <div className="naf-error-msg mr-2">Error message here</div> */ }
                      <button
                        className="btn btn-outline-success naf-icon-btn"
                        type="button"
                        onClick={handleSubmitNewPrj}
                      >
                        <span className="naf-icon-check" />
                      </button>
                      <button
                        className="btn btn-outline-danger naf-icon-btn mr-3"
                        type="button"
                        onClick={handleCancelNewPrj}
                      >
                        <span className="naf-icon-times" />
                      </button>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AddNewProjectForm.propTypes = {
  handleAddNewProject: PropTypes.func,
  handleSubmitNewPrj: PropTypes.func,
  handleCancelNewPrj: PropTypes.func,
  shouldHideAddNewBtn: PropTypes.bool,
  shouldRenderAddNewForm: PropTypes.bool,
  shouldShowSubmitCancelBtns: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewProjectForm);
