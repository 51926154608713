import React from 'react';
import MonetaryAmount from './MoreInformationFields/MonetaryAmount';

const fieldConfig = [
  {
    name: 'costFrom',
    id: 'permit-cost-from',
    label: 'From'
  },
  {
    name: 'costTo',
    id: 'permit-cost-to',
    label: 'To'
  }
];

const PermitCostField = () => (
  <MonetaryAmount
    label="PERMIT COSTS IN THE COUNTRY YOU OPERATE"
    name="permitCost"
    fieldConfig={fieldConfig}
  />
);

export default PermitCostField;
