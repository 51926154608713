import { UI } from '../../core/constants';
import {
  REQUEST_RESET_PASSWORD_BTN_DISABLED,
  REQUEST_RESET_PASSWORD_RESULT,
  REQUEST_RESET_PASSWORD_RESULT_MODAL_VISIBLE
} from '../constants';

export const showRequestResetPasswordResultModalSelector = state =>
  state.getIn([UI, REQUEST_RESET_PASSWORD_RESULT_MODAL_VISIBLE]);
export const requestResetPasswordResultSelector = state => state.getIn([UI, REQUEST_RESET_PASSWORD_RESULT]);
export const isRequestResetPasswordBtnDisabledSelector = state => state.getIn([UI, REQUEST_RESET_PASSWORD_BTN_DISABLED]);
