/* eslint-disable jsx-a11y/label-has-for,jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FilterBar from './FilterBar';
import ProfileStatusCategories from './ProfileStatusCategories';
import ResultsViewButtons from './ResultsViewButtons';
import ResultsView from './ResultsView';
import SortingControls from './SortingControls';
import Pagination from './Pagination';
import SearchControls from './SearchControls';
import { CARDS, LIST, ProfileStatus, SEARCH } from './constants';
import {
  cityFilterValueSelector,
  countryFilterValueSelector,
  dailyRateFromFilterValueSelector,
  dailyRateToFilterValueSelector,
  nameFilterValueSelector,
  profileStatusCategorySelector,
  resultsViewModeSelector,
  roleFilterValueSelector
} from './selectors';
import Spinner from '../widgets/Spinner';

const mapStateToProps = state => ({
  selectedProfileStatusCategory: profileStatusCategorySelector(state),
  role: roleFilterValueSelector(state),
  country: countryFilterValueSelector(state),
  city: cityFilterValueSelector(state),
  dailyRateFrom: dailyRateFromFilterValueSelector(state),
  dailyRateTo: dailyRateToFilterValueSelector(state),
  name: nameFilterValueSelector(state),
  resultsViewMode: resultsViewModeSelector(state)
});

const SearchResults = (props) => {
  const {
    data,
    selectedProfileStatusCategory,
    role,
    country,
    city,
    dailyRateFrom,
    dailyRateTo,
    name,
    resultsViewMode
  } = props;
  return (
    <main role="main" className="container">
      <Spinner
        element={SEARCH}
      />
      <div className="row justify-content-between naf-filter-bar mb-3 flex-column flex-md-row">
        <FilterBar role={role} country={country} city={city} dailyRateFrom={dailyRateFrom} dailyRateTo={dailyRateTo} name={name} />
        <SearchControls />
      </div>
      <ProfileStatusCategories
        selectedProfileStatusCategory={selectedProfileStatusCategory}
        role={role}
        country={country}
        city={city}
        dailyRateFrom={dailyRateFrom}
        dailyRateTo={dailyRateTo}
        name={name}
      />
      {
        data && data.size > 0 &&
        <div className="row mb-3 d-flex justify-content-between">
          <SortingControls />
          <ResultsViewButtons resultsViewMode={resultsViewMode} />
        </div>
      }
      <ResultsView resultsViewMode={resultsViewMode} data={data} />
      {
        data && data.size > 0 && <Pagination role={role} country={country} city={city} dailyRateFrom={dailyRateFrom} dailyRateTo={dailyRateTo} name={name} />
      }
    </main>
  );
};

SearchResults.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  selectedProfileStatusCategory: PropTypes.oneOf([
    ProfileStatus.FULL,
    ProfileStatus.MIGRATED,
    ProfileStatus.MISSING_INFORMATION,
    ProfileStatus.UNDER_REVIEW,
  ]),
  role: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
  name: PropTypes.string,
  dailyRateFrom: PropTypes.number,
  dailyRateTo: PropTypes.number,
  resultsViewMode: PropTypes.oneOf([LIST, CARDS])
};

export default connect(mapStateToProps)(SearchResults);
