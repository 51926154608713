import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MAIN } from './routePaths';

const FourOFourPage = ({ history }) => (
  <div className="container-fluid naf-error-page d-flex flex-column justify-content-center">
    <main role="main" className="container d-flex justify-content-center">
      <div className="d-flex flex-column justify-content-between naf-jumbotron align-items-center text-center">
        <div id="naf-cat-404" className="mb-5" />
        <p className="naf-error-title">The Page You Requested Could Not Be Found</p>
        <p>
          <button
            onClick={() => history.push(MAIN)}
            className="btn naf-btn-purple-round naf-btn-wide"
          >
            HOME
          </button>
        </p>
      </div>
    </main>
  </div>
);

FourOFourPage.propTypes = {
  history: PropTypes.shape()
};

export default withRouter(FourOFourPage);
