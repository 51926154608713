import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NafUploadFileModal from '../../widgets/UploadFileWidget/NafUploadFileModal';
import { downloadCvAction, submitCvUploadAction } from '../actions';
import { setUIStateAction } from '../../core/actions';
import { UPLOAD_CV_MODAL } from '../constants';
import { cvSelector } from '../selectors';

const mapDispatchToProps = dispatch => ({
  openUploadFileModal: () => dispatch(setUIStateAction(UPLOAD_CV_MODAL, true)),
  handleDownload: (fileId, filename, contentType) => dispatch(downloadCvAction(fileId, filename, contentType))
});

const mapStateToProps = state => ({
  cv: cvSelector(state) && cvSelector(state).toJS()
});

const CV = ({ openUploadFileModal, handleDownload, cv }) => (
  <div className="text-center naf-border-top mb-4">
    <div className="text-right">
      <button type="button" className="btn btn-link naf-edit-btn" onClick={openUploadFileModal}>EDIT</button>
    </div>
    CV {
        cv ?
          <a href="#" onClick={() => handleDownload(cv.fileId, cv.filename, cv.contentType)} className="naf-icon-social naf-icon-social--pdf"><span className="fa fa-fw fa-file-pdf-o" /></a>
          :
          <a className="naf-icon-social naf-icon-social--pdf"><span className="fa fa-fw fa-file-pdf-o" /></a>
    }

    <NafUploadFileModal
      title="Upload CV"
      action={submitCvUploadAction}
      stateIdentifier={UPLOAD_CV_MODAL}
    />
  </div>
);

CV.propTypes = {
  openUploadFileModal: PropTypes.func,
  cv: PropTypes.shape({
    fileId: PropTypes.string,
    filename: PropTypes.string,
    contentType: PropTypes.string
  }),
  handleDownload: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(CV);
