import download from 'downloadjs';
import { hashHistory } from 'react-router';
import authTokenHandler from './auth/authTokenHandler';

const JSON_FORMAT = 'application/json';
const AUTH_HEADER = 'Authorization';

export const createErrorString = errorHolder => (
  errorHolder.errors.reduce(
    (errorString, error) =>
      `${errorString} ${error.description}(${error.code})`,
    ''
  )
);

export function* updateEmail(email, accountUUID) {
  const requestParams = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      [AUTH_HEADER]: authTokenHandler.getAuthToken()
    },
    method: 'PUT',
    body: JSON.stringify({ email })
  };
  return yield fetch(`/api/accounts/${accountUUID}/email`, requestParams).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  });
}

export function* authorizedRequest(method, url, body) {
  const Authorization = authTokenHandler.getAuthToken();
  const contentHeaders = {
    Accept: JSON_FORMAT,
    'Content-Type': JSON_FORMAT
  };
  const headers = Authorization ? Object.assign({}, contentHeaders, { Authorization }) : contentHeaders;
  const requestParams = { headers, method };
  if (body) {
    requestParams.body = JSON.stringify(body);
  }
  return yield fetch(`/api${url}`, requestParams).then((httpResponse) => {
    if (httpResponse.ok) {
      return httpResponse.json();
    } else if (httpResponse.status === 401) {
      // Unauthorized
      hashHistory.push('/login');
      // Response error
      throw new Error('Unauthorized');
    }
    // Response error
    throw new Error(`Could not perform request (${httpResponse.statusText})`);
  }).then((responseJson) => {
    if (responseJson.hasError) {
      throw new Error(createErrorString(responseJson));
    }
    return responseJson;
  });
}

export function* unauthorizedRequest(method, url, body) {
  const headers = {
    Accept: JSON_FORMAT,
    'Content-Type': JSON_FORMAT
  };
  const requestParams = { headers, method };
  if (body) {
    requestParams.body = JSON.stringify(body);
  }
  return yield fetch(`/api${url}`, requestParams).then((httpResponse) => {
    if (httpResponse.ok) {
      // eslint-disable-next-line no-confusing-arrow
      return httpResponse.text().then(textResp => textResp ? JSON.parse(textResp) : {});
    }
    // Response error
    throw new Error(`Could not perform request (${httpResponse.statusText})`);
  }).then((responseJson) => {
    if (responseJson.hasError) {
      throw new Error(createErrorString(responseJson));
    }
    return responseJson;
  });
}

export function* multipartFormDataRequest(url, body, method = 'PUT') {
  const Authorization = authTokenHandler.getAuthToken();
  const headers = {
    Authorization
  };
  const requestParams = { headers, method, body };

  return yield fetch(`/api${url}`, requestParams).then((httpResponse) => {
    if (httpResponse.ok) {
      return httpResponse.json();
    } else if (httpResponse.status === 401) {
      // Unauthorized
      hashHistory.push('/login');
      // Response error
      throw new Error('Unauthorized');
    }
    return httpResponse.json();
  }).then((responseJson) => {
    if (responseJson.errors) {
      throw new Error(responseJson.errors);
    }
    return responseJson;
  });
}

export function* downloadFile({ filename, contentType }, url) {
  const Authorization = authTokenHandler.getAuthToken();
  const contentHeaders = {
    Accept: JSON_FORMAT,
    'Content-Type': JSON_FORMAT
  };
  const headers = Authorization ? { ...contentHeaders, Authorization } : contentHeaders;

  try {
    const httpResponse = yield fetch(url, {
      method: 'GET',
      headers
    });

    if (!httpResponse.ok) {
      // You can customize or localize your error message
      throw new Error(`Error while downloading file: ${httpResponse.status} ${httpResponse.statusText}`);
    }

    const blob = yield httpResponse.blob();
    download(blob, filename, contentType);
  } catch (error) {
    throw new Error('Error while downloading file');
  }
}
