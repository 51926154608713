import React from 'react';
import { Link } from 'react-router-dom';
import { TERMS } from '../../Layout/routePaths';

const renderTermsCheckbox = field => (
  <div className="form-group">
    <div className="custom-control custom-checkbox">
      <input {...field.input} type="checkbox" className="custom-control-input" id="reg-agree" />
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label className="custom-control-label" htmlFor="reg-agree">
        I agree to NAF&apos;s
        <Link to={TERMS}>Terms & Privacy policy</Link>
      </label>
    </div>
    {field.meta && field.meta.error && field.meta.touched &&
    <span style={{ marginTop: '20px' }} className="req">{field.meta.error}</span>}
  </div>
);

export default renderTermsCheckbox;
