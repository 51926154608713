export const GeneralInformationFields = {
  NAME: 'name',
  ROLES: 'roles',
  AMOUNT: 'amount',
  CURRENCY: 'currency',
  DAILY_RATE: 'dailyRate',
  ADDRESS: 'address',
  CITY: 'city',
  COUNTRY: 'country',
  EMAIL: 'email',
  PHONE: 'phone',
  SKYPE: 'skype',
  WHATSAPP: 'whatsapp',
  VIBER: 'viber',
  SECONDARY_PHONE: 'secondaryPhone'
};

export const ProjectFields = {
  TITLE: 'title',
  ROLE: 'role',
  LOCATION: 'location',
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
  DESCRIPTION: 'description',
  REFERENCES: 'references'
};

export const MAX_ROLES_SIZE = 3;
export const VIEW_MODE = 'view';
export const EDIT_MODE = 'edit';
export const HIDE_MODE = 'hide';
export const GENERAL_INFORMATION = 'generalInformation';
export const MISSING_INFORMATION = 'missingInformation';
export const IS_UNDER_REVIEW = 'isUnderReview';
export const ADD_NEW_PROJECT_FORM = 'addNewProject';
export const UPDATE_EXISTING_PROJECT_FORM = 'updateExistingProject';
export const UPDATE_ACCOUNT_EMAIL_FORM = 'updateAccountEmail';
export const UPDATE_ACCOUNT_PASSWORD_FORM = 'updateAccountPassword';
export const SOCIAL_MEDIA = 'socialMedia';
export const SHOW_SOCIAL_MEDIA_POPUP = 'showSocialMediaPopUp';
export const PROJECTS = 'projects';
export const ME = 'me';
export const CV = 'cv';
export const UUID = 'uuid';
export const CREATED_DATE = 'createdDate';
export const PROFILE_STATUS = 'profileStatus';
export const ACCOUNT_EMAIL = 'accountEmail';
export const PROFILE = 'profile';
export const BIO = 'bio';
export const SKILLS = 'skills';
export const INVOICES = 'invoices';
export const INVOICE_REQUESTS = 'invoiceRequests';
export const INTERESTS = 'interests';
export const ATTACHMENTS = 'attachments';
export const LANGUAGES = 'languages';
export const MORE_INFORMATION = 'moreInformation';
export const UPLOAD_FILE_MODAL = 'UPLOAD_FILE_MODAL';
export const UPLOAD_AVATAR_MODAL = 'UPLOAD_AVATAR_MODAL';
export const UPLOAD_CV_MODAL = 'UPLOAD_CV_MODAL';
export const AVATAR = 'avatar';
export const HIDE_ADD_PRJ_BTN = 'hideAddProjectButton';
export const SHOW_SUBMIT_CANCEL_PRJ_BTN = 'showSubmitCancelProjectButton';
export const HIDE_SUBMIT_CANCEL_PRJ_BTN = 'hideSubmitCancelProjectButton';
export const DISCARD_FIELD_CHANGES_ELEMENT_STYLE = 'btn btn-outline-danger naf-icon-btn';
export const NOTES = 'notes';

export const UNDER_REVIEW_BANNER_TEXT = 'Your account is being assessed by the NEEDaFIXER Community Team. Please bear with us while we reach out to your referees.We will contact you for an Interview Call as soon as we receive your references.';
