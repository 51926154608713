import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Project from './Project';
import { projectPropTypes } from '../constants/propTypes';
import { projectsSelector } from '../selectors';
import AddNewProjectForm from './AddNewProjectForm';

const mapStateToProps = state => ({
  projects: projectsSelector(state)
});

const Projects = ({ projects }) => {
  const projectsArr = projects && projects.toJS();
  return (
    <Fragment>
      <div className="row">
        <div className="card naf-card col-md-12">
          <div className="card-body">
            <h4 className="card-title">
              <span>PROJECTS</span>
            </h4>
            {
              projectsArr && projectsArr.length > 0 && projectsArr
                .sort((prjA, prjB) => moment(prjB.dateFrom).diff(moment(prjA.dateFrom)))
                .map((prj, index) => (<Project index={index} key={prj.title} projects={projectsArr} project={prj} />))
            }
          </div>
        </div>
      </div>
      <AddNewProjectForm />
    </Fragment>
  );
};

Projects.propTypes = {
  projects: PropTypes.arrayOf(projectPropTypes)
};

export default connect(mapStateToProps)(Projects);
