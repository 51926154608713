/* eslint-disable no-underscore-dangle */
import 'babel-polyfill';
import React from 'react';
import { Map } from 'immutable';
import { BrowserRouter } from 'react-router-dom';
import { asyncLocalStorage } from 'redux-persist/storages';
import { persistStore, autoRehydrate } from 'redux-persist-immutable';
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './reducers/rootReducer';
import rootSaga from './rootSaga';
import App from './app';
import './main.scss';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

// Creation of the store with saga middleware & reducer
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  Map(),
  composeEnhancers(
    autoRehydrate(),
    applyMiddleware(sagaMiddleware)
  )
);

persistStore(
  store,
  // Use session storage
  { storage: asyncLocalStorage },
  () => {
    sagaMiddleware.run(rootSaga);
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter >
          <App />
        </BrowserRouter>
      </Provider>,
      document.getElementById('root')
    );
  }
);
