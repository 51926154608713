import React from 'react';
import { DropdownList } from 'react-widgets';
import countries from '../../core/constants/countries';

const renderCountriesSearchableDropDown = className => field => (
  <DropdownList
    id="search-country"
    containerClassName={className}
    filter="contains"
    searchIcon={<span className="naf-icon-pencil" />}
    data={countries}
    valueField="value"
    textField="label"
    value={field.input.value}
    onChange={field.input.onChange}
    placeholder="Country"
  />
);

export default renderCountriesSearchableDropDown;
