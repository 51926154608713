import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setUIStateAction } from '../../core/actions';
import { BIO, EDIT_MODE } from '../constants';
import { bioSelector } from '../selectors';

const mapStateToProps = state => ({
  bio: bioSelector(state)
});

const mapDispatchToProps = dispatch => ({
  setEditMode: () => dispatch(setUIStateAction(BIO, EDIT_MODE))
});

const BioView = ({ setEditMode, bio }) => (

  <React.Fragment>
    <div className="naf-edit-btn-wrapper text-right">
      <button type="button" className="btn btn-link naf-edit-btn" onClick={() => setEditMode()}>EDIT</button>
    </div>
    <p style={{ whiteSpace: 'pre-wrap' }}>{bio}</p>
  </React.Fragment>
);

BioView.propTypes = {
  setEditMode: PropTypes.func,
  bio: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(BioView);
