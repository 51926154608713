import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { submit } from 'redux-form/immutable';
import { MORE_INFORMATION } from '../../../constants';

const mapDispatchToProps = dispatch => ({
  submitMoreInformationSection: () => dispatch(submit(MORE_INFORMATION))
});


const RadioButtonField = connect(null, mapDispatchToProps)(({
  input, submitMoreInformationSection, label, options
}) => (
  <React.Fragment>
    <div><strong>{label.toUpperCase()}:</strong></div>
    <div className="mb-3">
      {
        options.map(option => (
          <div className="custom-control custom-radio custom-control-inline">
            <input
              className="custom-control-input"
              id={option.id}
              type="radio"
              {...input}
              onChange={(e) => {
                input.onChange(e);
                submitMoreInformationSection();
              }}
              value={option.value}
              checked={option.value === input.value.toString()}
            />
            <label className="custom-control-label" htmlFor={option.id}>{option.label}</label>
          </div>))
      }
    </div>
  </React.Fragment>
));

RadioButtonField.propTypes = {
  input: PropTypes.shape(),
  submitMoreInformationSection: PropTypes.func,
  label: PropTypes.string
};

export default RadioButtonField;
