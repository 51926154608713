import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { withRouter } from 'react-router';
import RegWizardFormFirstPage from './stepOne/RegWizardFormFirstPage';
import RegWizardFormSecondPage from './stepTwo/RegWizardFormSecondPage';
import { setUIStateAction } from '../core/actions';
import { registrationStepSelector } from './selectors';
import { ACCOUNT_EMAIL_UPDATE, EMAIL_CONFIRMATION } from '../Layout/routePaths';
import { confirmPasswordAction, confirmUpdateAccountEmailAction } from '../Login/actions';
import { UI, WARNING } from '../core/constants';
import NafModal from '../widgets/NafModal';
import { CONFIRM_ACCOUNT_EMAIL_UPDATE, CONFIRM_EMAIL_MODAL_STATE } from './constants';
import { renderRegistrationVerificationModalMessage } from './utils';

const mapDispatchToProps = (dispatch, { history }) => ({
  initRegistration: () => dispatch(setUIStateAction('registrationStep', 1)),
  confirmPassword: data => dispatch(confirmPasswordAction(data)),
  updateAccountEmail: data => dispatch(confirmUpdateAccountEmailAction(data)),
  closeModal: (path) => {
    if (path === EMAIL_CONFIRMATION) {
      dispatch(setUIStateAction(CONFIRM_EMAIL_MODAL_STATE, undefined));
    } else {
      dispatch(setUIStateAction(CONFIRM_ACCOUNT_EMAIL_UPDATE, undefined));
    }
    history.push('/login');
  }
});

const mapStateToProps = state => ({
  step: registrationStepSelector(state),
  emailConfirmationModalState: state.getIn([UI, CONFIRM_EMAIL_MODAL_STATE]),
  accountEmailUpdateModalState: state.getIn([UI, CONFIRM_ACCOUNT_EMAIL_UPDATE]),
});

const renderContentForStep = (step) => {
  if (step === 1) {
    return (
      <Fragment>
        <h2>REGISTER TO NEEDaFIXER COMMUNITY</h2>
        <RegWizardFormFirstPage />
      </Fragment>
    );
  } else if (step === 2) {
    return (
      <Fragment>
        <h2>STEP 2</h2>
        <RegWizardFormSecondPage />
      </Fragment>);
  }
  return null;
};

// eslint-disable-next-line react/prefer-stateless-function
class RegisterComponent extends Component {
  componentWillMount() {
    const data = (parse(this.props.location.search));
    if (this.props.location.pathname === EMAIL_CONFIRMATION) {
      this.props.confirmPassword(data);
    } else if (this.props.location.pathname === ACCOUNT_EMAIL_UPDATE) {
      this.props.updateAccountEmail(data);
    } else {
      this.props.initRegistration();
    }
  }
  render() {
    return (
      <div id="naf-header-register" className="container-fluid naf-bg-header d-flex flex-column justify-content-center">
        <main role="main" className="container d-flex justify-content-center">
          <div className="naf-reg-box-wrap">
            <div className="naf-reg-box">
              {renderContentForStep(this.props.step)}
            </div>
          </div>
        </main>
        {
          this.props.emailConfirmationModalState && this.props.location.pathname === EMAIL_CONFIRMATION &&
          <NafModal
            type={this.props.emailConfirmationModalState}
            title="Account Verification"
            leftBtnClickHandler={() => this.props.closeModal(this.props.location.pathname)}
            leftBtnLabel="OK"
            leftBtnDisabled={this.props.emailConfirmationModalState === WARNING}
            message={renderRegistrationVerificationModalMessage(this.props.emailConfirmationModalState)}
          />
        }
        {
          this.props.accountEmailUpdateModalState && this.props.location.pathname === ACCOUNT_EMAIL_UPDATE &&
          <NafModal
            type={this.props.accountEmailUpdateModalState}
            title="Account Email Update"
            leftBtnClickHandler={() => this.props.closeModal(this.props.location.pathname)}
            leftBtnLabel="OK"
            leftBtnDisabled={this.props.accountEmailUpdateModalState === WARNING}
            message={renderRegistrationVerificationModalMessage(this.props.accountEmailUpdateModalState)}
          />
        }
      </div>
    );
  }
}

RegisterComponent.propTypes = {
  step: PropTypes.number,
  initRegistration: PropTypes.func,
  location: PropTypes.shape(),
  closeModal: PropTypes.func,
  confirmPassword: PropTypes.func,
  updateAccountEmail: PropTypes.func,
  emailConfirmationModalState: PropTypes.string,
  accountEmailUpdateModalState: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegisterComponent));
