import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import firstStepSyncValidator from '../validators/firstStepSyncValidator';
import firstStepSubmissionValidator from '../validators/firstStepSubmissionValidator';
import renderTextRegField from '../../core/renderers/renderTextFormField';
import { REGISTRATION_STEP_ONE, RegistrationFormFields } from '../constants';
import renderCountriesDropDown from './renderCountriesDropDown';

const FirstStepTextFields = {
  firstName: renderTextRegField(RegistrationFormFields.firstStep.FIRST_NAME),
  lastName: renderTextRegField(RegistrationFormFields.firstStep.LAST_NAME),
  email: renderTextRegField(RegistrationFormFields.firstStep.EMAIL),
  phone: renderTextRegField(RegistrationFormFields.firstStep.PHONE),
  city: renderTextRegField(RegistrationFormFields.firstStep.CITY),
};

const RegWizardFormFirstPage = props => (
  <form onSubmit={props.handleSubmit(firstStepSubmissionValidator)}>
    <Field
      name={RegistrationFormFields.firstStep.FIRST_NAME}
      component={FirstStepTextFields.firstName}
    />
    <Field
      name={RegistrationFormFields.firstStep.LAST_NAME}
      component={FirstStepTextFields.lastName}
    />
    <Field
      name={RegistrationFormFields.firstStep.EMAIL}
      component={FirstStepTextFields.email}
    />
    <Field
      name={RegistrationFormFields.firstStep.PHONE}
      component={FirstStepTextFields.phone}
    />
    <Field
      name={RegistrationFormFields.firstStep.COUNTRY}
      component={renderCountriesDropDown}
    />
    <div style={{ paddingTop: '12px' }}>
      <Field
        name={RegistrationFormFields.firstStep.CITY}
        component={FirstStepTextFields.city}
      />
    </div>
    <div className="form-group text-center">
      <button type="submit" className="btn naf-btn-red naf-btn-wide mt-3">NEXT</button>
    </div>
  </form>
);

RegWizardFormFirstPage.propTypes = {
  handleSubmit: PropTypes.func
};

export default reduxForm({
  form: REGISTRATION_STEP_ONE,
  validate: firstStepSyncValidator,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(RegWizardFormFirstPage);
