import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { arrayPush } from 'redux-form/immutable';
import { connect } from 'react-redux';
import Reference from './Reference';
import { projectPropTypes } from '../constants/propTypes';
import {
  ADD,
  DEL_MODAL_INDEX,
  DELETE,
  HIDE_CONTROLS,
  MODAL,
  MODE,
  PROJECTS,
  UPDATE,
  WARNING
} from '../../core/constants';
import {
  EDIT_MODE,
  HIDE_ADD_PRJ_BTN, HIDE_SUBMIT_CANCEL_PRJ_BTN,
  SHOW_SUBMIT_CANCEL_PRJ_BTN,
  UPDATE_EXISTING_PROJECT_FORM, VIEW_MODE
} from '../constants';
import { setUIStateAction } from '../../core/actions';
import { deleteProjectAction } from '../actions';
import {
  confirmProjectDeleteModalUISelector,
  hideProjectUpdateControlsUIStateSelector,
  modalIndexUIStateSelector,
  projectsSelector
} from '../selectors';
import NafModal from '../../widgets/NafModal';

const renderPeriod = (dateFrom, dateTo) => `${dateFrom} to ${dateTo}`;

const mapStateToProps = (state, { index }) => ({
  shouldShowConfirmDeleteModal: confirmProjectDeleteModalUISelector(state) === true,
  modalIndex: modalIndexUIStateSelector(state),
  projects: projectsSelector(state),
  shouldHideTheUpdateControls: hideProjectUpdateControlsUIStateSelector(state, index)
});

const mapDispatchToProps = (dispatch, { projects, project, index }) => ({
  handleEdit: () => {
    projects.forEach((prj, ind) => {
      if (ind !== index) {
        dispatch(setUIStateAction([PROJECTS, UPDATE, HIDE_CONTROLS, ind], true));
      }
    });
    dispatch(setUIStateAction([PROJECTS, UPDATE, HIDE_CONTROLS, index], true));
    dispatch(arrayPush(UPDATE_EXISTING_PROJECT_FORM, PROJECTS, fromJS(project)));
    dispatch(setUIStateAction([PROJECTS, UPDATE, MODE, index], EDIT_MODE));
    dispatch(setUIStateAction([PROJECTS, ADD, MODE], VIEW_MODE));
    dispatch(setUIStateAction([PROJECTS, ADD, HIDE_ADD_PRJ_BTN], true));
    dispatch(setUIStateAction([PROJECTS, ADD, SHOW_SUBMIT_CANCEL_PRJ_BTN], false));
    dispatch(setUIStateAction([PROJECTS, UPDATE, HIDE_SUBMIT_CANCEL_PRJ_BTN, index], false));
  },
  confirmDeletion: () => {
    dispatch(deleteProjectAction());
  },
  cancelDeletion: () => dispatch(setUIStateAction([PROJECTS, DELETE, MODAL], false)),
  handleClickDeleteBtn: () => {
    dispatch(setUIStateAction([PROJECTS, DELETE, MODAL], true));
    dispatch(setUIStateAction([PROJECTS, DEL_MODAL_INDEX], index));
  }
});

const ProjectView = (props) => {
  const {
    project,
    handleEdit,
    handleClickDeleteBtn,
    shouldShowConfirmDeleteModal,
    shouldHideTheUpdateControls,
    confirmDeletion,
    cancelDeletion
  } = props;
  const {
    title,
    role,
    location,
    dateFrom,
    dateTo,
    description,
    references
  } = project;
  return (
    <div className="card naf-experience-item mb-4">
      <div className="card-header d-flex justify-content-between">
        <span>{ title }</span>
      </div>
      <div className="card-body">
        <ul className="list-inline naf-list-bullets">
          <li className="list-inline-item"><strong>ROLE: </strong>{ role }</li>
          <li className="list-inline-item"><strong>LOCATION:</strong> { location }</li>
          <li className="list-inline-item"><strong>PERIOD:</strong> { renderPeriod(dateFrom, dateTo) }</li>
        </ul>
        <p>{ description }</p>
        {
          references && references.length > 0 &&
          <Fragment>
            <h6>REFERENCES</h6>
            {references.map(ref => (<Reference key={ref.contactNumber} reference={ref} />))}
          </Fragment>
        }
      </div>
      {
        !shouldHideTheUpdateControls &&
        <div className="d-flex justify-content-end naf-input-group">
          <button
            className="btn btn-outline-default naf-icon-btn mr-1"
            type="button"
            onClick={handleEdit}
          >
            <span className="naf-icon-pencil" />
          </button>
          <button
            className="btn btn-outline-danger naf-icon-btn"
            type="button"
            onClick={handleClickDeleteBtn}
          >
            <span className="naf-icon-times" />
          </button>
        </div>
      }
      {
        shouldShowConfirmDeleteModal &&
        <NafModal
          title="Confirm project deletion"
          message="Are you sure you want to delete the project?"
          type={WARNING}
          leftBtnClickHandler={confirmDeletion}
          leftBtnLabel="Confirm"
          rightBtnClickHandler={cancelDeletion}
          rightBtnLabel="Cancel"
        />
      }
    </div>
  );
};

ProjectView.propTypes = {
  project: projectPropTypes,
  handleEdit: PropTypes.func,
  shouldShowConfirmDeleteModal: PropTypes.bool,
  shouldHideTheUpdateControls: PropTypes.bool,
  confirmDeletion: PropTypes.func,
  cancelDeletion: PropTypes.func,
  handleClickDeleteBtn: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectView);
