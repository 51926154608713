import { RESET_EMAIL_FIELD } from '../constants';
import { isNafEmail, isValidEmail } from '../../core/utils';

const validator = (values) => {
  const errors = {};
  const email = values.get(RESET_EMAIL_FIELD);
  if (!email) {
    errors[RESET_EMAIL_FIELD] = 'Required';
  }
  if (email && (!isValidEmail(email) || isNafEmail(email))) {
    errors[RESET_EMAIL_FIELD] = 'Invalid email';
  }
  return errors;
};

export default validator;
