import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { LOGIN, PROFILE } from '../Layout/routePaths';
import { adminRoleSelector } from '../Login/selectors';

const mapDispatchToProps = dispatch => ({
  setIntendedRoute: ({ pathname, search }) => dispatch({ type: 'SET_INTENDED_ROUTE', payload: `${pathname}${search}` })
});

const mapStateToProps = state => ({
  isAdmin: adminRoleSelector(state)
});

class AuthenticatedRouteComponent extends React.Component {
  componentWillMount() {
    this.props.setIntendedRoute(this.props.location);
  }

  render() {
    const {
      component,
      exact,
      path,
      token
    } = this.props;
    return (
      token ?
        <Route
          path={path}
          exact={exact}
          component={component}
        />
        : <Redirect to={LOGIN} />
    );
  }
}

AuthenticatedRouteComponent.propTypes = {
  setIntendedRoute: PropTypes.func,
  location: PropTypes.shape(),
  component: PropTypes.func,
  path: PropTypes.string,
  token: PropTypes.string,
  exact: PropTypes.bool
};

export const AuthenticatedRoute = withRouter(connect(null, mapDispatchToProps)(AuthenticatedRouteComponent));

const UnauthenticatedRouteComponent = (props) => {
  const {
    isAdmin,
    redirectOnAuthenticated,
    component,
    path,
    exact,
    token
  } = props;
  return (
    redirectOnAuthenticated && token ?
      <Redirect to={isAdmin ? '/search' : PROFILE} />
      : <Route
          path={path}
          exact={exact}
          component={component}
      />
  );
};

UnauthenticatedRouteComponent.propTypes = {
  isAdmin: PropTypes.shape(),
  component: PropTypes.func,
  path: PropTypes.string,
  exact: PropTypes.bool,
  redirectOnAuthenticated: PropTypes.bool,
  token: PropTypes.string
};

export const UnauthenticatedRoute = connect(mapStateToProps)(UnauthenticatedRouteComponent);

