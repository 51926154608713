export const SET_UI_STATE = 'set-ui-state';
export const SET_ERROR = 'set-error';
export const CLEAR_ERROR = 'clear-error';
export const CLEAR_UI_STATE = 'clear-ui-state';
export const CLEAR_STATE = 'clear-state';
export const CLEAR_SECTION_SUBMITTING_FIELD = 'clear-section-submitting-field';
export const SEND_ERROR_LOG = 'send-error-log';

export const setUIStateAction = (attr, value) => ({
  type: SET_UI_STATE,
  payload: {
    attr,
    value
  }
});
export const clearUIStateAction = () => ({
  type: CLEAR_UI_STATE
});

export const clearStateAction = () => ({
  type: CLEAR_STATE
});

export const clearSectionSubmittingFieldAction = section => ({
  type: CLEAR_SECTION_SUBMITTING_FIELD,
  payload: { section }
});

export const setErrorAction = error => ({
  type: SET_ERROR,
  payload: { error }
});

export const sendErrorLogAction = error => ({
  type: SEND_ERROR_LOG,
  payload: { error }
});

export const clearErrorAction = () => ({
  type: CLEAR_ERROR
});
