import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import NafDismissibleBox from '../widgets/NafDismissibleBox';
import {
  missingInformationSelector,
  profileStatusSelector,
  projectsSelector,
  shouldShowUnderReviewBannerSelector
} from './selectors';
import { IS_UNDER_REVIEW, MISSING_INFORMATION, UNDER_REVIEW_BANNER_TEXT } from './constants';
import { DANGER } from '../core/constants';
import { projectPropTypes } from './constants/propTypes';

const enrichMissingInfoBullets = (missingInfoArr, projects) => {
  const missingCopy = Array.from(missingInfoArr);
  if (missingCopy.includes('Projects')) {
    const validProjectsNo = projects
      .map(prj => prj.toJS())
      .filter(prj => prj.references && prj.references.length > 0)
      .length;
    const indexOfProjects = missingInfoArr.indexOf('Projects');
    missingCopy[indexOfProjects] = `Projects(with at least one reference ${validProjectsNo}/2)`;
  }
  return missingCopy;
};

const mapStateToProps = state => ({
  missingInformation: missingInformationSelector(state),
  projects: projectsSelector(state),
  isUnderReview: profileStatusSelector(state) === 'UNDER_REVIEW',
  shouldShowUnderReviewBanner: shouldShowUnderReviewBannerSelector(state)
});

const MissingInformation = (props) => {
  const {
    missingInformation,
    projects,
    isUnderReview,
    shouldShowUnderReviewBanner
  } = props;
  if (missingInformation && missingInformation.size) {
    return (
      <NafDismissibleBox
        uiStateElement={MISSING_INFORMATION}
        type={DANGER}
        bullets={enrichMissingInfoBullets(missingInformation.toArray(), projects ? projects.toArray() : [])}
        title="Missing Information"
      />
    );
  }
  if (isUnderReview && shouldShowUnderReviewBanner) {
    return (
      <NafDismissibleBox
        uiStateElement={IS_UNDER_REVIEW}
        type={DANGER}
        title="Under Review"
        text={UNDER_REVIEW_BANNER_TEXT}
      />
    );
  }
  return <span />;
};

MissingInformation.propTypes = {
  missingInformation: PropTypes.instanceOf(Immutable.List),
  isUnderReview: PropTypes.bool,
  shouldShowUnderReviewBanner: PropTypes.bool,
  projects: PropTypes.arrayOf(projectPropTypes)
};

export default connect(mapStateToProps)(MissingInformation);
