import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, submit } from 'redux-form/immutable';
import { connect } from 'react-redux';
import renderUpdatePasswordField from './formFields/renderUpdatePasswordField';
import { CONFIRM_NEW_PASSWORD, EXISTING_PASSWORD, NEW_PASSWORD } from './constants';
import { setUIStateAction } from '../../core/actions';
import { ACCOUNT, PASSWORD } from '../../core/constants';
import { UPDATE_ACCOUNT_PASSWORD_FORM, VIEW_MODE } from '../constants';
import updateAccountPasswordValidator from './updateAccountPasswordValidator';

const renderExistingPasswordField = renderUpdatePasswordField('Existing Password');
const renderNewPasswordField = renderUpdatePasswordField('New Password');
const renderConfirmNewPasswordField = renderUpdatePasswordField('Confirm new Password');

const mapDispatchToProps = dispatch => ({
  handleCancel: () => dispatch(setUIStateAction([ACCOUNT, PASSWORD], VIEW_MODE)),
  submitForm: () => dispatch(submit(UPDATE_ACCOUNT_PASSWORD_FORM))
});

const AccountUpdatePassword = ({ handleCancel, submitForm }) => (
  <Fragment>
    <div className="naf-my-account-col-inner naf-my-account-col-accent">
      <div className="naf-hoverable naf-edit-row">
        <div className="naf-hoverable naf-edit-row">
          <Field
            id={EXISTING_PASSWORD}
            name={EXISTING_PASSWORD}
            component={renderExistingPasswordField}
          />
          <Field
            id={NEW_PASSWORD}
            name={NEW_PASSWORD}
            component={renderNewPasswordField}
          />
          <Field
            id={CONFIRM_NEW_PASSWORD}
            name={CONFIRM_NEW_PASSWORD}
            component={renderConfirmNewPasswordField}
          />
        </div>
      </div>
    </div>
    <div className="d-flex justify-content-end naf-input-group">
      <button
        className="btn btn-outline-success naf-icon-btn"
        type="button"
        onClick={submitForm}
      >
        <span className="naf-icon-check" />
      </button>
      <button
        className="btn btn-outline-danger naf-icon-btn"
        type="button"
        onClick={handleCancel}
      >
        <span className="naf-icon-times" />
      </button>
    </div>
  </Fragment>
);

AccountUpdatePassword.propTypes = {
  handleCancel: PropTypes.func,
  submitForm: PropTypes.func,
};

const AccountUpdatePasswordContainer = connect(null, mapDispatchToProps)(AccountUpdatePassword);

export default reduxForm({
  form: UPDATE_ACCOUNT_PASSWORD_FORM,
  validate: updateAccountPasswordValidator
})(AccountUpdatePasswordContainer);
