import { call, put, takeLatest } from 'redux-saga/effects';
import { authorizedRequest } from '../../core/restClient';
import { setUIStateAction } from '../../core/actions';
import { CONTACT_US_REQUEST_RESULT, CONTACT_US_RESULT_MODAL_VISIBLE } from '../constants';
import { DANGER, SUCCESS } from '../../core/constants';
import { SUBMIT_CONTACT_US_FORM } from '../actions';

function* submitContactUsFormSaga(action) {
  const { contactUsRequestPayload } = action.payload;
  try {
    yield call(authorizedRequest, 'POST', '/contact-page', contactUsRequestPayload);
    yield put(setUIStateAction(CONTACT_US_RESULT_MODAL_VISIBLE, true));
    yield put(setUIStateAction(CONTACT_US_REQUEST_RESULT, SUCCESS));
  } catch (err) {
    yield put(setUIStateAction(CONTACT_US_RESULT_MODAL_VISIBLE, true));
    yield put(setUIStateAction(CONTACT_US_REQUEST_RESULT, DANGER));
  }
}

export default function* watchContactUsRequests() {
  yield takeLatest(SUBMIT_CONTACT_US_FORM, submitContactUsFormSaga);
}
