import React from 'react';
import { reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bioUiStateSelector } from '../selectors';
import { BIO, EDIT_MODE } from '../constants';
import BioEdit from './BioEdit';
import BioView from './BioView';
import { submitBioFormAction } from '../actions';

const submitBioForm = (values, dispatch) => {
  dispatch(submitBioFormAction(values.toJS()));
};

const mapStateToProps = state => ({
  mode: bioUiStateSelector(state)
});

const Bio = ({
  mode,
  isAdmin
}) => (
  <div className={`card naf-card col-md-${isAdmin ? '6' : '12'}`}>
    <div className="card-body">
      <h4 className="card-title"><span>BIO</span></h4>
      {
        mode !== EDIT_MODE ? <BioView /> : <BioEdit />
      }
    </div>
  </div>
);

Bio.propTypes = {
  mode: PropTypes.string,
  isAdmin: PropTypes.shape()
};

export default reduxForm({
  form: BIO,
  onSubmit: submitBioForm,
  enableReinitialize: true
})(connect(mapStateToProps)(Bio));
