import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { avatarSelector } from '../../selectors';
import { setUIStateAction } from '../../../core/actions';
import { AVATAR, UPLOAD_AVATAR_MODAL } from '../../constants';
import NafUploadFileModal from '../../../widgets/UploadFileWidget/NafUploadFileModal';
import { submitAvatarUploadAction } from '../../actions';
import Spinner from '../../../widgets/Spinner';
import DefaultAvatar from '../../../../resources/media/avatar/default-avatar.png';

const mapDispatchToProps = dispatch => ({
  openUploadFileModal: () => dispatch(setUIStateAction(UPLOAD_AVATAR_MODAL, true)),
});


const mapStateToProps = state => ({
  avatar: avatarSelector(state)
});

const ProfilePicture = ({ avatar, openUploadFileModal }) => (
  <React.Fragment>
    <div className="col-xl-4 naf-profile-photo-wrapper">
      <Spinner element={AVATAR} />
      <div className="naf-profile-photo" style={{ backgroundImage: avatar ? `url(static/${avatar})` : `url(${DefaultAvatar})` }}>
        <div className="pull-right">
          <button type="button" className="btn btn-link naf-edit-btn" onClick={() => openUploadFileModal()}>EDIT</button>
        </div>
      </div>
    </div>
    <NafUploadFileModal
      action={submitAvatarUploadAction}
      stateIdentifier={UPLOAD_AVATAR_MODAL}
      title="Upload your profile picture"
    />
  </React.Fragment>
);

ProfilePicture.propTypes = {
  avatar: PropTypes.string,
  openUploadFileModal: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicture);
