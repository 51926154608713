import PropTypes from 'prop-types';

export const referencePropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactNumber: PropTypes.string.isRequired
});

export const projectPropTypes = PropTypes.shape({
  title: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  references: PropTypes.arrayOf(referencePropTypes)
});
