import moment from 'moment';

export const constructProjectPayload = data => ({
  title: data.title,
  role: data.role,
  location: data.location,
  description: data.description,
  references: data.references,
  dateFrom: moment(data.dateFrom).format('YYYY-MM-DD'),
  dateTo: moment(data.dateTo).format('YYYY-MM-DD')
});
