import React from 'react';
import { capitalizeFirstLetter } from '../utils/StringFormatters';

const renderField = (name, type) => field => (
  <div className="form-group">
    <label htmlFor={name} className="d-flex justify-content-between">
      <span>{name && capitalizeFirstLetter(name)}</span>
      {field.meta.touched && field.meta.error && !field.meta.active &&
      <span className="req">{field.meta.error}</span>}
    </label>
    { type === 'textarea'
      ? <textarea {...field.input} className="form-control" id={name} />
      : <input {...field.input} type={type || 'input'} className="form-control" id={name} />
    }
  </div>
);

export default renderField;
