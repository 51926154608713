import React from 'react';
import { Link } from 'react-router-dom';
import { REGISTER, TERMS, LOGIN, CONTACT_US } from './routePaths';

const NafUrl = 'https://www.needafixer.com';
const FacebookUrl = 'https://www.facebook.com/needafixer';
const TwitterUrl = 'https://twitter.com/needafixer';
const InstagramUrl = 'https://instagram.com/needafixer';
const VimeoUrl = 'https://vimeo.com/needafixer';

const Footer = () => (
  <footer>
    <div className="naf-footer-cols pb-5">
      <div className="container pt-3">
        <div className="mb-2"><span className="text-hide naf-logo-footer">Need a Fixer</span></div>
        <div className="row">
          <div className="naf-footer__col col-12 col-md-6 col-lg-3">
            <address className="naf-footer__col__cont pt-3">
              Unit 3, Canbury Business Park, Elm Crescent,<br />
              Kingston Upon Thames - KT2 6HJ,<br />
              London, United Kingdom<br />
              T: +44 (0) 20 8549 2259<br />
              E: info@needafixer.com<br />
            </address>
          </div>
          <div className="naf-footer__col col-12 col-md-6 col-lg-3">
            <ul className="list-unstyled naf-footer__col__cont pt-3">
              <li>
                <a href={NafUrl} target="_blank" rel="noopener noreferrer">NEEDaFIXER</a>
              </li>
              <li>
                <Link to={CONTACT_US}>Contact Us</Link>
              </li>
              <li>
                <Link to={REGISTER}>Register</Link>
              </li>
              <li>
                <Link to={LOGIN}>Login</Link>
              </li>
            </ul>
          </div>
          <div className="naf-footer__col col-12 col-md-6 col-lg-3">
            <ul className="list-unstyled naf-footer__col__cont pt-3">
              <li>
                <a href={FacebookUrl} target="_blank" rel="noopener noreferrer">
                  <span className="fa fa-fw fa-facebook" />
                  Facebook
                </a>
              </li>
              <li>
                <a href={TwitterUrl} target="_blank" rel="noopener noreferrer">
                  <span className="fa fa-fw fa-twitter" />
                  Twitter
                </a>
              </li>
              <li>
                <a href={InstagramUrl} target="_blank" rel="noopener noreferrer">
                  <span className="fa fa-fw fa-instagram" />
                  Instagram
                </a>
              </li>
              <li>
                <a href={VimeoUrl} target="_blank" rel="noopener noreferrer">
                  <span className="fa fa-fw fa-vimeo" />
                  Vimeo
                </a>
              </li>
            </ul>
          </div>
          <div className="naf-footer__col col-12 col-md-6 col-lg-3">
            <ul className="list-unstyled naf-footer__col__cont pt-3">
              <li>
                <Link to={TERMS}>Terms & Privacy policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="copy" className="text-center py-3">© 2018 All rights reserved to NEEDaFIXER Ltd.</div>
  </footer>
);

export default Footer;
