/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearErrorAction, sendErrorLogAction, setErrorAction } from './actions';
import { errorSelector } from './selectors';
import { isEmptyImmutableMap } from './utils';
import { MAIN } from '../Layout/routePaths';
import { logoutAction } from '../Login/actions';

const mapStateToProps = state => ({
  applicationError: errorSelector(state)
});

const mapDispatchToProps = dispatch => ({
  setError: (error, info) => {
    const stacktrace = `${error.toString()} ${info.componentStack}`;
    dispatch(setErrorAction(info));
    dispatch(sendErrorLogAction({
      url: window.location.href,
      stacktrace
    }));
  },
  handleReturnHome: (history) => {
    dispatch(logoutAction());
    history.push(MAIN);
    dispatch(clearErrorAction());
  }
});

class ErrorBoundary extends Component {
  componentDidCatch(error, info) { this.props.setError(error, info); }
  render() {
    if (!isEmptyImmutableMap(this.props.applicationError)) {
      return (
        <div className="container-fluid naf-error-page d-flex flex-column justify-content-center">
          <main role="main" className="container d-flex justify-content-center">
            <div className="d-flex flex-column justify-content-between naf-jumbotron align-items-center text-center">
              <div id="naf-cat-error" className="mb-5" />
              <p className="naf-error-title">An error occurred</p>
              <p>
                <button
                  onClick={() => this.props.handleReturnHome(this.props.history)}
                  className="btn naf-btn-purple-round naf-btn-wide"
                >
                  HOME
                </button>
              </p>
            </div>
          </main>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  setError: PropTypes.func,
  handleReturnHome: PropTypes.func,
  applicationError: PropTypes.shape(),
  history: PropTypes.shape()
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ErrorBoundary));
