import React, { Fragment } from 'react';
import { fieldHasError } from '../../Projects/formFields/utils';


const renderUpdatePasswordField = label => field => (
  <Fragment>
    <div className="naf-my-account-label">{ label }</div>
    <div className="naf-my-account-value">
      <input
        {...field.input}
        type="password"
        className={`form-control ${fieldHasError(field) ? 'is-invalid' : ''} naf-short-ctrl`}
      />
      {
        fieldHasError(field) && <div className="invalid-feedback">{field.meta.error}</div>
      }
    </div>
  </Fragment>
);

export default renderUpdatePasswordField;
