export const UI = 'ui';
export const BURGER = 'burger';
export const NEW = 'new';
export const VIEW = 'view';
export const EDIT = 'edit';

export const MODE = 'mode';
export const MAIN_SECTION = 'mainSection';
export const PERSONAL_INFORMATION = 'personalInformation';
export const PROJECTS = 'projects';
export const ACCOUNT = 'account';
export const INVOICES = 'invoices';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const ADD = 'add';
export const UPDATE = 'update';
export const DELETE = 'delete';
export const MODAL = 'modal';
export const MODAL_STATUS = 'modalStatus';
export const MODAL_UPDATING = 'modalUpdating';
export const DEL_MODAL_INDEX = 'deleteModalIndex';
export const HIDE_CONTROLS = 'hideControls';
export const SUCCESS = 'success';
export const DANGER = 'danger';
export const WARNING = 'warning';
export const ERROR = 'error';
export const PASSWORD_INPUT_TYPE = 'password';
export const ENTER_KEY = 'Enter';
export const ESCAPE_KEY = 'Escape';
export const SPINNER = 'SPINNER';
export const SUBMITTING_FIELD = 'submittingField';

export const EligibleAdminRoles = [
  'ROLE_ADMIN',
  'ROLE_COORDINATOR',
  'ROLE_COMMUNITY_MANAGER'
];
