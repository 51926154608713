import React from 'react';
import PropTypes from 'prop-types';


const NafModal = props => (
  <div className={`naf-modal naf-modal-${props.type} naf-show-modal`}>
    <div className="naf-modal-content">
      <div className="naf-modal-header">
        <h2>{props.title}</h2>
      </div>
      <div className="naf-modal-body">
        <p>{props.message}</p>
        <div className="naf-modal-footer">
          <button
            onClick={props.leftBtnClickHandler}
            type="button"
            className="btn btn-link"
            disabled={props.leftBtnDisabled || false}
          >
            {props.leftBtnLabel}
          </button>
          {
            props.rightBtnClickHandler && props.rightBtnLabel &&
            <button
              onClick={props.rightBtnClickHandler}
              type="button"
              className="btn btn-link"
            >
              {props.rightBtnLabel}
            </button>
          }
        </div>
      </div>
    </div>
  </div>
);

NafModal.propTypes = {
  // warning, error, success
  type: PropTypes.string,
  leftBtnClickHandler: PropTypes.func,
  rightBtnClickHandler: PropTypes.func,
  title: PropTypes.string,
  rightBtnLabel: PropTypes.string,
  leftBtnLabel: PropTypes.string,
  message: PropTypes.string,
  leftBtnDisabled: PropTypes.bool
};

export default NafModal;
