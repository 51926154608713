/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CARDS, LIST, RESULTS_VIEW_MODE } from './constants';
import { setUIStateAction } from '../core/actions';

const mapDispatchToProps = dispatch => ({
  handleResultsViewSelect: mode => dispatch(setUIStateAction(RESULTS_VIEW_MODE, mode))
});

const ResultsViewButtons = ({ resultsViewMode, handleResultsViewSelect }) => (
  <div>
    <div className="btn-group btn-group-toggle" data-toggle="buttons">
      <label className={`btn btn-light naf-icon-btn ${resultsViewMode === CARDS ? 'active' : ''}`}>
        <input
          type="radio"
          name="options"
          id="option1"
          autoComplete="off"
          onClick={() => handleResultsViewSelect(CARDS)}
        />
        <span className="fa fa-th-large text-secondary" />
      </label>
      <label className={`btn btn-light naf-icon-btn ${resultsViewMode === LIST ? 'active' : ''}`}>
        <input
          type="radio"
          name="options"
          id="option2"
          autoComplete="off"
          onClick={() => handleResultsViewSelect(LIST)}
        />
        <span className="fa fa-th-list text-secondary" />
      </label>
    </div>
  </div>
);

ResultsViewButtons.propTypes = {
  resultsViewMode: PropTypes.oneOf([CARDS, LIST]),
  handleResultsViewSelect: PropTypes.func
};

export default connect(null, mapDispatchToProps)(ResultsViewButtons);

