export const ContactUsFormFields = {
  NAME: 'name',
  EMAIL: 'email',
  MESSAGE: 'message'
};

export const CONTACT_US_RESULT_MODAL_VISIBLE = 'contactUsResultModalVisible';
export const CONTACT_US_REQUEST_RESULT = 'contactUsRequestResult';

export const CONTACT_US_RESULT_MODAL_TITLE = 'Contact Us';
export const CONTACT_US_FORM = 'contact-us';
