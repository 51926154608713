import React from 'react';
import PropTypes from 'prop-types';

const SocialMediaLink = ({ aClassName, spanClassName, url }) => (
  <li className="list-inline-item">
    <a href={url} target="_blank" rel="noopener noreferrer" className={`naf-icon-social naf-icon-social--${aClassName}`}>
      <span className={`fa fa-fw fa-${spanClassName}`} />
    </a>
  </li>
);

SocialMediaLink.propTypes = {
  aClassName: PropTypes.string,
  url: PropTypes.string,
  spanClassName: PropTypes.string
};

export default SocialMediaLink;
