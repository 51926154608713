import { all } from 'redux-saga/effects';
import watchAuthenticationRequests from '../Login/sagas';
import watchRegistrationRequests from '../Register/sagas';
import watchProfileRequests from './../Profile/sagas';
import watchResetPasswordRequests from './../ResetPassword/sagas';
import watchContactUsRequests from './../ContactUs/sagas';
import watchCoreRequests from './sagas';
import watchSectionFormSubmissionSaga from '../widgets/ProfileSectionForm/sagas';
import watchSearchRequests from '../Search/sagas';
import loginWithGoogleWatcher from '../Login/sagas/loginWithSocialSaga';

export default function* () {
  yield all([
    watchSearchRequests(),
    watchAuthenticationRequests(),
    watchRegistrationRequests(),
    watchProfileRequests(),
    watchResetPasswordRequests(),
    watchSectionFormSubmissionSaga(),
    watchContactUsRequests(),
    watchCoreRequests(),
    loginWithGoogleWatcher()
  ]);
}
