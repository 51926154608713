import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const RECAPTCHA_SITE_KEY = '6Lf-AWMUAAAAAKXQ8tGO1CacTMd8MOCX4z2xV0gR';

const renderRecaptcha = field => (
  <div className="form-group" id="naf-recaptcha">
    <div
      style={{
        marginBottom: '20px',
        backgroundColor: '#eee',
        width: '75%',
        height: '4rem',
        color: '#ccc',
        lineHeight: '4rem',
        textAlign: 'center',
        verticalAlign: 'middle'
      }}
    >
      <ReCAPTCHA
        // ADD key TO PROCESS.ENV
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={field.input.onChange}
      />
    </div >
    {field.meta && field.meta.touched && field.meta.error && !field.meta.active &&
    <span className="req">{field.meta.error}</span>}
  </div>
);

export default renderRecaptcha;
