import { Map } from 'immutable';
import { SET_UI_STATE, CLEAR_UI_STATE, CLEAR_SECTION_SUBMITTING_FIELD } from '../actions';
import { SUBMITTING_FIELD } from '../constants';

const ui = (state = Map(), action) => {
  switch (action.type) {
    case SET_UI_STATE:
      if (Array.isArray(action.payload.attr)) {
        return state.setIn(action.payload.attr, action.payload.value);
      }
      return state.set(action.payload.attr, action.payload.value);
    case CLEAR_UI_STATE:
      return Map();
    case CLEAR_SECTION_SUBMITTING_FIELD:
      return state.delete(`${SUBMITTING_FIELD}-${action.payload.section}`);
    default:
      return state;
  }
};

export default ui;
