import authTokenHandler from '../auth/authTokenHandler';

export const JSON_FORMAT = 'application/json';

const createHeaders = authorized => (
  authorized
    ? {
      Accept: JSON_FORMAT,
      'Content-Type': JSON_FORMAT,
      Authorization: authTokenHandler.getAuthToken()
    }
    : {
      Accept: JSON_FORMAT,
      'Content-Type': JSON_FORMAT,
    }
);

const AUTH_HEADER = 'Authorization';

export const socialLoginRequest = (code, redirect, provider) => {
  const parameters = {
    headers: createHeaders(false),
    method: 'GET'
  };
  return fetch(`/api/social/login?code=${code}&redirect=${redirect}&provider=${provider}`, parameters).then((response) => {
    if (response.ok && response.headers.get(AUTH_HEADER)) {
      const headerValue = response.headers.get(AUTH_HEADER);
      // Store token in localStorage
      authTokenHandler.storeAuthToken(headerValue.split(' ')[1]);
      return response.json();
    }
    throw new Error('Login with google failed.');
  }).then(responseJson => responseJson);
};
