import { RegistrationFormFields } from '../constants';
import { isNafEmail, isValidEmail, isValidPhoneNumber } from '../../core/utils';

const validator = (values) => {
  const errors = {};
  Object.values(RegistrationFormFields.firstStep).forEach((fieldName) => {
    if (!values.get(fieldName)) {
      errors[fieldName] = 'Required';
    }
  });
  const email = values.get(RegistrationFormFields.firstStep.EMAIL);
  if (email && (!isValidEmail(email) || isNafEmail(email))) {
    errors.email = 'Invalid Email';
  }
  const phone = values.get(RegistrationFormFields.firstStep.PHONE);
  if (!isValidPhoneNumber(phone)) {
    errors.phone = 'Invalid Phone Number';
  }
  return errors;
};

export default validator;
