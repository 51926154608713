import React from 'react';
import PropTypes from 'prop-types';
import { DropdownList } from 'react-widgets';
import currencies from '../../../constants/currencies';

const CurrencyDropDown = ({ input }) => (
  <DropdownList
    {...input}
    id="edit-exp-role-select"
    filter="contains"
    searchIcon={<span className="naf-icon-pencil" />}
    data={currencies}
    textField="label"
    valueField="value"
    placeholder="Please select currency"
  />
);

CurrencyDropDown.propTypes = {
  input: PropTypes.shape()
};

export default CurrencyDropDown;
