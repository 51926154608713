import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset } from 'redux-form/immutable';
import { PencilButton, XButton } from '../Buttons';
import { setUIStateAction } from '../../../../core/actions/index';
import { removeFieldThenSubmitFormAction } from '../../../../Profile/actions/index';

const mapDispatchToProps = (dispatch, {
  input, index, fields, meta: { form }
}) => ({
  setEditMode: () => {
    dispatch(reset(form));
    dispatch(setUIStateAction([form, 'edit'], input.name));
  },
  removeField: () => {
    dispatch(removeFieldThenSubmitFormAction(form, fields.name, index));
  }
});

const NafHoverableLi = ({
  input, editable, deletable, setEditMode, removeField, RenderFieldView
}) => (
  <li className="naf-hoverable">
    <RenderFieldView input={input} />
    <span className="naf-hoverable-show">
      {
        editable && <PencilButton callback={setEditMode} />
      }
      {
        deletable && <XButton callback={removeField} />
      }
    </span>
  </li>
);

NafHoverableLi.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string
  }),
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  setEditMode: PropTypes.func,
  removeField: PropTypes.func,
  RenderFieldView: PropTypes.node
};

export default connect(null, mapDispatchToProps)(NafHoverableLi);
