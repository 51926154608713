import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import queryString from 'query-string';
import { submit } from 'redux-form/immutable';
import {
  CHANGE_PROFILE_STATUS,
  SEARCH as SEARCH_ACTION,
  setResultsSummaryAction,
  setSearchResultsAction
} from '../actions';
import { authorizedRequest } from '../../core/restClient';
import { computeSortingQueryString, findInitialCategory, initSummary, processResultsSummaryResponse } from '../utils';
import { setUIStateAction } from '../../core/actions';
import {
  CITY,
  CITY_FILTER_PLACEHOLDER,
  COUNTRY, COUNTRY_FILTER_PLACEHOLDER, FILTER_FORM,
  FILTERS,
  MODE, NAME_FILTER_PLACEHOLDER,
  PROFILE_STATUS_CATEGORY, PROFILE_STATUS_CHANGE_MODAL,
  RATE,
  RESULTS,
  ROLE,
  ROLE_FILTER_PLACEHOLDER,
  SEARCH
} from '../constants';
import { SPINNER, VIEW } from '../../core/constants';
import { profileStatusCategorySelector, sortingSelector } from '../selectors';
import { getProfileAction, SELECT_VIEW_PROFILE } from '../../Profile/actions';
import { sleep } from '../../core/utils';

function* fetchResultsSummary(queryParams) {
  let summaryResp;
  try {
    summaryResp = yield call(authorizedRequest, 'GET', `/search/summary?${queryParams}`);
  } catch (err) {
    yield alert('Could not fetch summary');
    yield put(setResultsSummaryAction(initSummary));
    return initSummary;
  }
  const summary = yield call(processResultsSummaryResponse, summaryResp);
  yield put(setResultsSummaryAction(summary));
  return summary;
}

function* searchSaga(action) {
  let results;
  // cook the placeholder value non sense
  const data = action.payload;
  const {
    role,
    country,
    dailyRateFrom,
    dailyRateTo,
    city,
    name
  } = data;
  if (role === ROLE_FILTER_PLACEHOLDER) {
    data.role = undefined;
  }
  if (country === COUNTRY_FILTER_PLACEHOLDER) {
    data.country = undefined;
  }
  if (city === CITY_FILTER_PLACEHOLDER || !city) {
    data.city = undefined;
  }
  if (dailyRateFrom === null) {
    data.dailyRateFrom = undefined;
  }
  if (dailyRateTo === null) {
    data.dailyRateTo = undefined;
  }
  if (name === NAME_FILTER_PLACEHOLDER || !name) {
    data.name = undefined;
  }
  const currentProfileStatusCategory = yield select(profileStatusCategorySelector);
  const summary = yield call(fetchResultsSummary, queryString.stringify({ ...data, page: undefined }));
  const selectedCategory = currentProfileStatusCategory || findInitialCategory(summary);
  const queryParams = queryString.stringify({ ...data, profileStatus: selectedCategory });
  const sortingData = yield select(sortingSelector);
  const sortingQueryParams = sortingData ? yield call(computeSortingQueryString, sortingData.toJS()) : '';
  try {
    results = yield call(authorizedRequest, 'GET', `/search/filter?${queryParams}${sortingQueryParams}`);
  } catch (err) {
    yield alert('Could not fetch search results');
  }
  yield put(setUIStateAction([SEARCH, MODE], RESULTS));
  yield put(setUIStateAction([SEARCH, PROFILE_STATUS_CATEGORY], selectedCategory));
  yield put(setUIStateAction([SEARCH, FILTERS, ROLE], VIEW));
  yield put(setUIStateAction([SEARCH, FILTERS, COUNTRY], VIEW));
  yield put(setUIStateAction([SEARCH, FILTERS, RATE], VIEW));
  yield put(setUIStateAction([SEARCH, FILTERS, CITY], VIEW));
  yield put(setSearchResultsAction(results));
}

function* selectViewProfileSaga(action) {
  try {
    yield put(setUIStateAction(SPINNER, SEARCH));
    yield put.resolve(getProfileAction(action.payload.uuid));
    yield sleep(1000).then(() => console.log('slept for 1s'));
    yield put(setUIStateAction(SPINNER, ''));
    action.payload.history.push('/profile');
    window.scrollTo(0, 0);
  } catch (e) {
    alert(e);
  }
}

function* changeProfileStatusSaga(action) {
  const { uuid, status } = action.payload;
  try {
    yield call(authorizedRequest, 'PUT', `/profile/${uuid}/status`, { status });
    yield put(setUIStateAction(PROFILE_STATUS_CHANGE_MODAL, null));
    yield put(submit(FILTER_FORM));
  } catch (err) {
    yield alert('Could not update user status');
  }
}

export default function* watchSearchRequests() {
  yield all([
    takeLatest(SEARCH_ACTION, searchSaga),
    takeLatest(SELECT_VIEW_PROFILE, selectViewProfileSaga),
    takeLatest(CHANGE_PROFILE_STATUS, changeProfileStatusSaga)
  ]);
}
