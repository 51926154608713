/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { change } from 'redux-form/immutable';
import { PROFILE_STATUS_CATEGORY, SEARCH, ProfileStatus, FILTER_FORM, PAGE } from './constants';
import { setUIStateAction } from '../core/actions';
import {
  missingInformationCountSelector,
  underReviewCountSelector,
  fullMembersCountSelector,
  migratedCountSelector, ignoreCountSelector
} from './selectors';
import { searchAction } from './actions';

const mapStateToProps = state => ({
  fullMembersCount: fullMembersCountSelector(state),
  underReviewCount: underReviewCountSelector(state),
  missingInformationCount: missingInformationCountSelector(state),
  migratedCount: migratedCountSelector(state),
  ignoreCount: ignoreCountSelector(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleProfileStatusCategory: (profileStatusCategory) => {
    const {
      role,
      country,
      dailyRateFrom,
      dailyRateTo,
      city,
      name
    } = ownProps;
    dispatch(setUIStateAction([SEARCH, PROFILE_STATUS_CATEGORY], profileStatusCategory));
    dispatch((searchAction(role, country, dailyRateFrom, dailyRateTo, city, 0, name)));
    dispatch(change(FILTER_FORM, PAGE, 0));
  }
});

const ProfileStatusCategories = (props) => {
  const {
    selectedProfileStatusCategory,
    toggleProfileStatusCategory,
    fullMembersCount,
    underReviewCount,
    missingInformationCount,
    migratedCount,
    ignoreCount
  } = props;
  return (
    <div className="row mb-3">
      <ul className="list-unstyled d-flex flex-fill naf-member-list-tabs flex-column flex-md-row">
        <li className="text-center flex-fill">
          <a
            onClick={(e) => {
              e.preventDefault();
              toggleProfileStatusCategory(ProfileStatus.FULL);
            }}
            className={`naf-member-list-full ${selectedProfileStatusCategory === ProfileStatus.FULL ? 'active' : ''}`}
            href="#"
          >
            {`Full members(${fullMembersCount})`}
          </a>
        </li>
        <li className="text-center flex-fill">
          <a
            onClick={(e) => {
              e.preventDefault();
              toggleProfileStatusCategory(ProfileStatus.UNDER_REVIEW);
            }}
            className={`naf-member-list-review ${selectedProfileStatusCategory === ProfileStatus.UNDER_REVIEW ? 'active' : ''}`}
            href="#"
          >
            {`Under Review(${underReviewCount})`}
          </a>
        </li>
        <li className="text-center flex-fill">
          <a
            onClick={(e) => {
              e.preventDefault();
              toggleProfileStatusCategory(ProfileStatus.MISSING_INFORMATION);
            }}
            className={`naf-member-list-missing ${selectedProfileStatusCategory === ProfileStatus.MISSING_INFORMATION ? 'active' : ''}`}
            // className="naf-member-list-full active"
            href="#"
          >
            {`Missing Information(${missingInformationCount})`}
          </a>
        </li>
        <li className="text-center flex-fill">
          <a
            onClick={(e) => {
              e.preventDefault();
              toggleProfileStatusCategory(ProfileStatus.MIGRATED);
            }}
            className={`naf-member-list-migrated ${selectedProfileStatusCategory === ProfileStatus.MIGRATED ? 'active' : ''}`}
            href="#"
          >
            {`Migrated(${migratedCount})`}
          </a>
        </li>
        <li className="text-center flex-fill">
          <a
            onClick={(e) => {
              e.preventDefault();
              toggleProfileStatusCategory(ProfileStatus.IGNORE);
            }}
            className={`naf-member-list-migrated ${selectedProfileStatusCategory === ProfileStatus.IGNORE ? 'active' : ''}`}
            href="#"
          >
            {`Ignore(${ignoreCount})`}
          </a>
        </li>
      </ul>
    </div>
  );
};


ProfileStatusCategories.propTypes = {
  selectedProfileStatusCategory: PropTypes.oneOf([
    ProfileStatus.FULL,
    ProfileStatus.MIGRATED,
    ProfileStatus.MISSING_INFORMATION,
    ProfileStatus.UNDER_REVIEW,
  ]),
  toggleProfileStatusCategory: PropTypes.func,
  fullMembersCount: PropTypes.number,
  underReviewCount: PropTypes.number,
  missingInformationCount: PropTypes.number,
  migratedCount: PropTypes.number,
  ignoreCount: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileStatusCategories);
