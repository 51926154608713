import { SUBMIT_FORM_SECTION } from './constants';


export const submitSectionFormAction = (values, section, flat = false) => ({
  type: SUBMIT_FORM_SECTION,
  payload: {
    data: values,
    section,
    flat
  }
});
