import React from 'react';
import PropTypes from 'prop-types';

const MoreInformationFieldEdit = ({
  label, input, meta, RenderEdit, fieldConfig
}) => (
  <React.Fragment>
    <div><strong>{label.toUpperCase()}:</strong></div>
    <RenderEdit input={input} meta={meta} fieldConfig={fieldConfig} />
  </React.Fragment>
);

MoreInformationFieldEdit.propTypes = {
  label: PropTypes.string,
  RenderEdit: PropTypes.func,
  meta: PropTypes.shape(),
  input: PropTypes.shape(),
  fieldConfig: PropTypes.arrayOf(PropTypes.shape())
};

export default MoreInformationFieldEdit;
