import { isNafEmail, isValidEmail } from '../../core/utils';
import { ContactUsFormFields } from '../constants';
import { SUCCESS } from '../../core/constants';

export const contactUsValidator = (values) => {
  const errors = {};
  Object.values(ContactUsFormFields).forEach((fieldName) => {
    if (!values.get(fieldName)) {
      errors[fieldName] = 'Required';
    }
  });
  const email = values.get(ContactUsFormFields.EMAIL);
  if (email && (!isValidEmail(email) || isNafEmail(email))) {
    errors.email = 'Invalid Email';
  }
  return errors;
};

export const contactUsModalMessageRenderer = (result) => {
  if (result === SUCCESS) {
    return 'Thank you for reaching out, one of our representatives will contact you shortly.';
  }
  return 'Failed to submit your message, please try again.';
};
