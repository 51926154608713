import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, formValueSelector, destroy } from 'redux-form/immutable';
import {
  FORGOT_PASSWORD_LITERAL,
  requestResetPasswordModalMessageRenderer,
  RESET_EMAIL_FIELD,
  REQUEST_RESET_PASSWORD
} from './constants';
import renderTextFormField from '../core/renderers/renderTextFormField';
import resetPasswordRequestValidator from './validators/resetPasswordRequestValidator';
import { requestResetPasswordAction } from './actions';
import NafModal from '../widgets/NafModal';
import { MAIN } from '../Layout/routePaths';
import {
  requestResetPasswordResultSelector,
  showRequestResetPasswordResultModalSelector,
  isRequestResetPasswordBtnDisabledSelector
} from './selectors';
import { clearUIStateAction } from '../core/actions';

const ResetEmailField = renderTextFormField(RESET_EMAIL_FIELD);

const mapStateToProps = state => ({
  email: formValueSelector(REQUEST_RESET_PASSWORD)(state, RESET_EMAIL_FIELD),
  shouldShowResultModal: showRequestResetPasswordResultModalSelector(state),
  requestResetPasswordResult: requestResetPasswordResultSelector(state),
  isRequestResetPasswordBtnDisabled: isRequestResetPasswordBtnDisabledSelector(state)
});

const mapDispatchToProps = (dispatch, { history }) => ({
  handleResetPassRequest: values => dispatch(requestResetPasswordAction(values.get(RESET_EMAIL_FIELD))),
  modalBtnCLickHandler: () => {
    dispatch(clearUIStateAction());
    history.push(MAIN);
    dispatch(destroy(REQUEST_RESET_PASSWORD));
  }
});

const RequestResetPassword = (props) => {
  const {
    handleSubmit,
    handleResetPassRequest,
    shouldShowResultModal,
    email,
    modalBtnCLickHandler,
    requestResetPasswordResult,
    isRequestResetPasswordBtnDisabled
  } = props;
  return (
    <div id="naf-header-register" className="container-fluid naf-bg-header d-flex flex-column justify-content-center">
      <main role="main" className="container d-flex justify-content-center">
        <div className="d-flex flex-column">
          <div className="d-flex flex-column naf-reg-box-wrap">
            <div className="naf-reg-box">
              <h2>{FORGOT_PASSWORD_LITERAL}</h2>
              <div className="px-5 py-2">
                <p>
                  Please enter your registered email to receive reset password instructions.
                </p>
              </div>
              <form onSubmit={handleSubmit(handleResetPassRequest)}>
                <Field
                  name={RESET_EMAIL_FIELD}
                  component={ResetEmailField}
                />
                <div className="form-group d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn naf-btn-red naf-btn-wide mt-3"
                    disabled={isRequestResetPasswordBtnDisabled}
                  >
                    SEND ME INSTRUCTIONS
                  </button>
                </div>
                {shouldShowResultModal &&
                <NafModal
                  title={FORGOT_PASSWORD_LITERAL}
                  message={requestResetPasswordModalMessageRenderer(requestResetPasswordResult, email)}
                  leftBtnClickHandler={modalBtnCLickHandler}
                  leftBtnLabel="OK"
                  type={requestResetPasswordResult}
                />}
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

RequestResetPassword.propTypes = {
  handleSubmit: PropTypes.func,
  handleResetPassRequest: PropTypes.func,
  modalBtnCLickHandler: PropTypes.func,
  shouldShowResultModal: PropTypes.bool,
  isRequestResetPasswordBtnDisabled: PropTypes.bool,
  requestResetPasswordResult: PropTypes.string,
  email: PropTypes.string,
};

const ResetPasswordRequestContainer = connect(mapStateToProps, mapDispatchToProps)(RequestResetPassword);

export default reduxForm({
  form: REQUEST_RESET_PASSWORD,
  validate: resetPasswordRequestValidator
})(withRouter(ResetPasswordRequestContainer));
