import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NafHoverableLiView from './NafHoverableLiView';
import NafHoverableLiEdit from './NafHoverableLiEdit';
import { UI } from '../../../../core/constants/index';

const mapStateToProps = (state, { input: { name }, meta: { form } }) => ({
  inEditMode: state.getIn([UI, form, 'edit']) === name
});

const NafHoverableLi = props => (
  props.inEditMode
    ? <NafHoverableLiEdit {...props} />
    : <NafHoverableLiView {...props} />
);

NafHoverableLi.propTypes = {
  inEditMode: PropTypes.bool
};

export default connect(mapStateToProps)(NafHoverableLi);
