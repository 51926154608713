import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import MoreInformationField from './MoreInformationFields/ButtonSubmittedField/MoreInformationField';
import Buttons from './MoreInformationFields/ButtonSubmittedField/Buttons';
import validations from '../../../core/utils/validations';

const RenderEdit = ({ input, meta }) => (
  <React.Fragment>
    <textarea className={`form-control naf-v-resize mb-1 ${meta.error && 'is-invalid'}`} rows={6} {...input}>{input.value}</textarea>
    <div className="invalid-feedback">{meta.error}</div>
    <div className="d-flex justify-content-end">
      <Buttons />
    </div>
  </React.Fragment>
);

RenderEdit.propTypes = {
  input: PropTypes.shape(),
  meta: PropTypes.shape()
};

const RenderView = ({ input }) => (
  <p>{input.value}</p>
);

RenderView.propTypes = {
  input: PropTypes.shape()
};

const maxLengthValidator = validations.maxLength(1000);

const CountryEventInformation = () => (
  <Field
    validate={[
      maxLengthValidator
    ]}
    label="ARE ANY FILM/BROADCAST FESTIVALS HELD IN YOUR COUNTRY/REGION"
    name="countryEventInformation"
    component={MoreInformationField}
    RenderView={RenderView}
    RenderEdit={RenderEdit}
  />
);

export default CountryEventInformation;
