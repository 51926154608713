/**
 * The key used to store the auth-token in local storage.
 */
export const tokenStorageKey = 'token';

/**
 * Responsible to manage the authentication token.
 * The local storage is used to store the token in case of remember me login,
 * else the token is stored in sessionStorage.
 */
export default (() => (
  {
    storeAuthToken(token, rememberMe) {
      if (rememberMe) {
        localStorage.setItem(tokenStorageKey, token);
      } else {
        sessionStorage.setItem(tokenStorageKey, token);
      }
    },
    getAuthToken() {
      return localStorage.getItem(tokenStorageKey) || sessionStorage.getItem(tokenStorageKey);
    },
    clearAuthToken() {
      localStorage.removeItem(tokenStorageKey);
      sessionStorage.removeItem(tokenStorageKey);
    }
  }
))();
