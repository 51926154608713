/* eslint-disable react/prop-types */
import React from 'react';
import {
  formValueSelector,
  fieldInputPropTypes,
  fieldMetaPropTypes,
  change,
  submit
} from 'redux-form/immutable';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setUIStateAction } from '../../core/actions';
import { editableProfileFieldSelector, sectionFieldSelector } from '../selectors';
import { labelize } from '../../core/utils/StringFormatters';
import {
  EDIT_MODE,
  GENERAL_INFORMATION,
  GeneralInformationFields,
  HIDE_MODE,
  VIEW_MODE
} from '../constants';
import { MODAL, SUBMITTING_FIELD } from '../../core/constants';
import GeneralInformationViewField from './GeneralInformationViewField';
import GeneralInformationEditTextField from './GeneralInformationEditTextField';
import DailyRateInlineFields from './DailyRateInlineFields';
import GeneralInformationRolesMultiSelect from './GeneralInformationRolesMultiSelect';
import GeneralInformationCountriesDropDown from './GeneralInformationCountriesDropDown';


const mapStateToProps = (state, { input }) => ({
  mode: editableProfileFieldSelector(state, GENERAL_INFORMATION, input.name),
  fieldValue: formValueSelector(GENERAL_INFORMATION)(state, input.name),
  storedFieldValue: sectionFieldSelector(state, GENERAL_INFORMATION, input.name)
});

const mapDispatchToProps = (dispatch, { input }) => ({
  toggle: desiredMode => dispatch(setUIStateAction(`${GENERAL_INFORMATION}-${input.name}`, desiredMode)),
  updateSection: (val, fieldName) => {
    dispatch(setUIStateAction(`${SUBMITTING_FIELD}-${GENERAL_INFORMATION}`, input.name));
    dispatch(submit(GENERAL_INFORMATION));
    if ((fieldName === GeneralInformationFields.ROLES && !val.length) || (fieldName === GeneralInformationFields.DAILY_RATE && !val)) {
      dispatch(setUIStateAction([GENERAL_INFORMATION, MODAL], true));
    }
  },
  revertTo: (storedFieldValue) => {
    dispatch(change(GENERAL_INFORMATION, input.name, storedFieldValue));
    dispatch(setUIStateAction(`${GENERAL_INFORMATION}-${input.name}`, VIEW_MODE));
  }
});

const EditComponentRenderer = (props) => {
  switch (props.input.name) {
    case GeneralInformationFields.DAILY_RATE:
      return <DailyRateInlineFields {...props} />;
    case GeneralInformationFields.ROLES:
      return <GeneralInformationRolesMultiSelect {...props} />;
    case GeneralInformationFields.COUNTRY:
      return <GeneralInformationCountriesDropDown {...props} />;
    default:
      return <GeneralInformationEditTextField {...props} />;
  }
};

EditComponentRenderer.propTypes = {
  input: PropTypes.shape()
};

const EditableProfileField = (props) => {
  const {
    mode,
    input,
    toggle,
    meta,
    updateSection,
    fieldValue,
    storedFieldValue,
    revertTo
  } = props;
  if (mode === HIDE_MODE) {
    return (<span />);
  }
  return (
    <div className={`d-flex justify-content-start align-items-center ${mode === VIEW_MODE ? 'naf-hoverable' : ''} naf-edit-row`}>
      <span className={`mr-2 ${input.name === GeneralInformationFields.DAILY_RATE ? 'nowrap' : ''}`}>{input && labelize(input.name)}</span>
      <div>
        {
          mode === VIEW_MODE &&
          <GeneralInformationViewField
            fieldName={input.name}
            value={storedFieldValue}
          />
        }
        { mode === EDIT_MODE &&
          <div className="input-group naf-input-group">
            { EditComponentRenderer(props)}
            {input.name !== GeneralInformationFields.DAILY_RATE &&
            <div
              className="input-group-append"
            >
              <button
                className={`btn btn-outline-success naf-icon-btn ${GeneralInformationFields.COUNTRY === input.name ? 'naf-short-ctrl' : ''}`}
                type="submit"
                onClick={(e) => {
                  if (fieldValue !== storedFieldValue) {
                    e.preventDefault();
                    updateSection(fieldValue, input.name);
                  } else {
                    toggle(VIEW_MODE);
                  }
                }}
              >
                <span className="naf-icon-check" />
              </button>
              <button
                className={`btn btn-outline-danger naf-icon-btn ${GeneralInformationFields.COUNTRY === input.name ? 'naf-short-ctrl' : ''}`}
                type="button"
                onClick={() => revertTo(storedFieldValue)}
              >
                <span className="naf-icon-times" />
              </button>
            </div>
            }
            {meta && meta.touched && meta.error &&
              <div className="invalid-feedback">{meta && meta.error}</div>
            }
          </div>
        }
      </div>
    </div>
  );
};

EditableProfileField.propTypes = {
  mode: PropTypes.string,
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  storedFieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  input: PropTypes.shape(fieldInputPropTypes),
  meta: PropTypes.shape(fieldMetaPropTypes),
  toggle: PropTypes.func,
  revertTo: PropTypes.func,
  updateSection: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(EditableProfileField);
