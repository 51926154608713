import React, { Fragment } from 'react';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { errorStyle, fieldHasError } from './utils';

momentLocalizer(moment);

const renderDatePickerField = label => field => (
  <Fragment>
    <label htmlFor={`edit-exp-${field.input.name}`}>{ label }</label>
    <div style={fieldHasError(field) ? { border: '1px solid #dc3545' } : {}}>
      <DateTimePicker
        time={false}
        format="DD MMM YYYY"
        value={!field.input.value ? null : new Date(field.input.value)}
        onChange={field.input.onChange}
        id={`edit-exp-${field.input.name}`}
        dateIcon={<span className="fa fa-calendar" />}
      />
    </div>
    {
      fieldHasError(field) && <div style={errorStyle}>{ field.meta.error }</div>
    }
  </Fragment>
);

export default renderDatePickerField;
