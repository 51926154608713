import { fromJS, Map } from 'immutable';
import { SET_USER_ROLE } from '../actions';
import { ROLE } from '../constants';

export default (userState = Map(), action) => {
  if (!action) {
    return userState;
  }
  switch (action.type) {
    case SET_USER_ROLE:
      return userState
        .set(ROLE, fromJS(action.payload.role));
    default:
      return userState;
  }
};
