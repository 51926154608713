import React, { Fragment } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import renderTextField from './renderTextField';
import { required, requiredEmail, requiredPhoneNumber } from '../../validators';

const renderRefName = renderTextField('Name');
const renderRefTitle = renderTextField('Title');
const renderRefEmail = renderTextField('Email');
const renderRefContactNo = renderTextField('Contact number');

const renderReferences = ({ fields }) => (
  <Fragment>
    {
      fields.map((member, index) => (
        <Fragment>
          <div className="card naf-no-border-radius">
            <div className="card-body">
              <div className="form-row">
                <div className="form-group col-md-3">
                  <Field
                    name={`${member}.name`}
                    id={`${member}.name`}
                    component={renderRefName}
                    validate={required}
                  />
                </div>
                <div className="form-group col-md-3">
                  <Field
                    name={`${member}.title`}
                    id={`${member}.title`}
                    component={renderRefTitle}
                    validate={required}
                  />
                </div>
                <div className="form-group col-md-3">
                  <Field
                    name={`${member}.email`}
                    id={`${member}.email`}
                    component={renderRefEmail}
                    validate={requiredEmail}
                  />
                </div>
                <div className="form-group col-md-3">
                  <Field
                    name={`${member}.contactNumber`}
                    id={`${member}.contactNumber`}
                    component={renderRefContactNo}
                    validate={requiredPhoneNumber}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between flex-md-row flex-column">
            <span />
            <div className="d-flex justify-content-center justify-content-md-end naf-input-group">
              <button
                className="btn btn-outline-danger naf-icon-btn mr-3"
                type="button"
                onClick={() => fields.remove(index)}
              >
                <span className="naf-icon-times" />
              </button>
            </div>
          </div>
        </Fragment>
      ))
    }
    <div className="d-flex justify-content-between flex-md-row flex-column">
      <button
        type="button"
        className="btn btn-link naf-edit-btn"
        onClick={() => fields.push(Map({}))}
      >
        <span className="fa fa-plus" />
        ADD NEW REFERENCE
      </button>
    </div>
  </Fragment>
);

renderReferences.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape)
};

export default renderReferences;
