import React from 'react';

const socialMediaTextField = config => field => (
  <div className="form-group">
    <div className="input-group">
      <div className="input-group-prepend">
        <span className={`input-group-text naf-input-group-text naf-input-group-text--${config.abbreviation}`}>
          <span className={`fa fa-fw fa-${config.icon}`} />
        </span>
      </div>
      <input
        {...field.input}
        type="text"
        className="form-control"
      />
    </div>
  </div>
);

export default socialMediaTextField;
