import moment from 'moment';
import {
  GENERAL_INFORMATION,
  ME,
  PROFILE,
  BIO,
  SKILLS,
  INTERESTS,
  ATTACHMENTS,
  LANGUAGES,
  MORE_INFORMATION,
  MISSING_INFORMATION,
  AVATAR,
  GeneralInformationFields,
  PROJECTS,
  HIDE_ADD_PRJ_BTN,
  SHOW_SUBMIT_CANCEL_PRJ_BTN,
  HIDE_SUBMIT_CANCEL_PRJ_BTN,
  SHOW_SOCIAL_MEDIA_POPUP,
  SOCIAL_MEDIA,
  CREATED_DATE,
  PROFILE_STATUS,
  ACCOUNT_EMAIL,
  UUID, INVOICES,
  CV, IS_UNDER_REVIEW, NOTES, INVOICE_REQUESTS
} from '../constants';
import {
  ACCOUNT,
  ADD,
  DEL_MODAL_INDEX,
  DELETE, EMAIL, HIDE_CONTROLS,
  MAIN_SECTION,
  MODAL, MODAL_STATUS, MODAL_UPDATING,
  MODE, PASSWORD,
  SUBMITTING_FIELD,
  UI,
  UPDATE
} from '../../core/constants';

export const generalInformationSelector = state => state.getIn([PROFILE, ME, GENERAL_INFORMATION]);
export const projectsSelector = state => state.getIn([PROFILE, ME, PROJECTS]);
export const socialMediaSelector = state => state.getIn([PROFILE, ME, SOCIAL_MEDIA]);
export const nameSelector = state => state.getIn([PROFILE, ME, GENERAL_INFORMATION, GeneralInformationFields.NAME]);
export const missingInformationSelector = state => state.getIn([PROFILE, ME, MISSING_INFORMATION, MISSING_INFORMATION]);
export const renderedMainSectionSelector = state => state.getIn([UI, MAIN_SECTION]);
export const addNewPrjBtnUISelector = state => state.getIn([UI, PROJECTS, ADD, HIDE_ADD_PRJ_BTN]);
export const addNewPrjFormUISelector = state => state.getIn([UI, PROJECTS, ADD, MODE]);
export const confirmProjectDeleteModalUISelector = state => state.getIn([UI, PROJECTS, DELETE, MODAL]);
export const showSubmitCancelNewPrjBtnUISelector = state => state.getIn([UI, PROJECTS, ADD, SHOW_SUBMIT_CANCEL_PRJ_BTN]);
export const submitCancelUpdatePrjBtnUISelector = (state, index) => state.getIn([UI, PROJECTS, UPDATE, HIDE_SUBMIT_CANCEL_PRJ_BTN, index]);
export const modalIndexUIStateSelector = state => state.getIn([UI, PROJECTS, DEL_MODAL_INDEX]);
export const hideProjectUpdateControlsUIStateSelector = (state, index) => state.getIn([UI, PROJECTS, UPDATE, HIDE_CONTROLS, index]);
export const projectUpdateIndexModeUIStateSelector = (state, index) => state.getIn([UI, PROJECTS, UPDATE, MODE, index]);
export const missingInformationUIStateSelector = state => state.getIn([UI, MISSING_INFORMATION]);
export const socialMediaUIStateSelector = state => state.getIn([UI, SHOW_SOCIAL_MEDIA_POPUP]);
export const bioSelector = state => state.getIn([PROFILE, ME, BIO]);
export const bioUiStateSelector = state => state.getIn([UI, BIO]);
export const sectionFieldSelector = (state, section, field) => state.getIn([PROFILE, ME, section, field]);
export const generalInformationCurrencySelector = state => state.getIn([PROFILE, ME, GENERAL_INFORMATION, GeneralInformationFields.DAILY_RATE, GeneralInformationFields.CURRENCY]);
export const generalInformationSubmittingFieldSelector = state => state.getIn([UI, `${SUBMITTING_FIELD}-${GENERAL_INFORMATION}`]);
export const skillsSelector = state => state.getIn([PROFILE, ME, SKILLS]);
export const invoicesSelector = state => state.getIn([PROFILE, ME, INVOICES]);
export const invoiceRequestsSelector = state => state.getIn([PROFILE, ME, INVOICE_REQUESTS]);
export const interestsSelector = state => state.getIn([PROFILE, ME, INTERESTS]);
export const attachmentsSelector = state => state.getIn([PROFILE, ME, ATTACHMENTS]);
export const languagesSelector = state => state.getIn([PROFILE, ME, LANGUAGES]);
export const moreInformationSelector = state => state.getIn([PROFILE, ME, MORE_INFORMATION]);
export const avatarSelector = state => state.getIn([PROFILE, ME, AVATAR]);
export const editableProfileFieldSelector = (state, section, name) =>
  state.getIn([UI, `${section}-${name}`]);
export const createdDateSelector = state => state.getIn([PROFILE, ME, CREATED_DATE])
  && moment(state.getIn([PROFILE, ME, CREATED_DATE])).format('DD/MM/YYYY');
export const profileStatusSelector = state => state.getIn([PROFILE, ME, PROFILE_STATUS]);
export const shouldShowUnderReviewBannerSelector = state => state.getIn([UI, IS_UNDER_REVIEW]);
export const existingAccountEmailSelector = state => state.getIn([PROFILE, ME, ACCOUNT_EMAIL]);
export const accountEmailModeUIStateSelector = state => state.getIn([UI, ACCOUNT, EMAIL]);
export const accountEmailChangeModalUISelector = state => state.getIn([UI, ACCOUNT, MODAL]);
export const accountEmailModalStatusSelector = state => state.getIn([UI, ACCOUNT, MODAL_STATUS]);
export const accountEmailModalUpdatingSelector = state => state.getIn([UI, ACCOUNT, MODAL_UPDATING]);
export const accountUUIDSelector = state => state.getIn([PROFILE, ME, UUID]);
export const accountPasswordModeUIStateSelector = state => state.getIn([UI, ACCOUNT, PASSWORD]);
export const cvSelector = state => state.getIn([PROFILE, ME, CV]);
export const fieldRemovalWarningModalUISelector = state => state.getIn([UI, GENERAL_INFORMATION, MODAL]);
export const notesSelector = state => state.getIn([PROFILE, ME, NOTES]);
export const notesUiStateSelector = state => state.getIn([UI, NOTES]);
