/* eslint-disable jsx-a11y/anchor-is-valid,no-confusing-arrow */
import React from 'react';
import PropTypes from 'prop-types';
import ResultListView from './ResultListView';
import { CARDS, LIST } from './constants';
import ResultCardView from './ResultCardView';

const renderResults = (data, resultsViewMode) => data.map((userData, index) => resultsViewMode === LIST
  ? <ResultListView key={index} userData={userData} />
  : <ResultCardView key={index} userData={userData} />);

const ResultsView = ({ data, resultsViewMode }) => (
  data && data.size > 0 ?
    <div id="search-results" className="row mb-2">
      { renderResults(data.toJS(), resultsViewMode) }
    </div>
    : <h3>No results</h3>
);

ResultsView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  resultsViewMode: PropTypes.oneOf([CARDS, LIST])
};

export default ResultsView;
