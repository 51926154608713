import { Map, fromJS } from 'immutable';
import {
  SET_PROFILE,
  SET_GENERAL_INFORMATION,
  UPDATE_BIO,
  UPDATE_SECTION,
  SET_MISSING_INFORMATION,
  SET_PROJECTS,
  SET_SOCIAL_MEDIA, SET_ACCOUNT_EMAIL, UPDATE_NOTES
} from '../actions';
import { ACCOUNT_EMAIL, BIO, GENERAL_INFORMATION, ME, MISSING_INFORMATION, NOTES, SOCIAL_MEDIA } from '../constants';
import { PROJECTS } from '../../core/constants';

export default (profileState = Map(), action) => {
  if (!action) {
    return profileState;
  }
  switch (action.type) {
    case SET_PROFILE:
      return profileState
        .set(ME, fromJS(action.payload.profile));
    case SET_MISSING_INFORMATION:
      return profileState
        .setIn([ME, MISSING_INFORMATION], action.payload.missingInformation);
    case SET_GENERAL_INFORMATION:
      return profileState
        .setIn([ME, GENERAL_INFORMATION], fromJS(action.payload.generalInformation));
    case SET_PROJECTS:
      return profileState
        .setIn([ME, PROJECTS], fromJS(action.payload.projects));
    case SET_ACCOUNT_EMAIL:
      return profileState
        .setIn([ME, ACCOUNT_EMAIL], fromJS(action.payload.email));
    case SET_SOCIAL_MEDIA:
      return profileState
        .setIn([ME, SOCIAL_MEDIA], fromJS(action.payload.socialMedia));
    case UPDATE_BIO:
      return profileState
        .setIn([ME, BIO], fromJS(action.payload.bio));
    case UPDATE_SECTION:
      return profileState
        .setIn([ME, action.payload.section], fromJS(action.payload.data));
    case UPDATE_NOTES:
      return profileState
        .setIn([ME, NOTES], fromJS(action.payload.notes));
    default:
      return profileState;
  }
};
