import { takeLatest, all, call, put } from 'redux-saga/effects';
import { REQUEST_RESET_PASSWORD } from '../actions';
import { authorizedRequest, unauthorizedRequest } from '../../core/restClient';
import { setUIStateAction } from '../../core/actions';
import { ERROR, SUCCESS } from '../../core/constants';
import {
  REQUEST_RESET_PASSWORD_BTN_DISABLED,
  REQUEST_RESET_PASSWORD_RESULT,
  REQUEST_RESET_PASSWORD_RESULT_MODAL_VISIBLE,
  SET_NEW_PASSWORD_SUBMIT
} from '../constants';

function* handleResetPasswordRequest(action) {
  const { email } = action.payload;
  yield put(setUIStateAction(REQUEST_RESET_PASSWORD_BTN_DISABLED, true));
  try {
    yield call(authorizedRequest, 'GET', `/password-manager/request?email=${email}`);
    yield put(setUIStateAction(REQUEST_RESET_PASSWORD_RESULT, SUCCESS));
    yield put(setUIStateAction(REQUEST_RESET_PASSWORD_RESULT_MODAL_VISIBLE, true));
  } catch (err) {
    yield put(setUIStateAction(REQUEST_RESET_PASSWORD_RESULT, ERROR));
    yield put(setUIStateAction(REQUEST_RESET_PASSWORD_RESULT_MODAL_VISIBLE, true));
  }
}

function* handleSetNewPasswordRequest(action) {
  try {
    yield call(unauthorizedRequest, 'post', '/password-manager/confirm', action.payload.values);
  } catch (err) {
    console.log(err);
  } finally {
    action.payload.history.push('/');
  }
}

export default function* watchResetPasswordRequests() {
  yield all([
    takeLatest(REQUEST_RESET_PASSWORD, handleResetPasswordRequest),
    takeLatest(SET_NEW_PASSWORD_SUBMIT, handleSetNewPasswordRequest)
  ]);
}

