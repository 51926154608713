import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ContactUsForm from './ContactUsForm';
import NafModal from '../widgets/NafModal';
import {
  contactUsRequestResultSelector,
  showContactUsResultModalSelector
} from './selectors';
import { CONTACT_US_RESULT_MODAL_TITLE, CONTACT_US_RESULT_MODAL_VISIBLE } from './constants';
import { contactUsModalMessageRenderer } from './utils';
import { setUIStateAction } from '../core/actions';
import { MAIN } from '../Layout/routePaths';

const mapStateToProps = state => ({
  shouldShowResultModal: showContactUsResultModalSelector(state),
  contactUsRequestResult: contactUsRequestResultSelector(state)
});

const mapDispatchToProps = (dispatch, { history }) => ({
  modalBtnCLickHandler: () => {
    dispatch(setUIStateAction(CONTACT_US_RESULT_MODAL_VISIBLE, false));
    history.push(MAIN);
  }
});

const ContactUs = (props) => {
  const {
    shouldShowResultModal,
    contactUsRequestResult,
    modalBtnCLickHandler
  } = props;
  return (
    <div id="naf-header-register" className="container-fluid naf-bg-header d-flex flex-column justify-content-center">
      <main role="main" className="container d-flex justify-content-center">
        <div className="naf-reg-box-wrap">
          <div className="naf-reg-box">
            <h2>Contact us</h2>
            <ContactUsForm />
          </div>
        </div>
      </main>
      { shouldShowResultModal &&
      <NafModal
        title={CONTACT_US_RESULT_MODAL_TITLE}
        message={contactUsModalMessageRenderer(contactUsRequestResult)}
        leftBtnClickHandler={modalBtnCLickHandler}
        leftBtnLabel="OK"
        type={contactUsRequestResult}
      /> }
    </div>
  );
};

ContactUs.propTypes = {
  shouldShowResultModal: PropTypes.bool,
  modalBtnCLickHandler: PropTypes.func,
  contactUsRequestResult: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactUs));
