import { Map } from 'immutable';

export const SET_AUTH = 'set_auth';
export const CLEAR_AUTH = 'clear_auth';

export const authReducer = (state = Map(), action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case SET_AUTH:
      return state.set('authenticated', true);
    case CLEAR_AUTH:
      return Map();
    default:
      return state;
  }
};
