/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DefaultAvatar from '../../resources/media/avatar/default-avatar.png';
import { dailyRateFormatter, rolesFormatter } from './formatters';
import { handleSelectProfileAction } from './actions';

const mapDispatchToProps = dispatch => ({
  handleViewProfile: (uuid, history) => dispatch(handleSelectProfileAction(uuid, history))
});


const ResultCardView = ({ userData, history, handleViewProfile }) => {
  const { avatar, uuid } = userData;
  const {
    name,
    roles,
    dailyRate,
    country,
    city
  } = userData.generalInformation;
  return (
    <div className="col-12 col-md-6 mb-4">
      <div className="row">
        <div
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.preventDefault();
            handleViewProfile(uuid, history);
          }}
          className="col-xl-4 naf-profile-photo-wrapper"
        >
          <div
            className="naf-profile-photo-sm"
            style={{ backgroundImage: avatar ? `url(static/${avatar})` : `url(${DefaultAvatar})` }}
          />
        </div>
        <div className="col-xl-8">
          <div className="row naf-full-height">
            <div className="naf-profile-fields">
              <div className="d-flex naf-full-height flex-column justify-content-between">
                <div className="p-3">
                  <dl className="row">
                    <dt className="col-4">NAME </dt>
                    <dd
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleViewProfile(uuid, history);
                      }}
                      className="col-8"
                    >
                      { name || '' }
                    </dd>
                    <dt className="col-4">ROLES </dt>
                    <dd className="col-8">{ rolesFormatter(roles) }</dd>
                    <dt className="col-4">DAILY RATE </dt>
                    <dd className="col-8">{ dailyRateFormatter(dailyRate) }</dd>
                    <dt className="col-4">COUNTRY </dt>
                    <dd className="col-8">{ country || '' }</dd>
                    <dt className="col-4">CITY </dt>
                    <dd className="col-8">{ city || '' }</dd>
                  </dl>
                </div>
                <button className="btn btn-default naf-btn-red-deep align-self-end" onClick={() => handleViewProfile(uuid, history)}>VIEW PROFILE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ResultCardView.propTypes = {
  userData: PropTypes.shape,
  history: PropTypes.shape,
  handleViewProfile: PropTypes.func
};

export default connect(null, mapDispatchToProps)(withRouter(ResultCardView));
