import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FieldArray, Field, reduxForm } from 'redux-form/immutable';
import NafCard from '../../NafCard/NafCard';
import NafHoverableLi from '../FieldType/NafHoverableLi/NafHoverableLi';
import Spinner from '../../Spinner';
import AddNewButton from '../../NafCard/AddNewButton';
import { setUIStateAction } from '../../../core/actions/index';
import { UI } from '../../../core/constants/index';
import { submitSectionFormAction } from '../actions';
import validations from '../../../core/utils/validations';

export const submitSectionForm = (section, flat) => (values, dispatch) => dispatch(submitSectionFormAction(values.toJS(), section, flat));


const maxLengthValidator = validations.maxLength(40);

const SectionField = ({
  name, index, fields, placeholder, RenderFieldView, RenderFieldEdit
}) => (
  <Field
    validate={[
      maxLengthValidator,
      validations.required
    ]}
    RenderFieldEdit={RenderFieldEdit}
    RenderFieldView={RenderFieldView}
    fields={fields}
    placeholder={placeholder}
    editable
    deletable
    name={name}
    index={index}
    component={NafHoverableLi}
  />
);

SectionField.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  fields: PropTypes.shape(),
  placeholder: PropTypes.string,
  RenderFieldView: PropTypes.node,
  RenderFieldEdit: PropTypes.node
};

const RenderSection = ({
  fields, addNewCallback, allowAddNew, RenderFieldView, RenderFieldEdit, hasTwoColumns
}) => (
  <React.Fragment>
    <ul className={`naf-list-editing ${hasTwoColumns ? 'naf-list-two-col' : ''}`}>
      {
        fields.map((name, index) => <SectionField fields={fields} name={name} key={index} index={index} RenderFieldView={RenderFieldView} RenderFieldEdit={RenderFieldEdit} />)
      }
    </ul>
    <AddNewButton callBack={() => addNewCallback(fields, allowAddNew)} />
  </React.Fragment>
);

RenderSection.propTypes = {
  fields: PropTypes.shape(),
  allowAddNew: PropTypes.bool,
  addNewCallback: PropTypes.func,
  RenderFieldView: PropTypes.node,
  RenderFieldEdit: PropTypes.node,
  hasTwoColumns: PropTypes.bool
};

const mapStateToPros = (state, { section }) => ({
  userIsAddingNewField: !!state.getIn([UI, section, 'add'])
});

const mapDispatchToProps = dispatch => ({
  handleAddNewSection: section => (fields, allowAddNew) => {
    const data = fields.getAll() && fields.getAll().toArray();
    const sectionContainsEmptyField = data && (data.includes(undefined) || data.find(el => typeof el === 'object' && (!el.get('name') || !el.get('languageProficiency'))));
    if (!allowAddNew || sectionContainsEmptyField) {
      return;
    }
    const newSectionFieldName = `${section}[${fields.length}]`;
    dispatch(setUIStateAction([section, 'add'], newSectionFieldName));
    fields.push();
    dispatch(setUIStateAction([section, 'edit'], newSectionFieldName));
  }
});

const FieldArraySection = ({
  handleAddNewSection, userIsAddingNewField, section, RenderFieldView, RenderFieldEdit
}) => (
  <NafCard
    outerClassName="card naf-card col-md-6"
    title={section.toUpperCase()}
  >
    <Spinner element={section} />
    <FieldArray
      RenderFieldEdit={RenderFieldEdit}
      RenderFieldView={RenderFieldView}
      allowAddNew={!userIsAddingNewField}
      addNewCallback={handleAddNewSection(section)}
      id={section}
      name={section}
      component={RenderSection}
    />
  </NafCard>
);

FieldArraySection.propTypes = {
  section: PropTypes.string,
  RenderFieldEdit: PropTypes.node,
  RenderFieldView: PropTypes.node,
  handleAddNewSection: PropTypes.func,
  userIsAddingNewField: PropTypes.bool
};

export default section => reduxForm({
  form: section,
  enableReinitialize: true,
  onSubmit: submitSectionForm(section)
})(connect(mapStateToPros, mapDispatchToProps)(props => <FieldArraySection section={section} {...props} />));
