import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SocialMediaForm from './SocialMediaForm';
import { updateSocialMediaAction } from '../actions';
import { socialMediaSelector } from '../selectors';

const mapStateToProps = state => ({
  socialMedia: socialMediaSelector(state)
});

const SocialMediaUpsertPopUp = ({ socialMedia }) => (
  <div className="naf-modal naf-modal-upload naf-show-modal">
    <div className="naf-modal-content">
      <div className="naf-modal-header">
        <h3>ADD MORE SOCIAL NETWORK LINKS</h3>
      </div>
      <div className="naf-modal-body naf-social-media">
        <SocialMediaForm
          onSubmit={(formValues, dispatch) => {
            dispatch(updateSocialMediaAction(formValues));
          }}
          initialValues={socialMedia}
        />
      </div>
    </div>
  </div>
);

SocialMediaUpsertPopUp.propTypes = {
  socialMedia: PropTypes.shape
};

export default connect(mapStateToProps)(SocialMediaUpsertPopUp);
