import { Switch } from 'react-router';
import React, { Fragment } from 'react';
import Header from '../Layout/Header/Header';
import Login from '../Login/Login';
import Register from '../Register/Register';
import Footer from './Footer';
import Main from './Main';
import MainSection from '../Profile/MainSection';
import SetNewPassword from '../ResetPassword/SetNewPasswordForm';
import {
  MAIN,
  TERMS,
  PROFILE,
  REGISTER,
  FORGOT_PASSWORD,
  LOGIN,
  EMAIL_CONFIRMATION,
  CONTACT_US,
  SET_NEW_PASSWORD, ACCOUNT_EMAIL_UPDATE, FACEBOOK_LOGIN_REDIRECT_PATH, GOOGLE_LOGIN_REDIRECT_PATH
} from './routePaths';
import ResetPasswordRequestForm from '../ResetPassword/RequestResetPasswordForm';
import { AuthenticatedRoute, UnauthenticatedRoute } from '../core/routing';
import Terms from '../Terms/Terms';
import ContactUs from '../ContactUs/ContactUs';
import AuthTokenHandler from '../core/auth/authTokenHandler';
import FourOFourPage from './FourOFourPage';
import ErrorBoundary from '../core/ErrorBoundary';
import Search from '../Search/Search';
import DirectProfileLink from '../DirectProfileLink/DirectProfileLink';
import Oauth2WaitingScreen from '../Login/Oauth2WaitingScreen';

const Layout = () => (
  <Fragment>
    <Header token={AuthTokenHandler.getAuthToken()} />
    <ErrorBoundary>
      <Switch>
        <UnauthenticatedRoute
          path={MAIN}
          exact
          component={Main}
          redirectOnAuthenticated
          token={AuthTokenHandler.getAuthToken()}
        />
        <UnauthenticatedRoute
          redirectOnAuthenticated
          exact
          path={FACEBOOK_LOGIN_REDIRECT_PATH}
          component={Oauth2WaitingScreen}
        />
        <UnauthenticatedRoute
          redirectOnAuthenticated
          exact
          path={GOOGLE_LOGIN_REDIRECT_PATH}
          component={Oauth2WaitingScreen}
        />
        <UnauthenticatedRoute
          path={TERMS}
          exact
          component={Terms}
          token={AuthTokenHandler.getAuthToken()}
        />
        { /* TODO Create contact us form page */ }
        <UnauthenticatedRoute
          path={CONTACT_US}
          exact
          component={ContactUs}
          token={AuthTokenHandler.getAuthToken()}
        />
        <UnauthenticatedRoute
          path={FORGOT_PASSWORD}
          exact
          component={ResetPasswordRequestForm}
          token={AuthTokenHandler.getAuthToken()}
        />
        <UnauthenticatedRoute
          redirectOnAuthenticated
          path={LOGIN}
          exact
          component={Login}
          token={AuthTokenHandler.getAuthToken()}
        />
        <UnauthenticatedRoute
          redirectOnAuthenticated
          path={REGISTER}
          exact
          component={Register}
          token={AuthTokenHandler.getAuthToken()}
        />
        <UnauthenticatedRoute
          path={EMAIL_CONFIRMATION}
          exact
          component={Register}
          redirectOnAuthenticated={false}
          token={AuthTokenHandler.getAuthToken()}
        />
        <UnauthenticatedRoute
          path={ACCOUNT_EMAIL_UPDATE}
          exact
          component={Register}
          redirectOnAuthenticated={false}
          token={AuthTokenHandler.getAuthToken()}
        />
        <UnauthenticatedRoute
          path={SET_NEW_PASSWORD}
          exact
          component={SetNewPassword}
          redirectOnAuthenticated={false}
          token={AuthTokenHandler.getAuthToken()}
        />
        <AuthenticatedRoute
          path={PROFILE}
          exact
          component={MainSection}
          token={AuthTokenHandler.getAuthToken()}
        />
        <AuthenticatedRoute
          path="/direct-link"
          exact
          component={DirectProfileLink}
          token={AuthTokenHandler.getAuthToken()}
        />
        <AuthenticatedRoute
          path="/search"
          exact
          component={Search}
          token={AuthTokenHandler.getAuthToken()}
        />
        <AuthenticatedRoute
          component={FourOFourPage}
          token={AuthTokenHandler.getAuthToken()}
        />
      </Switch>
    </ErrorBoundary>
    <Footer />
  </Fragment>
);

export default Layout;
