import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reset, submit } from 'redux-form/immutable';
import { NOTES, VIEW_MODE } from '../constants';
import { setUIStateAction } from '../../core/actions';
import Spinner from '../../widgets/Spinner';

const mapDispatchToProps = dispatch => ({
  onCancelButtonClick: () => {
    dispatch(reset(NOTES));
    dispatch(setUIStateAction(NOTES, VIEW_MODE));
  },
  onSubmitButtonClick: () => {
    dispatch(submit(NOTES));
  }
});

const NotesEdit = ({ onCancelButtonClick, onSubmitButtonClick }) => (
  <div className="card naf-card col-md-6">
    <div className="card-body">
      <h4 className="card-title"><span>NOTES</span></h4>
      <div className="naf-pos-relative">
        <Spinner element={NOTES} />
        <Field
          name={NOTES}
          className="form-control naf-v-resize mb-1"
          rows="6"
          component="textarea"
        />
        <div className="d-flex justify-content-end">
          <button className="btn btn-outline-success naf-icon-btn mr-1" type="button" onClick={() => onSubmitButtonClick()}><span
            className="naf-icon-check"
          />
          </button>
          <button className="btn btn-outline-danger naf-icon-btn mr-3" type="button" onClick={() => onCancelButtonClick()}><span
            className="naf-icon-times"
          />
          </button>
        </div>
      </div>
    </div>
  </div>
);

NotesEdit.propTypes = {
  onCancelButtonClick: PropTypes.func,
  onSubmitButtonClick: PropTypes.func
};

export default connect(null, mapDispatchToProps)(NotesEdit);
