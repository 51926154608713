import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteInvoiceAction, downloadAttachmentAction } from '../actions';

const InvoiceDocuments = ({
  handleDownload, invoiceDocument, openUploadFileModal
}) => {
  const { fileId, filename, contentType } = invoiceDocument;
  return (
    <span className="naf-hoverable">
      <span>{filename}</span>
      <span>
        <button type="button" className="btn btn-link naf-del-btn btn-sm pr-1" onClick={openUploadFileModal}><span
          className="naf-icon-edit"
        />
        </button>
        <button type="button" className="btn btn-link naf-save-btn btn-sm pl-1" onClick={() => handleDownload(fileId, filename, contentType)}><span
          className="naf-icon-download"
        />
        </button>
      </span>
    </span>
  );
};

InvoiceDocuments.propTypes = {
  invoiceDocument: PropTypes.shape({
    contentType: PropTypes.string,
    fileId: PropTypes.string,
    filename: PropTypes.string,
    ownerUuid: PropTypes.string
  }),
  handleDownload: PropTypes.func,
  openUploadFileModal: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  handleDownload: (fileId, fileName, contentType) => dispatch(downloadAttachmentAction(fileId, fileName, contentType)),
  handleDelete: invoiceId => dispatch(deleteInvoiceAction(invoiceId))
});

export default connect(null, mapDispatchToProps)(InvoiceDocuments);
