import { ERROR, SUCCESS } from '../../core/constants';

export const renderRegistrationVerificationModalMessage = (result) => {
  switch (result) {
    case SUCCESS:
      return 'Successful email verification';
    case ERROR:
      return 'Failed to verify email';
    default:
      return 'Processing...';
  }
};
