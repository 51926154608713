/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment } from 'react';
import { Field, reduxForm, change } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  cityFilterUIModeSelector,
  countryFilterUIModeSelector, nameFilterSelector,
  rateFilterUIModeSelector,
  roleFilterUIModeSelector,
} from './selectors';
import { EDIT, VIEW } from '../core/constants';
import {
  CITY, CITY_FILTER_PLACEHOLDER,
  COUNTRY,
  DAILY_RATE_FROM,
  DAILY_RATE_TO,
  FILTER_FORM,
  FILTERS, NAME, NAME_FILTER_PLACEHOLDER, PAGE,
  RATE,
  ROLE,
  SEARCH
} from './constants';
import { setUIStateAction } from '../core/actions';
import { dailyRateRangeFormatter } from './formatters';
import CountriesFilterSearchableDropDown from './formFields/CountriesFilterSearchableDropDown';
import RolesFilterSearchableDropDown from './formFields/RolesFilterSearchableDropDown';
import { searchAction } from './actions';
import { monetaryAmountNormalizer } from './utils';

const mapStateToProps = state => ({
  roleFilterMode: roleFilterUIModeSelector(state),
  countryFilterMode: countryFilterUIModeSelector(state),
  cityFilterMode: cityFilterUIModeSelector(state),
  rateFilterMode: rateFilterUIModeSelector(state),
  nameFilterMode: nameFilterSelector(state)
});

const mapDispatchToProps = dispatch => ({
  toggleRoleFilterMode: (mode) => {
    dispatch(setUIStateAction([SEARCH, FILTERS, ROLE], mode));
    if (mode === EDIT) {
      dispatch(setUIStateAction([SEARCH, FILTERS, COUNTRY], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, RATE], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, CITY], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, NAME], VIEW));
    }
  },
  toggleCountryFilterMode: (mode) => {
    dispatch(setUIStateAction([SEARCH, FILTERS, COUNTRY], mode));
    if (mode === EDIT) {
      dispatch(setUIStateAction([SEARCH, FILTERS, ROLE], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, RATE], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, CITY], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, NAME], VIEW));
    }
  },
  toggleCityFilterMode: (mode) => {
    dispatch(setUIStateAction([SEARCH, FILTERS, CITY], mode));
    if (mode === EDIT) {
      dispatch(setUIStateAction([SEARCH, FILTERS, ROLE], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, COUNTRY], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, RATE], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, NAME], VIEW));
    }
  },
  toggleRateFilterMode: (mode) => {
    dispatch(setUIStateAction([SEARCH, FILTERS, RATE], mode));
    if (mode === EDIT) {
      dispatch(setUIStateAction([SEARCH, FILTERS, ROLE], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, COUNTRY], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, CITY], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, NAME], VIEW));
    }
  },
  toggleNameFilterMode: (mode) => {
    dispatch(setUIStateAction([SEARCH, FILTERS, NAME], mode));
    if (mode === EDIT) {
      dispatch(setUIStateAction([SEARCH, FILTERS, ROLE], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, COUNTRY], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, CITY], VIEW));
      dispatch(setUIStateAction([SEARCH, FILTERS, RATE], VIEW));
    }
  },
  handleDailyRateSubmit: () => dispatch(setUIStateAction([SEARCH, FILTERS, RATE], VIEW)),
  handleNameSubmit: () => dispatch(setUIStateAction([SEARCH, FILTERS, NAME], VIEW)),
  handleDailyRateCancel: () => {
    dispatch(setUIStateAction([SEARCH, FILTERS, RATE], VIEW));
    dispatch(change(FILTER_FORM, DAILY_RATE_FROM, null));
    dispatch(change(FILTER_FORM, DAILY_RATE_TO, null));
  },
  handleCitySubmit: () => dispatch(setUIStateAction([SEARCH, FILTERS, CITY], VIEW)),
  handleCityCancel: () => {
    dispatch(setUIStateAction([SEARCH, FILTERS, CITY], VIEW));
    dispatch(change(FILTER_FORM, CITY, null));
  },
  handleNameCancel: () => {
    dispatch(setUIStateAction([SEARCH, FILTERS, NAME], VIEW));
    dispatch(change(FILTER_FORM, NAME, null));
  }
});

const FilterBar = (props) => {
  const {
    role,
    country,
    city,
    name,
    dailyRateFrom,
    dailyRateTo,
    roleFilterMode,
    countryFilterMode,
    nameFilterMode,
    cityFilterMode,
    rateFilterMode,
    toggleRoleFilterMode,
    toggleCountryFilterMode,
    toggleCityFilterMode,
    toggleRateFilterMode,
    handleDailyRateSubmit,
    handleCitySubmit,
    handleDailyRateCancel,
    handleCityCancel,
    handleNameSubmit,
    handleNameCancel,
    toggleNameFilterMode
  } = props;
  return (
    <Fragment>
      <h3 className="flex-grow-1">Filter Search Results</h3>
      <div className="d-flex justify-content-between flex-grow-1 flex-column flex-md-row">
        <div className={`dropdown ${roleFilterMode === EDIT ? 'show' : ''}`}>
          <button
            type="button"
            className="btn btn-link dropdown-toggle"
            onClick={() => toggleRoleFilterMode(roleFilterMode === VIEW ? EDIT : VIEW)}
          >
            { role }
          </button>
          <div className={`dropdown-menu dropdown-menu-right naf-dropdown-menu naf-dropdown-menu-wide ${roleFilterMode === EDIT ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
            <div className="form-row px-3 py-1">
              <div className="col-12">
                <Field
                  id={ROLE}
                  name={ROLE}
                  component={RolesFilterSearchableDropDown}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={`dropdown ${rateFilterMode === EDIT ? 'show' : ''}`}>
          <button
            type="button"
            className="btn btn-link dropdown-toggle"
            onClick={() => toggleRateFilterMode(rateFilterMode === VIEW ? EDIT : VIEW)}
          >
            { dailyRateRangeFormatter(dailyRateFrom, dailyRateTo) }
          </button>
          <div
            className={`dropdown-menu dropdown-menu-right naf-dropdown-menu naf-dropdown-menu-wide ${rateFilterMode === EDIT ? 'show' : ''}`}
            aria-labelledby="dropdownMenuButton"
          >
            <div className="form-row px-3 py-1">
              <div className="col-4">
                <Field
                  id={DAILY_RATE_FROM}
                  name={DAILY_RATE_FROM}
                  component="input"
                  type="text"
                  className="form-control mb-2 mr-sm-2"
                  normalize={monetaryAmountNormalizer}
                />
                <label className="mb-2 mr-sm-2" htmlFor={DAILY_RATE_FROM}>From</label>
              </div>
              <div className="col-4">
                <Field
                  id={DAILY_RATE_TO}
                  name={DAILY_RATE_TO}
                  component="input"
                  type="text"
                  className="form-control mb-2 mr-sm-2"
                  normalize={monetaryAmountNormalizer}
                />
                <label className="mb-2 mr-sm-2" htmlFor={DAILY_RATE_TO}>To</label>
              </div>
              <div className="col-4 d-flex align-items-start">
                <button
                  className="btn btn-outline-success naf-icon-btn"
                  type="button"
                  onClick={handleDailyRateSubmit}
                >
                  <span className="naf-icon-check" />
                </button>
                <button
                  className="btn btn-outline-danger naf-icon-btn"
                  type="button"
                  onClick={handleDailyRateCancel}
                >
                  <span className="naf-icon-times" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={`dropdown ${countryFilterMode === EDIT ? 'show' : ''}`}>
          <button
            type="button"
            className="btn btn-link dropdown-toggle"
            onClick={() => toggleCountryFilterMode(countryFilterMode === VIEW ? EDIT : VIEW)}
          >
            { country }
          </button>
          <div className={`dropdown-menu dropdown-menu-right naf-dropdown-menu naf-dropdown-menu-wide ${countryFilterMode === EDIT ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
            <div className="form-row px-3 py-1">
              <div className="col-12">
                <Field
                  id={COUNTRY}
                  name={COUNTRY}
                  component={CountriesFilterSearchableDropDown}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={`dropdown ${cityFilterMode === EDIT ? 'show' : ''}`}>
          <button
            type="button"
            className="btn btn-link dropdown-toggle"
            onClick={() => toggleCityFilterMode(cityFilterMode === VIEW ? EDIT : VIEW)}
          >
            { city || CITY_FILTER_PLACEHOLDER }
          </button>
          <div className={`dropdown-menu dropdown-menu-right naf-dropdown-menu naf-dropdown-menu-wide ${cityFilterMode === EDIT ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
            <div className="form-row px-3 py-1">
              <div className="col-8">
                <div className="input-group naf-input-group">
                  <Field
                    id={CITY}
                    name={CITY}
                    type="text"
                    component="input"
                    className="form-control mb-2 mr-sm-2"
                  />
                </div>
              </div>
              <div className="col-4 d-flex align-items-start">
                <button
                  className="btn btn-outline-success naf-icon-btn"
                  type="button"
                  onClick={handleCitySubmit}
                >
                  <span className="naf-icon-check" />
                </button>
                <button
                  className="btn btn-outline-danger naf-icon-btn"
                  type="button"
                  onClick={handleCityCancel}
                >
                  <span className="naf-icon-times" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={`dropdown ${rateFilterMode === EDIT ? 'show' : ''}`}>
          <button
            type="button"
            className="btn btn-link dropdown-toggle"
            onClick={() => toggleNameFilterMode(nameFilterMode === VIEW ? EDIT : VIEW)}
          >
            { name || NAME_FILTER_PLACEHOLDER }
          </button>
          <div
            className={`dropdown-menu dropdown-menu-right naf-dropdown-menu naf-dropdown-menu-wide ${nameFilterMode === EDIT ? 'show' : ''}`}
            aria-labelledby="dropdownMenuButton"
          >
            <div className="form-row px-3 py-1">
              <div className="col-8">
                <Field
                  id={NAME}
                  name={NAME}
                  component="input"
                  type="text"
                  className="form-control mb-2 mr-sm-2"
                />
              </div>
              <div className="col-4 d-flex align-items-start">
                <button
                  className="btn btn-outline-success naf-icon-btn"
                  type="button"
                  onClick={handleNameSubmit}
                >
                  <span className="naf-icon-check" />
                </button>
                <button
                  className="btn btn-outline-danger naf-icon-btn"
                  type="button"
                  onClick={handleNameCancel}
                >
                  <span className="naf-icon-times" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FilterBar.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
  dailyRateFrom: PropTypes.number,
  dailyRateTo: PropTypes.number,
  roleFilterMode: PropTypes.oneOf([VIEW, EDIT]),
  countryFilterMode: PropTypes.oneOf([VIEW, EDIT]),
  cityFilterMode: PropTypes.oneOf([VIEW, EDIT]),
  rateFilterMode: PropTypes.oneOf([VIEW, EDIT]),
  nameFilterMode: PropTypes.oneOf([VIEW, EDIT]),
  toggleRoleFilterMode: PropTypes.func,
  toggleCountryFilterMode: PropTypes.func,
  toggleRateFilterMode: PropTypes.func,
  toggleCityFilterMode: PropTypes.func,
  handleDailyRateSubmit: PropTypes.func,
  handleCitySubmit: PropTypes.func,
  handleDailyRateCancel: PropTypes.func,
  handleCityCancel: PropTypes.func,
  handleNameSubmit: PropTypes.func,
  handleNameCancel: PropTypes.func,
  toggleNameFilterMode: PropTypes.func,
};

const FilterBarForm = reduxForm({
  form: FILTER_FORM,
  destroyOnUnmount: false,
  onSubmit: (formData, dispatch) => {
    dispatch(searchAction(
      formData.get(ROLE),
      formData.get(COUNTRY),
      formData.get(DAILY_RATE_FROM),
      formData.get(DAILY_RATE_TO),
      formData.get(CITY),
      formData.get(PAGE),
      formData.get(NAME)
    ));
  }
})(FilterBar);

export default connect(mapStateToProps, mapDispatchToProps)(FilterBarForm);
