/* eslint-disable import/prefer-default-export */
export const SearchFormFields = {
  ROLE: 'role',
  COUNTRY: 'country'
};

export const SEARCH_FORM = 'search';
export const FILTER_FORM = 'filter';
export const SEARCH = 'search';
export const SORTING = 'sorting';
export const FIELD = 'field';
export const ORDER = 'order';
export const ASCENDING = 'asc';
export const DESCENDING = 'desc';
export const PROFILE_STATUS_CATEGORY = 'profileStatusCategory';
export const MODE = 'mode';
export const INLINE_FORM = 'inlineForm';
export const RESULTS = 'results';
export const CONTENT = 'content';
export const TOTAL_PAGES = 'totalPages';
export const PAGE = 'page';
export const FIRST = 'first';
export const LAST = 'last';
export const NUMBER = 'number';
export const SUMMARY = 'summary';
export const FILTERS = 'filters';
export const ROLE = 'role';
export const COUNTRY = 'country';
export const CITY = 'city';
export const DAILY_RATE_FROM = 'dailyRateFrom';
export const DAILY_RATE_TO = 'dailyRateTo';
export const RATE = 'rate';
export const NAME = 'name';
export const ROLE_FILTER_PLACEHOLDER = 'Role';
export const COUNTRY_FILTER_PLACEHOLDER = 'Country';
export const CITY_FILTER_PLACEHOLDER = 'City';
export const NAME_FILTER_PLACEHOLDER = 'Name';
export const RATE_FILTER_PLACEHOLDER = 'Daily Rate';
export const inlineSearchStyle = 'form-control form-control-lg mr-sm-2';
export const filtersSearchStyle = 'custom-select';
export const PROFILE_STATUS_CHANGE_MODAL = 'showProfileStatusChangeModal';
export const RESULTS_VIEW_MODE = 'resultsViewMode';
export const LIST = 'list';
export const CARDS = 'cards';

export const MaxViewFieldLength = {
  NAME: 16,
  ROLES: 18,
  COUNTRY: 14,
  CITY: 10
};

export const ProfileStatus = {
  MISSING_INFORMATION: 'MISSING_INFORMATION',
  UNDER_REVIEW: 'UNDER_REVIEW',
  FULL: 'FULL',
  MIGRATED: 'MIGRATED',
  MIGRATED_NO_EMAIL: 'MIGRATED_NO_EMAIL',
  IGNORE: 'IGNORE'
};
export const ViewProfileStatusArray = [
  ProfileStatus.MISSING_INFORMATION,
  ProfileStatus.UNDER_REVIEW,
  ProfileStatus.FULL,
  ProfileStatus.IGNORE
];

