import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Bio from './Bio/Bio';
import Skills from './Skills';
import Interests from './Interests';
import Attachments from './Attachments/Attachments';
import {
  bioSelector,
  attachmentsSelector,
  interestsSelector,
  skillsSelector,
  languagesSelector,
  moreInformationSelector,
  missingInformationUIStateSelector,
  notesSelector
} from './selectors/index';
import Languages from './Languages/Languages';
import MoreInformation from './MoreInformation/MoreInformation';
import { adminRoleSelector } from '../Login/selectors';
import Notes from './Notes/Notes';

const mapStateToProps = state => ({
  bio: bioSelector(state),
  skills: skillsSelector(state) && skillsSelector(state).toJS(),
  interests: interestsSelector(state) && interestsSelector(state).toJS(),
  attachments: attachmentsSelector(state) && attachmentsSelector(state).toJS(),
  languages: languagesSelector(state) && languagesSelector(state).toJS(),
  moreInformation: moreInformationSelector(state) && moreInformationSelector(state).toJS(),
  shouldShowMissingInfo: missingInformationUIStateSelector(state),
  isAdmin: adminRoleSelector(state),
  notes: notesSelector(state)
});

const PersonalInformation = (props) => {
  const {
    bio,
    skills,
    interests,
    attachments,
    languages,
    moreInformation,
    notes,
    isAdmin
  } = props;
  return (
    <Fragment>
      <div className="row">
        <Bio isAdmin={isAdmin} initialValues={{ bio }} />
        {
          isAdmin &&
          <Notes initialValues={{ notes }} />
        }
      </div>
      <div className="row">
        <Skills initialValues={{ skills }} />
        <Interests initialValues={{ interests }} />
      </div>
      <div className="row">
        <Attachments initialValues={{ attachments }} />
        <Languages
          initialValues={{ languages }}
        />
      </div>
      <div className="row">
        <MoreInformation initialValues={{ ...moreInformation }} />
      </div>
    </Fragment>
  );
};

PersonalInformation.propTypes = {
  notes: PropTypes.string,
  isAdmin: PropTypes.shape(),
  bio: PropTypes.string,
  skills: PropTypes.arrayOf(PropTypes.string),
  interests: PropTypes.arrayOf(PropTypes.string),
  attachments: PropTypes.arrayOf(PropTypes.shape()),
  languages: PropTypes.arrayOf(PropTypes.shape()),
  moreInformation: PropTypes.shape()
};

export default connect(mapStateToProps)(PersonalInformation);
